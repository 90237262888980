import request from '../utils/request'
// 获取猎才官列表
export function getHunterList(params) {
    return request({
        url: 'sys/hunter/list',
        method: 'get',
        params
    })
}

// 设置黑名单
export function setBlacklist(data) {
    return request({
        url: `sys/hunter/black/${data}`,
        method: 'PUT'
    })
}

// 获取审核猎才官列表(学生用户表-分页列表查询)
export function getHunterAuditLise(params) {
    return request({
        url: 'wechat/recStudent/list',
        method: 'get',
        params
    })
}

// 同意成为猎才官
export function setHunterSucceed(data) {
    return request({
        url: `sys/recSchoolController/agree/${data.studentId}`,
        method: 'post'
    })
}

// 拒绝成为猎才官
export function setHunterRefuse(data) {
    return request({
        url: `sys/recSchoolController/refuse/${data.studentId}`,
        method: 'post'
    })
}

// 获取猎才官详情
export function getHunterDetails(id) {
    return request({
        url: `sys/hunter/query/${id}`,
        method: 'get'
    })
}

// 获取猎才官团队信息
export function getHunterTeam(id, params) {
    return request({
        url: `sys/hunter/list/${id}`,
        method: 'get',
        params
    })
}

// 获取猎才管收益明细
export function getHunterEarnings(params) {
    return request({
        url: 'sys/hunter/hunter/list',
        method: 'get',
        params
    })
}
