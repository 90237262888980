import request from '../utils/request'
// 获取广告列表
export function getAdvertising(params) {
    return request({
        url: 'sys/recBanner/auth/list',
        method: 'get',
        params
    })
}

// 添加广告数据
export function addAdvertising(data) {
    return request({
        url: 'sys/recBanner/add',
        method: 'post',
        data
    })
}

// 编辑广告数据
export function editAdvertising(data) {
    return request({
        url: 'sys/recBanner/edit',
        method: 'post',
        data
    })
}

// 删除广告数据
export function deleteAdvertising(params){
    return request({
        url:'sys/recBanner/delete',
        method:'delete',
        params
    })
}
