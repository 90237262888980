import React, { useState, useEffect } from 'react'
import { Table, Button, Image, Switch, Modal } from 'antd'
import { objTurnArr, adType } from "../../utils/specification"
import { getAdvertising, editAdvertising, deleteAdvertising } from "../../api/advertising"
import EditAd from './EditAd'
import "./index.scss"
export default function Index() {
    const typeList = objTurnArr(adType)
    const [edit, setEdit] = useState({ visible: false })
    const [params, setParams] = useState({ category: 1, pageNo: 1, pageSize: 10 })
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const typeObj = { null: null, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 63: 6, 73: 7, 8: 8 }

    const delEnterprise = (id) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除当前项',
            okText: '删除',
            cancelButtonProps: true,
            onOk: () => {
                deleteAdvertising({ id }).then(res => {
                    if (res) getAdvertisingApi()
                })
            }
        })
    }
    const getAdvertisingApi = () => {
        getAdvertising(params).then(({ result }) => {
            let { records, total } = result
            setList(records)
            setTotal(total)
        })
    }
    const columns = [
        {
            title: '序号',
            width: 98,
            align: 'center',
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '图片/文字',
            render: (key) => <>
                {
                    key.routing ? <Image src={key.url} width={300} /> : key.url
                }
            </>
        },
        {
            title: '状态',
            width: 98,
            render: (key) => <Switch
                defaultChecked={Boolean(key.isOpen)}
                onChange={() => { editAdvertising({ ...key, ...{ isOpen: key.isOpen ? 0 : 1 } }).then(res => { if (res) { getAdvertisingApi() } }) }}
                checkedChildren="开启"
                unCheckedChildren="关闭"
            />
        },
        {
            title: '操作',
            align: 'center',
            width: 218,
            render: (key) => <div className='operation'>
                <div className='edit iconfont' onClick={() => { setEdit({ visible: true, params: { ...key, ...{ routing: typeObj[key.routing] } } }) }}>&#xe8ac;</div>
                <div className='vertical' />
                <div className='rejectText iconfont' onClick={() => { delEnterprise(key.id) }}>&#xe8b6;</div>
            </div>
        }
    ]
    useEffect(() => {
        getAdvertisingApi()
    }, [params])
    return (
        <div className='advertising'>
            <div className='screening'>
                <div className='typeWrap'>
                    {
                        typeList.map((key, index) => {
                            return <div className={`typeItem${params.category === index ? ' typeItemAct' : ''}`} key={index} onClick={() => { setParams({ ...params, ...{ category: index } }) }}>{key}</div>
                        })
                    }
                </div>
                <Button type="primary" onClick={() => { setEdit({ visible: true }) }}>新建</Button>
            </div>
            <Table
                columns={columns}
                dataSource={list}
                scroll={{ y: 'calc(100vh - 100px)' }}
                pagination={{
                    total: total,
                    current: params.pageNo,
                    pageSize: params.pageSize,
                    onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                }}
                rowKey="id"
            />
            <EditAd visible={edit.visible} params={edit.params} type={params.category} cancel={() => { setEdit({ visible: false }) }} update={getAdvertisingApi} />
        </div >
    )
}
