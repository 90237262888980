import React, { useEffect, useState } from "react";
import { Input, Modal, Button, Table, Select, message } from "antd"
import { hrColumns } from "../table"
import { getHrList, getPosition } from "../../../api/students"
import { pushWarehouseHr } from "../../../api/commendBook"
import "./PushHr.scss";
import "../../User/index.scss"
export default function PushHr(props) {
    const { Option } = Select
    const [parmas, setParams] = useState({ visible: false })
    const [quest, setQuest] = useState({ pageNo: 1, pageSize: 10 })
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [position, setPosition] = useState([])
    const [selection, setSelection] = useState([])

    // 定义定时器
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setQuest({ ...quest, ...{ name: e.target.value ? `,${e.target.value},` : e.target.value, pageNo: 1 } })
        };
        timeout = setTimeout(fake, 1000);
    }
    // 获取HR列表
    const getHrListApi = () => {
        let data = JSON.parse(JSON.stringify(quest))
        for (let index in data) {
            if (!data[index]) {
                delete data[index]
            }
        }
        getHrList(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    function init() {
        setParams({ visible: false });
        setSelection([])
    }
    function sbumit() {
        pushWarehouseHr({
            warehouseId: props.warehouseId,
            hrIds: selection
        }).then(res => message.success({ content: '推送成功！', duration: 2, onClose: () => init() }))
    }
    useEffect(() => {
        if (parmas.visible) getHrListApi()
    }, [quest, parmas])

    useEffect(() => {
        getPosition().then(({ result }) => {
            setPosition(result.records)
        })
    }, [])
    return (
        <>
            <Button type="primary" onClick={() => setParams({ visible: true })}> 推送</Button >
            <Modal
                visible={parmas.visible}
                title="选择推送HR"
                // footer={null}
                width={1280}
                onCancel={() => { init() }}
                onOk={() => sbumit()}
                destroyOnClose
            >
                <div className='screening'>
                    <Input placeholder='请输入HR名字' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "258px" }} />
                    <Select value={quest.industryId} allowClear style={{ width: 240, marginLeft: 20 }} onChange={(e) => { setQuest({ ...quest, ...{ industryId: e } }) }} placeholder="请选择行业">
                        {
                            position?.map(key => {
                                return <Option value={key.id} key={key.id}>{key.name}</Option>
                            })
                        }
                    </Select>
                </div >
                <Table
                    rowSelection={{
                        selectedRowKeys: selection,
                        onChange: (e) => { setSelection(e) }
                    }}
                    columns={hrColumns}
                    dataSource={list}
                    className="ces"
                    centered
                    scroll={{ y: 'calc(100vh - 100px)' }}
                    rowKey="id"
                    pagination={{
                        total: total,
                        current: quest.pageNo,
                        pageSize: quest.pageSize,
                        showTotal: (total) => <div style={{ marginRight: 20 }}>共计{total}人</div>,
                        onChange: (pageNo, pageSize) => { setQuest({ pageNo, pageSize }, setSelection([])) }
                    }}
                ></Table>
            </Modal>
            
        </>

    )
}