import { useNavigate } from "react-router-dom";

function NotFound() {
	const navigate = useNavigate()
	return (
		<h1 onClick={()=>{
			navigate('/login', {
				state: {
					pathname:  window.location.pathname,
					search:  window.location.search
				}
			})
		}}>
			NotFound
		</h1>
	)
}

export default NotFound
