import React, { Component } from 'react'
import "./index.scss"
import LoginView from "./LoginView"
export default class Login extends Component {
	render() {
		return (
			<div className='login' >
				<header>
					<img src="assets/logo.png" alt="" />
					<div className='prompt'>让职场，陪伴见证我的大学成长</div>
				</header>
				<main>
					<img src="assets/invitation.png" className='invitation' alt="" />
					<LoginView />
				</main>
			</div>
		)
	}
}