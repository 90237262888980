import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import { Button, Table, Modal, Drawer, Timeline } from "antd"
import { HrPushDetailsColumns } from "./table"
import { getResumePushHrDetails, removeResumePushHr, getResumeInfo, getWarehouseRecord } from "../../api/commendBook"
import "./index.scss"
import PushHr from "./components/PushHr"
import { filterJoin } from "../../utils/specification";
export default function HrPushDetails() {
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [drawer, setDrawer] = useState(false)
    const [record, setRecord] = useState([])

    function getDetails() {
        getResumePushHrDetails(state).then(async ({ result }) => {
            let { inventories } = result
            for (let index = 0; index < inventories.length; index++) {
                let item = inventories[index]
                let info = await getResumeInfo({ id: item.resumeId }).then(res => res.result)
                if (info.content) info = { ...info, ...JSON.parse(info.content) }
                item.info = info
                inventories[index] = item
                console.log(item, 'getResumeInfo')
            }
            setList(inventories)
        })
    }
    // 移除数据
    function delPushApi(r) {
        Modal.confirm({
            title: '提示',
            content: '确认删除当前简历',
            onOk: () => {
                removeResumePushHr({ id: state, inventories: [{ id: r.id, resumeId: r.resumeId }] }).then(res => getDetails())
            }
        })
    }
    // 获取记录
    function getRecord() {
        getWarehouseRecord(state).then(({ result }) => {
            result.forEach(key => {
                key.open = false
            });
            setRecord(result)
            setDrawer(true)
        })
    }

    function open(index, type) {
        let list = record
        list[index].open = type ? false : true
        setRecord(list)
    }
    useEffect(() => {
        getDetails()
    }, [])
    return (
        <div className="HrPushList">
            <div className="screening">
                <Button type="primary" style={{ marginRight: 20 }} onClick={() => getRecord()}>记录</Button>
                <PushHr warehouseId={state} />
            </div>
            <Table
                columns={[
                    ...HrPushDetailsColumns,
                    ...[
                        {
                            title: '操作',
                            width: 100,
                            align: 'center',
                            render: (t, r) => <div className='del' onClick={(e) => { e.stopPropagation(); delPushApi(r) }}>&#xe8b6;</div>
                        }
                    ]
                ]}
                rowKey="id"
                pagination={false}
                dataSource={list}
                scroll={{ y: 'calc(100vh - 100px)' }}
            />
            <Drawer width={458} title="推送记录" placement="right" onClose={() => { setDrawer(false) }} visible={drawer}>
                <Timeline>
                    {
                        record.map((key, index) => {
                            return (
                                <Timeline.Item key={index}>
                                    <div className="flexSbCenter">
                                        <div>{key.pushTime}</div>
                                        <div>推送{key.hrs.length}位HR，共{key.resumeCount}份简历</div>
                                    </div>
                                    <div style={{ height: key.hrs.length < 4 ? 32 * key.hrs.length : key.open ? 32 * key.hrs.length : 32 * 3 }}>
                                        {
                                            key.hrs.map(item => {
                                                return (
                                                    <div className="item" key={item.id}>{filterJoin([item.name, item.employerName, item.phone], ' - ')}</div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        key.hrs.length >= 4 ? <div className="more" onClick={() => { open(index, key.open) }}>{key.open ? '收起' : '更多'}</div> : null
                                    }
                                </Timeline.Item>
                            )
                        })
                    }
                </Timeline>
            </Drawer>
        </div >
    )
}