import axios from 'axios'
import { message, Modal } from 'antd';
import { getToken, removePermission, removeToken, removeUserInfo } from "../auth"

// create an axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API, // url = base url + request url
  timeout: 50000 // request timeout
})

// request interceptor
// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    let token = getToken() || ''
    if (token) {
      config.headers['X-Access-Token'] = token
    }
    console.log(process.env.REACT_APP_BASE_API, '请求配置')
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.status == 200) {
      if (res.code !== 200 && res.code !== 0) {
        switch (res.code) {
          case 201:
            message.error(res.message || '出错啦！');
            return Promise.reject(res)
          case 202:
            Modal.warning({
              title: '',
              content: '登陆过期，请重新登录',
              okText: '重新登录',
              onOk() {
                console.log('重新登录了')
              }
            });
            break;
          default:
            message.error(res.message || '出错啦！');
            return Promise.reject(res)
        }
      } else if (res.code === 200 || res.code === 0) {
        return Promise.resolve(res)
      }
    } else {
      message.error(res.message || '出错啦！');
    } 
    console.log('response-----', response)
    // if the custom code is not 20000, it is judged as an error.

  },
  ({ response }) => {
    console.log(response, '请求失败')
    if (response.status == 401) {
      message.error('身份令牌失效');
      removePermission()
      removeToken()
      removeUserInfo()
      window.location.reload()
      window.location.pathname = '/login'
      return false
    } else if (response.status == 0) {
      message.error('网络请求不通！');
    } else {
      message.error('出错啦！');
    }
    return Promise.reject(response)
  }
)

export default service
