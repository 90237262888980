import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from "react-router-dom"
import { getStudentResumeList, getStudentInfo } from "../../api/commendBook"
import { filterJoin } from "../../utils/specification"
export default function ResumeDetails() {
    const { state: studentId } = useLocation()
    const [resume, setResume] = useState([])
    const [userInfo, setUserInfo] = useState({})
    console.log(studentId)
    useEffect(() => {
        getStudentResumeList(studentId).then(({ result }) => {
            setResume(result)
        })
        getStudentInfo(studentId).then(({ result }) => {
            console.log(result, '学生信息')
            setUserInfo(result)
        })
    }, [])
    return (
        <div className='ResumeDetails'>
            <div className='userInfo'>
                <img src={userInfo.avatar} alt="" className='avarat' />
                <div className='nameAndSex'>{userInfo.name}
                    <img className='sex' src={`/assets/${userInfo.sex === 1 ? 'male' : userInfo.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                <div className='school'>
                    <span className='period'>{userInfo.graduate}届</span>{filterJoin([userInfo.school, userInfo.specialty], ' · ')}
                </div>
                <div className='achievement'>
                    <div className='title'>我的成就</div>
                    <div className='label'>
                        {userInfo.isFlash === 1 ? <div className='labelItem item1'>闪光应届生</div> : null}
                        {userInfo.hunter === 1 ? <div className='labelItem item2'>预备委员</div> : null}
                        {userInfo.isGeneral === 1 ? <div className='labelItem item2'>实践优等生</div> : null}
                        {userInfo.isTeam === 1 ? <div className='labelItem item3'>省协会委员</div> : null}
                    </div>
                </div>
                <div className='contact'>
                    <div className='title'>联系方式</div>
                    <div className='flexAlignCenter'>
                        <div className='phone'>{userInfo.phone}</div>
                        {
                            userInfo.superiorId ? <div className='referees'>由<span>{userInfo.superiorName}</span>{userInfo.createTime.split(' ')[0]}邀请</div> : null
                        }
                    </div>
                </div>
            </div>
            <div className='ResumeList'>
                <header>
                    <div className='title'>简历列表</div>
                    <div className='instructions'>共<span>{resume.length}</span>份简历</div>
                </header>
                <main>
                    {/* <div className='typeWrap'>
                        <div className='typeItem typeItemAct'>全部</div>
                        <div className='typeItem'>求职</div>
                        <div className='typeItem'>已审核</div>
                    </div> */}
                    {
                        resume.map((key, index) => {
                            return (
                                <NavLink to="../preview" state={{ ...userInfo, ...{ resumeId: key.id } }} className='resumeRow' key={index}>
                                    <div className='number'>{index + 1}</div>
                                    <div className='resumeCol'>{key.resume}</div>
                                    <div className='state'>{key?.jobObjective??'--'}</div>
                                    <div className='tiem'>{key?.updateTime ? key.updateTime.split(' ')[0] : key.createTime.split(' ')[0]}</div>
                                    {/* <div className='resumeCol'>{}</div> */}
                                    {/* <div className='state'>待审核</div> */}
                                </NavLink>
                            )
                        })
                    }
                </main>
            </div>
        </div>
    )
}
