import request from '../utils/request'
// 获取企业列表
export function getEnterpriseList(params) {
    return request({
        url: 'com/recCompany/list',
        method: 'get',
        params
    })
}

// 获取企业详情信息
export function getEnterpriseInfo(params) {
    return request({
        url: 'com/recCompany/queryById',
        method: 'get',
        params
    })
}

// 删除企业信息
export function deleteEnterprise(params) {
    return request({
        url: 'com/recCompany/delete',
        method: 'delete',
        params
    })
}

// 修改企业信息
export function editCompanyInfo(data) {
    return request({
        url: 'com/recCompany/edit',
        method: 'post',
        data
    })
}

// 修改企业登录手机号---新
export function editCompanyPhone(data) {
    return request({
        url: "com/recCompany/phone/change",
        method: "PUT",
        data
    })
}

// 通过企业信息
export function passEnterprise(companyId) {
    return request({
        url: `com/recCompany/pass/${companyId}`,
        method: 'post'
    })
}

// 拒绝企业信息
export function rejectEnterprise(companyId) {
    return request({
        url: `com/recCompany/refuse/${companyId}`,
        method: 'post'
    })
}