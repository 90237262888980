import React, { useState, useEffect } from 'react'
import { filterJoin } from '../../utils/specification'
import { getFinanceDetails } from "../../api/financial"
import { Table } from 'antd'
import { details as columns } from "./table"
import "./index.scss"
export default function Details() {
    const roleList = ['预备委员', '学生']
    const typeList = [
        { title: '余额', rule: 1 },
        { title: '信用金', rule: 1 },
        { title: '红包', rule: 1 },
        { title: '猎才金', rule: 0 }
    ]
    const stateList = [
        { title: '充值', rule: [0] },
        { title: '支出', rule: [0, 2, 3] }, // 收益改支出
        { title: '提现', rule: [0, 1, 2, 3] },
        { title: '扣罚', rule: [1] }
    ]
    // 选择角色
    const selectRole = (role) => {
        for (let type = 0; type < typeList.length; type++) {
            if (typeList[type].rule === role) {
                selectType(role, type)
                return false
            }
        }
    }
    // 选择类型
    const selectType = (role, type) => {
        for (let status = 0; status < stateList.length; status++) {
            if (stateList[status].rule.includes(type)) {
                setParams({ ...params, ...{ role, type, status, pageNo: 1 } })
                return false
            }
        }
    }
    const [params, setParams] = useState({ role: 0, type: 3, status: 1, pageNo: 1, pageSize: 10 })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const getFinanceApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        getFinanceDetails(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    useEffect(() => {
        getFinanceApi()
    }, [params])
    return (
        <div className='FinancialDetails'>
            <div className='typeWrap'>
                {
                    roleList.map((key, index) => {
                        return <div key={index} className={`typeItem ${params.role === index ? 'typeItemAct' : ''}`} onClick={() => { selectRole(index) }}>{key}</div>
                    })
                }
            </div>
            <div className='screen'>
                <div className='row'>
                    <div className='rowWrap'>
                        <div className='rowItem'>类型</div>
                        {
                            typeList.map((key, index) => {
                                return key.rule === params.role ? <div className={filterJoin(['rowItem', params.type === index ? 'rowItemAct' : null])} key={index} onClick={() => { selectType(params.role, index) }}>{key.title}</div> : null
                            })
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='rowWrap'>
                        <div className='rowItem'>状态</div>
                        {
                            stateList.map((key, index) => {
                                return key.rule.includes(params.type) ? <div className={filterJoin(['rowItem', params.status === index ? 'rowItemAct' : null])} key={index} onClick={() => { setParams({ ...params, ...{ status: index } }) }}>{key.title}</div> : null
                            })
                        }
                    </div>
                </div>
            </div>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={list}
                pagination={{
                    total: total,
                    current: params.pageNo,
                    pageSize: params.pageSize,
                    onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                }}
                scroll={{ y: 'calc(100vh - 100px)' }}
            />
        </div>
    )
}
