import React, { useEffect, useState } from 'react'
import { Input, Dropdown, Menu, Table, Select, DatePicker, Cascader, message,Modal } from "antd"
import { useNavigate, useLocation, Outlet } from "react-router-dom"
import { getResumeList, setRecommendInfo, getResumeDetails, getPosition, getHrPushList, pushResumePushHr, getResumeInfo, delectResume } from "../../api/commendBook"
import { resumeTable } from "./table"
import ResumeModal from "./ResumeModal"
import { getUserInfo } from '../../utils/auth'
import moment from "moment"
import "./index.scss"
export default function Resume() {
  const { Option } = Select
  const navigate = useNavigate()
  const userInfo = JSON.parse(getUserInfo()) || {}
  const { pathname } = useLocation()
  const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])
  const [position, setPosition] = useState([])
  const [HrList, setHrList] = useState([])
  const [paramsItem, setParamsItem] = useState({ visible: false, type: 0 })
  // 获取简历列表
  const getResumeApi = () => {
    for (let index in params) {
      if (!params[index]) {
        delete params[index]
      }
    }
    getResumeList(params).then(async ({ result }) => {
      let { records, total } = result
      for (let index = 0; index < records.length; index++) {
        let item = records[index]
        let { stuName, jobObjective,content } = await getResumeInfo({ id: item.resumeId }).then(res => res.result)
        item.stuName = stuName
        item.jobObjective = jobObjective
        item.content = content
        records[index] = item
      }
      setList(records)
      setTotal(total)
    })
  }
  let timeout;
  // input 输入选择
  const inputChange = (e) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    const fake = () => {
      setParams({ ...params, ['stuName']: e.target.value, ['pageNo']: 1 })

    };
    timeout = setTimeout(fake, 1000);
  }
  /**
   * 专业输入
   * @param {*} e 
   */
  let specialtyTimeout
  function specialtyChange(e) {
    if (specialtyTimeout) {
      clearTimeout(specialtyTimeout);
      specialtyTimeout = null;
    }
    const fake = () => {
      setParams({ ...params, ['specialty']: e.target.value, ['pageNo']: 1 })

    };
    specialtyTimeout = setTimeout(fake, 1000);
  }
  /**
   * 意向岗位选择 
   */
  function cascaderChange(e) {
    if (e) {

      function formatting(list) {
        let arr = list.filter(key => e.includes(key.label))[0]
        return arr?.children ? formatting(arr.children) : arr.id
      }

      let id = formatting(JSON.parse(JSON.stringify(position)))
      setParams({ ...params, ['desiredPosition']: id, ['pageNo']: 1 })
    } else {
      setParams({ ...params, ['desiredPosition']: '', ['pageNo']: 1 })
    }
    // let 
  }
  /**
   * 学校输入
   * @param {*} e 
   */
  let schoolTimeout
  function schoolChange(e) {
    if (schoolTimeout) {
      clearTimeout(schoolTimeout);
      schoolTimeout = null;
    }
    const fake = () => {
      setParams({ ...params, ['schoolName']: e.target.value, ['pageNo']: 1 })

    };
    schoolTimeout = setTimeout(fake, 1000);
  }
  // 推送/取消闪光墙
  const setFlash = (res, type = 0) => {
    if (res.isFlash) {
      if (type) {
        getResumeDetails({ id: res.resumeId }).then(({ result }) => {
          setParamsItem({ ...result, ...{ visible: true }, ['type']: type })
        })
        return
      }
      setRecommendInfo({ id: res.resumeId }).then(res => {
        if (res) {
          getResumeApi()
        }
      })
    } else {
      getResumeDetails({ id: res.resumeId }).then(({ result }) => {
        setParamsItem({ ...result, ...{ visible: true }, ['type']: type })
      })
    }
  }
  // 加载更多数据
  const loadData = (e) => {
    console.log(e)
    let targetOption = e[e.length - 1];
    console.log(targetOption, 'targetOption')
    targetOption.loading = true; // load options lazily
    getPosition({ pageSize: 9999, parentId: targetOption.id, level: e.length + 1 }).then(({ result }) => {
      targetOption.loading = false;
      let { records } = result;
      targetOption.children = records.map(key => {
        return { label: key.name, value: key.name, id: key.id, isLeaf: e.length == 2 ? true : false }
      });
      setPosition([...position])
    })
  }

  function delectResumeApi(e) {
    Modal.warning({
      title: '提示',
      content: '是否确认删除当前简历',
      onOk: () => {
        delectResume({ id: e.resumeId }).then(res => {
          if (res) {
            getResumeApi()
          }
        })
      }
    })
  }

  useEffect(() => {
    console.log(pathname === '/commendBook/resume')
    if (pathname === '/commendBook/resume') {
      getResumeApi()
      getPosition({
        level: 1,
        pageSize: 999
      }).then(({ result }) => {
        let { records } = result
        let arr = records.map(key => {
          return { label: key.name, value: key.name, id: key.id, isLeaf: false }
        });
        setPosition(arr)
      })
    }
  }, [params])

  useEffect(() => {
    if (pathname === '/commendBook/resume') getHrPushList({ pageNo: 1, pageSize: 1000 }).then(({ result }) => {
      setHrList(result.records)
    })
  }, [])
  return (
    <>
      {
        pathname === '/commendBook/resume' ? <div className='resume'>
          <div className='screening'>
            {/* setParams({ ...params, ...{ jobObjective: e, pageNo: 1 } }) */}
            <Select style={{ width: 198, marginRight: 20 }} allowClear onChange={(e) => { setParams({ ...params, ['pushed']: e == 1 ? true : '', ['pageNo']: 1 }) }} placeholder="请选择类型">
              <Option value='0'>全部</Option>
              <Option value='1'>闪光墙</Option>
            </Select>
            <Select style={{ width: 198, marginRight: 20 }} allowClear onChange={(e) => { setParams({ ...params, ...{ jobObjective: e, pageNo: 1 } }) }} placeholder="请选意向">
              <Option value='找兼职'>找兼职</Option>
              <Option value='找实习'>找实习</Option>
              <Option value='毕业求职'>毕业求职</Option>
            </Select>
            <Input placeholder='专业' onChange={specialtyChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: 198, marginRight: 20 }} />
            <Cascader options={position} loadData={loadData} onChange={cascaderChange} style={{ width: 358, marginRight: 20 }} placeholder="意向岗位" />
            <DatePicker placeholder='毕业年限' picker="year" onChange={e => { setParams({ ...params, ...{ graduate: e ? moment(e).format('YYYY') : '', pageNo: 1 } }) }} style={{ width: 198, marginRight: 20 }} />
            <Input placeholder='学校' onChange={schoolChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: 198, marginRight: 20 }} />
            <Input placeholder='姓名' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: 198 }} />
          </div>
          <Table
            columns={userInfo?.nature === 'sys' ? [...resumeTable, ...[{
              title: '操作',
              align: 'center',
              width: 128,
              render: (t, r) => <div className='options'>
                <Dropdown overlay={<Menu items={[
                  {
                    key: 1,
                    label: (<div className="operationBox passText" onClick={(e) => { e.stopPropagation(); setFlash(r, 1) }}>查看</div>),
                    disabled: r.isFlash !== 1
                  },
                  {
                    key: 2,
                    label: (<div className='operationBox passText' onClick={(e) => { e.stopPropagation(); setFlash(r, 2) }}>编辑</div>),
                    disabled: r.isFlash !== 1
                  },
                  {
                    key: 3,
                    label: (r.isFlash === 1 ? <div className='operationBox rejectText' onClick={(e) => { e.stopPropagation(); setFlash(r) }}>取消推送</div> : <div className='passText' onClick={(e) => { e.stopPropagation(); setFlash(r) }}>推送闪光墙</div>)
                  }
                ]} />} placement="bottom" arrow>
                  <div className="edit iconfont">&#xe8ac;</div>
                </Dropdown>
                <div className='vertical'>|</div>
                <Dropdown overlay={<Menu items={
                  HrList.map((item, index) => {
                    return {
                      key: index + 1,
                      label: (<div className='operationBox' onClick={e => { e.stopPropagation(); pushResumePushHr({ id: item.id, inventories: [{ warehouseId: item.id, resumeId: r.resumeId }] }).then(res => message.success('添加成功！')) }}>{item.name}</div>)
                    }
                  })
                } />} placement="bottom" arrow>
                  <div className="shopping iconfont">&#xe69c;</div>
                </Dropdown>
                <div className='vertical'>|</div>
                <div className='rejectText iconfont' onClick={(e) => { e.stopPropagation(); delectResumeApi(r) }}>&#xe8b6;</div>
                {/* {
                  r.isFlash === 1 ?
                    <>
                      <div className="passText" onClick={(e) => { e.stopPropagation(); setFlash(r, 1) }}>查看</div>
                      <div className='vertical'>|</div>
                      <div className='passText' onClick={(e) => { e.stopPropagation(); setFlash(r, 2) }}>编辑</div>
                      <div className='vertical'>|</div>
                      <div className='rejectText' onClick={(e) => { e.stopPropagation(); setFlash(r) }}>取消推送</div>
                    </>
                    : <div className='passText' onClick={(e) => { e.stopPropagation(); setFlash(r) }}>推送闪光墙</div>
                } */}
                {/* <Button type='pprimaryr' className={r.isFlash === 1 ? 'buttonAct' : 'button'} onClick={(e) => { e.stopPropagation(); setFlash(r) }}>{r.isFlash === 1 ? '取消推送' : '推送闪光墙'}</Button> */}
              </div>
            }]] : resumeTable}
            dataSource={list}
            onRow={r => { return { onClick: e => { navigate('details', { state: r.studentId }) } } }}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 100px)' }}
            pagination={{
              total: total,
              current: params.pageNo,
              pageSize: params.pageSize,
              showTotal: (total) => <div style={{ marginRight: 20 }}>共计{total}份</div>,
              onChange: (pageNo, pageSize) => { setParams({ ...{ pageNo, pageSize } }) }
            }}
          />
        </div> : <Outlet />
      }
      <ResumeModal visible={paramsItem.visible} type={paramsItem.type} params={paramsItem} update={getResumeApi} cancel={(e) => { setParamsItem({ ...{ visible: false } }) }} />
    </>

  )
}
