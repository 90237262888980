import React, { useEffect, useState } from 'react'
import { Input, Table } from "antd"
import { useNavigate } from 'react-router-dom'
import { hrColumns as columns } from "./table"
import { Select } from "antd"
import { getHrList, getPosition } from "../../api/students"
import "./index.scss"
export default function HrList() {
    const { Option } = Select
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10, industryId: '' })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [position, setPosition] = useState([])
    const navigate = useNavigate()
    // 定义定时器
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setParams({ ...params, ...{ name: e.target.value ? `,${e.target.value},` : e.target.value, pageNo: 1 } })
        };
        timeout = setTimeout(fake, 1000);
    }
    // 获取HR列表
    const getHrListApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        for (let index in data) {
            if (!data[index]) {
                delete data[index]
            }
        }
        getHrList(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    useEffect(() => {
        getHrListApi()
    }, [params])
    useEffect(() => {
        getPosition().then(({ result }) => {
            setPosition(result.records)
        })
    }, [])
    return (
        <div className='students'>
            <div className='screening'>
                <Input placeholder='请输入HR名字' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "258px" }} />
                <Select value={params.schoolId} allowClear style={{ width: 240, marginLeft: 20 }} onChange={(e) => { setParams({ ...params, ...{ industryId: e } }) }} placeholder="请选择行业">
                    {
                        position?.map(key => {
                            return <Option value={key.id} key={key.id}>{key.name}</Option>
                        })
                    }
                </Select>
            </div >
            <Table
                columns={[
                    ...columns,
                    ...[{
                        title: '行业',
                        align: 'center',
                        dataIndex: 'industryId',
                        render: (id) => <>{position.filter(key => key.id == id)[0]?.name ?? '暂无'}</>
                    }, {
                        title: '入驻时间',
                        dataIndex: 'createTime',
                        align: 'center',
                        render: (t) => <>{t.split(' ')[0]}</>
                    }]
                ]}
                dataSource={list}
                scroll={{ y: 'calc(100vh - 100px)' }}
                rowKey="id"
                onRow={r => { return { onClick: e => { navigate('details', { state: r.id }) } } }}
                pagination={{
                    total: total,
                    current: params.pageNo,
                    pageSize: params.pageSize,
                    showTotal: (total) => <div style={{ marginRight: 20 }}>共计{total}人</div>,
                    onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
                }}
            ></Table>
        </div >
    )
}
