import "./Audit.scss";
import { Form, Input, Modal, Button, Switch } from "antd"
import { recStuPenalty, oneKeyPenalty } from "../../../api/position"
export default function InputView(props) {
    const handleCancel = () => {
        props.UndateVisible()
    };
    const onFinish = (data) => {
        console.log(props)
        data.positionId = props.positionId
        if (props?.studentId) {
            data.studentId = props.studentId
            data.type = -1
            data.settlement = 0
            data.twoSettlement = 0
            recStuPenalty(data).then(res => {
                if (res) {
                    props.cancel()
                    props.update()
                }
            })
        } else {
            // console.log(stuids)
            data.stuIds = props.student.join(',')
            data.commission = data.envelope
            delete data.envelope
            oneKeyPenalty(data).then(res => {
                if (res) {
                    props.cancel()
                    props.update()
                }
            })
        }
    };
    return (
        <Modal
            visible={props.visible}
            title="审核"
            footer={null}
            onCancel={props.cancel}
            destroyOnClose
        >
            <Form name="basic"
                initialValues={{
                    remember: true,
                }}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    name="envelope"
                    label="红包"
                    rules={[
                        {
                            required: true,
                            message: '请输入红包',
                        },
                    ]}
                >
                    <Input placeholder='请输入红包' min={0} type="number" />
                </Form.Item>
                {/* <Form.Item
                    name="settlement"
                    label="一级猎才金"
                    rules={[
                        {
                            required: true,
                            message: '请输入一级猎才金',
                        },
                    ]}
                >
                    <Input placeholder='请输入一级猎才金' min={0} type="number" maxLength={2} />
                </Form.Item>
                <Form.Item
                    name="twoSettlement"
                    label="二级猎才金"
                    rules={[
                        {
                            required: true,
                            message: '请输入二级猎才金',
                        },
                    ]}
                >
                    <Input placeholder='请输入二级猎才金' min={0} type="number" maxLength={2} />
                </Form.Item> */}
                {/* <Form.Item name='type' label="是否扣除信用金">
                    <Switch checkedChildren="是" unCheckedChildren="否" />
                </Form.Item> */}
                <Button type="primary" htmlType="submit" loading={false} className='submit'>
                    确认
                </Button>
            </Form>
        </Modal>
    )
}