import request from '../utils/request'
// 获取学生列表
export function getStudentsList(params) {
    return request({
        url: 'wechat/recStudent/list',
        method: 'get',
        params
    })
}

// 修改学生用户信息
export function setStudentsInfo(data) {
    return request({
        url: 'wechat/recStudent/edit',
        method: 'post',
        data
    })
}

// 获取学生闪光应届生申请列表
export function getStudentsRemark(params) {
    return request({
        url: 'wechat/recStuRemark/list',
        method: 'get',
        params
    })
}

// 学生闪光应届生审核通过
export function setStudentsRemarkSuccess(id) {
    return request({
        url: `wechat/recStuRemark/success/${id}`,
        method: 'post'
    })
}

// 学生闪光应届生审核拒绝
export function setStudentsRemarkReject(id) {
    return request({
        url: `wechat/recStuRemark/reject/${id}`,
        method: 'post'
    })
}

// 获取学生详情
export function getStudentsDetails(id) {
    return request({
        url: `sys/recSchoolController/student/${id}`,
        method: 'get'
    })
}

// 获取简历列表信息
export function getStudentsResumeInfoDetails(id) {
    return request({
        url: `sys/admin/resume/query/${id}`,
        method: 'get'
    })
}

// 获取企业列表
export function getStudentsQuery(id) {
    return request({
        url: `sys/recSchoolController/student/${id}`,
        method: 'get',
    })
}

// 获取学生提现记录
export function getStudentsWithdraw(params) {
    return request({
        url: 'wechat/recStuWithdraw/list',
        method: 'get',
        params
    })
}

// 获取学生余额收益记录
export function getBalance(params) {
    return request({
        url: 'wechat/recStuWalletDetails/list',
        method: 'get',
        params
    })
}

// 获取HR列表
export function getHrList(params) {
    return request({
        url: 'sys/hr/page',
        method: 'get',
        params
    })
}

// 获取HR详情
export function getHrDetails(hrId) {
    return request({
        url: `sys/hr/${hrId}/detail`,
        method: 'get'
    })
}

// 获取HR收藏列表
export function getHrCollect(hrId) {
    return request({
        url: `sys/hr/${hrId}/resume/collected/filter`,
        method: 'get'
    })
}

// 获取行业列表
export function getPosition() {
    return request({
        url: 'com/recPositionDetail/auth/list',
        method: 'get',
        params: {
            level: 1,
            pageSize: 999
        }
    })
}

// 简历授权信息
export function authorize(data) {
    return request({
        url: 'sys/hr/resume/authorize',
        method: 'post',
        data
    })
}

// 取消授权信息
export function cancelAuthorize(hrId, authorizeId) {
    return request({
        // url:'sys/hr/resume/cancelAuthorize',
        url: `sys/hr/resume/auth/${authorizeId}/cancelAuthorize?hrId=${hrId}`,
        method: 'post'
    })
}