import React, { useState, useEffect } from 'react'
import { Table } from "antd"
import { columnsAudit } from "./table"
import { getEnterpriseList, passEnterprise, rejectEnterprise } from "../../api/enterprise"
import Info from './Info'
import "./index.scss"
export default function List() {
    const [list, setList] = useState([])
    const [selectItem, setSelectItem] = useState({ visible: false })
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10, status: '' })
    const [total, setTotal] = useState(0)
    const typeList = [{ type: '', title: '全部' }, { type: 'AWAIT', title: '待审核' }, { type: 'SUCCESS', title: '已通过' }, { type: 'FAIL', title: '已驳回' }]
    const getEnterpriseApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        for (let index in data) {
            if (!data[index]) {
                delete data[index]
            }
        }
        getEnterpriseList(data).then(({ result }) => {
            let { records, total } = result
            setList(records)
            setTotal(total)
        })
    }
    useEffect(() => {
        getEnterpriseApi()
    }, [params])
    return (
        <div className='enterprise'>
            <div className='screening type'>
                {
                    typeList.map((key, index) => {
                        return <div className={`typeItem${params.status === key.type ? ' typeItemAct' : ''}`} key={index} onClick={() => { setParams({ ...params, ...{ status: key.type } }) }}>{key.title}</div>
                    })
                }
            </div >
            <Table
                columns={[...columnsAudit, ...[{
                    title: '操作',
                    align: 'center',
                    width: 258,
                    render: (key) =>
                        <div className='options'>
                            {
                                key.status === "AWAIT" ?
                                    <>
                                        <div className='pass' onClick={(e) => { e.stopPropagation(); passEnterprise(key.id).then(res => { if (res) { getEnterpriseApi() } }) }}>通过</div>
                                        <div className='reject' onClick={(e) => { e.stopPropagation(); rejectEnterprise(key.id).then(res => { if (res) { getEnterpriseApi() } }) }}>拒绝</div>
                                    </> :
                                    key.status === "SUCCESS" ?
                                        <span className='passText'>已通过审核</span> :
                                        key.status === 'FAIL' ?
                                            <span className='rejectText'>已驳回审核</span> : null
                            }



                        </div>
                }]]}
                rowKey="id"
                onRow={r => { return { onClick: e => { setSelectItem({ ...r, ...{ visible: true } }) } } }}
                pagination={{
                    total: total,
                    current: params.pageNo,
                    pageSize: params.pageSize,
                    onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                }}
                dataSource={list}
                scroll={{ y: 'calc(100vh - 100px)', }} />
            <Info {...selectItem} cancel={() => { setSelectItem({ visible: false }) }} />
        </div>
    )
}
