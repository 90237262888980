import request from '../utils/request'
// 获取财务统计信息
export function getFinanceInfo(data) {
  return request({
    url: 'sys/finance/statistics',
    method: 'post',
    data: data
  })
}

// 获取财务详情列表数据
export function getFinanceDetails(data) {
  return request({
    url: 'sys/finance/record',
    method: 'post',
    data
  })
}

// 获取财务审核列表数据
export function getFinancialAudit(params) {
  return request({
    url: 'sys/finance/check',
    method: 'get',
    params
  })
}

// 财务审核
export function auditFinancial(params) {
  return request({
    url: 'sys/finance/success',
    method: 'put',
    params
  })
}
