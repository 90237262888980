import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Dropdown, Menu, Space, Modal, Input, message } from "antd"
import { DownOutlined } from '@ant-design/icons';
import { setPush, deleteSuperior, setPushLibrary, setPositionsShelf, setPositionsTop } from "../../../api/position"
import { paymentType, type, positionType, partType, brokerageUnit, unit, process, filterJoin } from "../../../utils/specification";
export default function PositionIItem(props) {
    const { state } = props;
    const rejectInput = useRef()
    const [selectBtn, setSelectBtn] = useState({ key: 1, title: '审核', type: 'auditShow', id: state.id });
    const menu = (
        <Menu
            items={[
                {
                    label: (
                        <Button onClick={() => { selectButton({ key: 1, title: '审核', type: 'auditShow', id: state.id }) }}>审核</Button>
                    ),
                    key: '0',
                },
                {
                    label: (
                        <Button onClick={() => { selectButton({ key: 0, title: '驳回', type: 'rejectedShow', id: state.id }) }}>驳回</Button>
                    ),
                    key: '1',
                }
            ]}
        />
    );
    const selectButton = (e) => {
        setSelectBtn(e)
        props.selectButton(e)
    }
    const enterLoading = (e) => {
        props.selectButton(selectBtn)
    };
    const setSuperior = (e) => {
        if (!e.isSuperior) {
            setPush(e.id).then(res => {
                if (res) {
                    props.update()
                }
            })
        } else {
            deleteSuperior(e.id).then(res => {
                if (res) {
                    props.update()
                }
            })
        }
    }
    // 定义推送人才库
    const pushAdd = (id) => {
        Modal.confirm({
            title: '创建推送库',
            content: (<Input className='rejectInput' ref={rejectInput} placeholder='请输入推送库名称' />),
            onOk: () => {
                let name = rejectInput.current.input.value
                if (!name) {
                    message.error('请输入人才库名称')
                } else {
                    setPushLibrary({ positionId: state.id, name }).then(res => {
                        if (res) {
                            message.info('创建成功')
                        }
                    })
                }
            }
        })
    }
    return (
        <NavLink to='details' state={{ id: state.id }} className='rowItem'>
            <div className='header'>
                <div className='companyInfo flex1'>
                    {
                        state.hrCompanyName ?
                            <div onClick={(e) => { e.preventDefault() }}>
                                <img src={state.hrLogo} alt="" />
                                <span>{state.hrCompanyName}</span>
                            </div>
                            : ''
                    }
                </div>
                <div className='label flex1'>{filterJoin([positionType[state.positionType], state.interview ? '面试' : '', type[state.type]], ' · ')}</div>
                <div className='commission flex1'>
                    <div className='commissionItem'>猎才金：<span>{state.twoBrokerage || 0}</span></div>
                    <div className='commissionItem'>上级猎才金：<span>{state.threeBrokerage || 0}</span></div>
                </div>
                {/* <div className='userInfo flex1'>张三<span>15100000000</span></div> */}
                <div className='timeInfo flex1'>发布时间：{state.createTime}</div>
                {
                    [2, 4].includes(state.positionType) ? <Button type='primary'
                        className={state.isSuperior === 1 ? 'buttonAct' : 'button'}
                        onClick={(e) => { e.preventDefault(); setSuperior(state) }}>
                        {state.isSuperior === 1 ? '已推送闪光优职' : '推送闪光优职 '}
                    </Button> : null
                }
            </div>
            <div className='formHead'>
                <div className='positionInfo' style={{ textAlign: 'left' }}>职位信息</div>
                <div className='commission'>佣金</div>
                <div className='deadline'>保用期</div>
                <div className='creditGold'>信用金</div>
                <div className='creditGold'>信用时长</div>
                <div className='recruitment'>招聘人数</div>
                <div className='apply'>报名人数</div>
                <div className='hasHired'>已聘人数</div>
                <div className='state'>状态</div>
                <div className='operation'>操作</div>
            </div>
            <div className='formCenter'>
                <div className='positionInfo'>
                    <div className='name'>{state.name}</div>
                    <div className='companyInfo' onClick={(e) => { e.preventDefault() }}>
                        <img src={state.logo} alt="" />
                        <div className='name'>{state.companyName}</div>
                        <div className='label'>{filterJoin([state.address ? state.address.split(',')[2] : '', state.recEducation, partType[state.partType], paymentType[state.paymentType]], ' | ')}</div>
                    </div>
                    {
                        state.positionType === 1 ? <div className='timeInfo'>兼职时间：{state.beginTime} 至 {state.endTime}</div> : null
                    }
                </div>
                <div className='commission'><span>{filterJoin([state.brokerage, brokerageUnit[state.brokerageUnit]], '/')}</span></div>
                <div className='deadline'><span>{state.period || 0}天</span></div>
                <div className='creditGold'><span>{state.credit}元</span></div>
                <div className='creditGold'><span>{parseInt(state.periodMin) >= 1 ? state.periodMin + '小时' : null || null}</span></div>
                <div className='recruitment'>{state.number}</div>
                <div className='apply'>{state.signNum || 0}</div>
                <div className='hasHired'>{state.pinged || 0}</div>
                <div className='state'>
                    {
                        state.type !== 1 ? (state.process === 0 ?
                            <Space className='processSpace' direction="vertical" onClick={(e) => { e.preventDefault() }}>
                                <Dropdown.Button
                                    icon={<DownOutlined />}
                                    overlay={menu}
                                    onClick={(e) => enterLoading(e)}
                                >
                                    {selectBtn.title}
                                </Dropdown.Button>
                            </Space> : process[state.process]) : null
                    }
                </div>
                <div className='operation' onClick={(e) => { e.preventDefault() }}>
                    <div className='edit' onClick={pushAdd}>推荐</div>
                    <div className='vertical'></div>
                    <div className={state.isShelf ? 'tuijian' : 'delete'} onClick={() => {
                        setPositionsShelf(state.id).then(res => {
                            if (res) {
                                props.update()
                            }
                        });
                    }}> {state.isShelf ? '下架' : '上架'} </div>
                    <div className='vertical'></div>
                    <div className={state.isTop ? 'tuijian' : 'delete'} onClick={() => {
                        setPositionsTop(state.id).then(res => {
                            props.update()
                        })
                    }}>{state.isTop ? '取消置顶' : '置顶'}</div>
                </div>
            </div>
        </NavLink >
    )
}
