import React, { useEffect, useState } from 'react'
import { Table, Button, Modal } from "antd"
import { detailsTable } from "./table"
import { useLocation } from "react-router-dom"
import {
    getPromotionDetails,
    getPromotionVideo,
    getArticleComment,
    delArticleComment,
    deleteVideo
} from "../../../api/recObtainGuide"
import AddVideo from './AddVideo'
import "./index.scss"
import { getUserInfo } from '../../../utils/auth'
export default function BooksDetails() {
    const [state, setState] = useState()
    const userInfo = JSON.parse(getUserInfo()) || {}
    const [params, setParams] = useState({ pageSize: 10, pageNo: 1 })
    const [paramsItem, setParamsItem] = useState({ visible: false })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [videoList, setVideoList] = useState([])
    const { state: id } = useLocation()
    const getPromotionVideoApi = () => {
        getPromotionVideo({ promotionId: id, pageSize: 1000 }).then(({ result }) => {
            setVideoList(result.records)
        })
    }
    useEffect(() => {
        getPromotionDetails({ id }).then(({ result }) => {
            setState(result)
        })
        getPromotionVideoApi()
    }, [])
    const getPromotionCommentApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        data.articleId = id
        getArticleComment(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    const delCommentApi = (data) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除当前项',
            okText: '删除',
            cancelButtonProps: true,
            onOk: () => {
                delArticleComment(data).then(res => {
                    if (res) {
                        getPromotionCommentApi()
                    }
                })
            }
        })
    }
    const deleteVideoApi = (id) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除当前项',
            okText: '删除',
            cancelButtonProps: true,
            onOk: () => {
                deleteVideo({ id }).then(res => {
                    if (res) {
                        getPromotionVideoApi()
                    }
                })
            }
        })
    }
    useEffect(() => {
        getPromotionCommentApi()
    }, [params])
    return (
        <div className='PromoteDetails BooksDetails'>
            <div style={{
                flex: 1
            }}>
                <div className='title'>{state?.title}</div>
                <div className='album'>
                    {
                        videoList.map((key, index) => {
                            return (
                                <div className='video'>
                                    <video src={key.video} controls key={index} />
                                    <div className='flexAlignCenter'>
                                        <div className='flex1'>第{key.course}节：{key.title}</div>
                                        <div className='edit iconfont flexs' onClick={e => { setParamsItem({ visible: true, params: key }) }}>&#xe8ac;</div>
                                    </div>
                                    <div className='delete' onClick={() => { deleteVideoApi(key.id) }}>&#xe8b6;</div>
                                </div>
                            )
                        })
                    }
                    {
                        userInfo?.nature === 'sys' ? <Button className='uploadButton' onClick={() => { setParamsItem({ visible: true }) }}>
                            <div className='iconfont'>&#xe60f;</div>
                            <p>支持MP4格式</p>
                        </Button> : null
                    }

                </div>
                <div className='content' dangerouslySetInnerHTML={{ __html: state?.content.replace(/[\n\r]/g, '<br/>') }} />
            </div>
            <div className='nav'>评论列表（{list.length}条）<span>点赞（{state?.likes ?? 0}）</span></div>
            <Table
                columns={userInfo?.nature === 'sys' ? [...detailsTable, ...[{
                    width: 88,
                    dataIndex: 'id',
                    align: 'center',
                    render: (id) => <div className='delItem' onClick={() => { delCommentApi({ id }) }}>删除</div>
                }]] : detailsTable}
                rowKey="id"
                dataSource={list}
                showHeader={false}
                pagination={{
                    total: total,
                    pageSize: params.pageSize,
                    current: params.pageNo,
                    onChange: (pageNo, pageSize) => { setParams(pageNo, pageSize) }
                }}
                scroll={{ y: 'calc(100vh - 100px)' }} />
            <AddVideo
                id={id}
                visible={paramsItem.visible}
                params={paramsItem.params}
                cancel={() => { setParamsItem({ visible: false }) }}
                update={getPromotionVideoApi}
            />
        </div>
    )
}
