import React, { useState, useEffect } from 'react'
import { Input, Select, Table, Modal, message } from "antd"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { columnsList } from "./table"
import { useNavigate, Outlet, useLocation } from "react-router-dom"
import { getEnterpriseList, deleteEnterprise, editCompanyInfo, editCompanyPhone } from "../../api/enterprise"
import { industry, objTurnArr, scale as scaleList, financing as financingList, property as propertyList } from "../../utils/specification"
import "./index.scss"
import { getUserInfo } from '../../utils/auth'
const { Option } = Select;
const { confirm } = Modal;
export default function List() {
    const userInfo = JSON.parse(getUserInfo()) || {}
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10, scale: 0, recIndustry: '', financing: 0, property: 0, type: '', status: 'SUCCESS' })
    const [total, setTotal] = useState(0)
    scaleList[0] = '不限规模'
    const scale = objTurnArr(scaleList)
    financingList[0] = '不限融资'
    const financing = objTurnArr(financingList)
    propertyList[0] = '所有类型'
    const property = objTurnArr(propertyList)
    const handleChange = (e) => {
        console.log(e)
    }
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setParams({ ...params, ...{ nameFull: e.target.value, pageNo: 1 } })
        };
        timeout = setTimeout(fake, 1000);
    }
    const getEnterpriseApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        for (let index in data) {
            if (!data[index]) {
                delete data[index]
            }
        }
        getEnterpriseList(data).then(({ result }) => {
            let { total, records } = result
            setList(records)
            setTotal(total)
        })
    }
    // 删除企业
    const delEnterprise = (id) => {
        deleteEnterprise({ id }).then(res => {
            if (res) {
                getEnterpriseApi()
            }
        })
    }
    // 修改登录手机号
    const editPhone = ({ id, mobile }) => {
        // console.log(id,mobile)
        function cheng(e) {
            mobile = e.target.value
        }
        confirm({
            title: '修改登录手机号',
            icon: <ExclamationCircleOutlined />,
            content: <Input style={{ marginTop: '10px' }} placeholder={mobile} maxLength={11} type='tel' onChange={e => cheng(e)} />,
            onOk() {
                return editCompanyPhone({ id, mobile }).then(res => { message.success("修改成功!"); getEnterpriseApi() })
            }
        });

    }
    useEffect(() => {
        if (pathname === '/enterprise/list') {
            getEnterpriseApi()
        }
    }, [params, pathname])
    return (
        <>
            {
                pathname === '/enterprise/list' ? <div className='enterprise'>
                    <div className='screening'>
                        <Input placeholder='输入企业名称搜索' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
                        <div>
                            <Select defaultValue={params.type} style={{ width: 170 }} onChange={(e) => { setParams({ ...params, ...{ type: e, pageNo: 1 } }) }}>
                                <Option value="">不限类型</Option>
                                <Option value={2}>人力资源</Option>
                                <Option value={1}>企业</Option>

                            </Select>
                            <Select defaultValue={params.recIndustry} style={{ width: 170 }} onChange={(e) => { setParams({ ...params, ...{ recIndustry: e, pageNo: 1 } }) }}>
                                <Option value="">不限行业</Option>
                                {
                                    industry.map((key, index) => {
                                        return <Option value={key} key={index}>{key}</Option>
                                    })
                                }
                            </Select>
                            <Select defaultValue={params.scale} style={{ width: 120 }} onChange={(e) => { setParams({ ...params, ...{ scale: e, pageNo: 1 } }) }}>
                                {
                                    scale.map((key, index) => {
                                        return <Option value={index} key={index}>{key}</Option>
                                    })
                                }
                            </Select>
                            <Select defaultValue={params.financing} style={{ width: 120 }} onChange={(e) => { setParams({ ...params, ...{ financing: e, pageNo: 1 } }) }}>
                                {
                                    financing.map((key, index) => {
                                        return <Option value={index} key={index}>{key}</Option>
                                    })
                                }
                            </Select>
                            <Select defaultValue={params.property} style={{ width: 120 }} onChange={(e) => { setParams({ ...params, ...{ property: e, pageNo: 1 } }) }}>
                                {
                                    property.map((key, index) => {
                                        return <Option value={index} key={index}>{key}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div >
                    <Table
                        columns={[...columnsList, ...[{
                            align: 'center', title: '操作', width: "228px", render: (key) =>
                                <div className='options'>
                                    <div onClick={e => { e.stopPropagation(); navigate('editor', { state: { ...key, ...{ recIndustry: key.recIndustry.split(',') } } }) }} className='passText iconfont'>&#xe8ac;</div>
                                    <div className='vertical' />
                                    <div className='rejectText iconfont' onClick={(e) => { e.stopPropagation(); delEnterprise(key.id) }}>&#xe8b6;</div>
                                    {
                                        userInfo?.nature === 'sys' ?
                                            <>
                                                <div className='vertical' />
                                                <div className='rejectText flexs' onClick={(e) => { e.stopPropagation(); editCompanyInfo({ ...key, ['isActor']: key.isActor ? 0 : 1 }).then(res => { if (res) { getEnterpriseApi() } }) }}>{key.isActor ? '已推优' : '推优'}</div>
                                                {
                                                    key.hrCompanyId === null ?
                                                        <>
                                                            <div className='vertical' />
                                                            <div className='rejectText' onClick={(e) => { e.stopPropagation(); editPhone(key) }}>更改手机号</div>
                                                        </> : null
                                                }

                                            </>
                                            : null
                                    }
                                </div>
                        }]]}
                        rowKey="id"
                        dataSource={list}
                        scroll={{ y: 'calc(100vh - 100px)' }}
                        onRow={r => {
                            return {
                                onClick: e => { navigate('details', { state: { id: r.id } }) }
                            }
                        }}
                        pagination={{
                            total: total,
                            current: params.pageNo,
                            pageSize: params.pageSize,
                            onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                        }} />
                </div> : <Outlet />
            }
        </>

    )
}
