import React, { useEffect, useState } from 'react'
import { Input, Image, Table, Switch } from "antd"
import { NavLink, useLocation } from 'react-router-dom'
import { getEnterpriseInfo } from "../../api/enterprise"
import { getEnterprisePositionList, setPositionsShelf } from "../../api/position"
import { filterJoin, financing, scale, objTurnArr, process } from "../../utils/specification"
import { positionColumns as columns } from "./table"
export default function Details() {
    const { state: { id } } = useLocation()
    const [state, setState] = useState({})
    const [table, setTable] = useState(1)
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10, id })
    const [total, setTotal] = useState(0)
    const [positions, setPositions] = useState([])
    const tableList = objTurnArr({ 1: '公司详情', 2: '职位列表' })

    // 获取企业详情数据
    useEffect(() => {
        getEnterpriseInfo({ id }).then(({ result }) => {
            console.log(result)
            if (result?.recIndustry) {
                result.recIndustry = result.recIndustry.split(',')
            }
            setState(result)
        })
    }, [])
    // 获取公司职位数据
    const getEnterprisePositionApi = () => {
        getEnterprisePositionList(params).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setPositions(records)
        })
    }
    useEffect(() => {
        getEnterprisePositionApi()
    }, [params])
    return (
        <div className='enterpriseDetails'>
            <div className='basicsInfo'>
                <div className='info'>
                    <img className='enterpriseLogo' src={state.logo} alt="" />
                    <div className='details'>
                        <div className='name'>{state.nameFull} <span>（{state.name}）</span> </div>
                        <NavLink to="../editor" state={state}>完善信息</NavLink>
                        <div className='tag'>{filterJoin([financing[state.financing], scale[state.scale], state.recIndustry], ' · ')}</div>
                    </div>
                </div>
                <div className='sitting'>
                    <div>在招职位</div>
                    <div>{state.count}</div>
                </div>
            </div>
            <div className='table'>
                {
                    tableList.map((key, index) => {
                        console.log(table == index)
                        return <div className={filterJoin(['tableItem', table === index ? 'tableItemAct' : null])} onClick={() => { setTable(index) }} key={index}>{key}</div>
                    })
                }
            </div>
            {
                table === 1 ? <div className='enterpriseContent'>
                    <div className='row'>
                        <div className='title'>{state.name}简介</div>
                        <Input.TextArea className='introduce' value={state.introduction} disabled bordered={false} autoSize={true} />
                    </div>
                    <div className='row'>
                        <div className='title'>营业执照</div>
                        <div className='photo'>
                            <div className='photoItem'>
                                <Image src={state.license} alt="营业执照" />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='title'>{state.nameFull}</div>
                        <div className='principal'>
                            <div>联系电话：{state.mobile || '暂无'}</div>
                            <div>联系地址：{state.address || '暂无'}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='title'>{state.name}环境</div>
                        <div className='photo'>
                            {state.video ? <div className='photoItem'><video src={state.video} /></div> : null}
                            {
                                state.photo ? <Image.PreviewGroup>
                                    {
                                        state.photo.split(',').map((key, index) => {
                                            return (
                                                <div className='photoItem'>
                                                    <Image src={key} key={index} alt="公司环境" />
                                                </div>
                                            )
                                        })
                                    }
                                </Image.PreviewGroup> : null
                            }
                        </div>
                    </div>
                </div> :
                    <Table
                        className='tableColumns'
                        columns={[...columns, ...[
                            {
                                title: '是否上架',
                                dataIndex: 'isShelf',
                                align: 'center',
                                render: (isShelf, r) =>
                                    <div className='' onClick={(e) => { e.stopPropagation() }}>
                                        <Switch checkedChildren="上架" unCheckedChildren="下架" checked={Boolean(isShelf)} onClick={e => {
                                            setPositionsShelf(r.id).then(res => {
                                                if (res) {
                                                    getEnterprisePositionApi()
                                                }
                                            })
                                        }} />
                                    </div>
                            },
                            {
                                title: '状态',
                                dataIndex: 'process',
                                render: (value) => <>{process[value]}</>,
                                align: 'center',
                            }
                        ]]}
                        dataSource={positions}
                        rowKey="id"
                        scroll={{ y: 'calc(100vh - 100px)' }}
                        pagination={{
                            total: total,
                            current: params.pageNo,
                            pageSize: params.pageSize,
                            onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                        }} />
            }
        </div>
    )
}
