import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Table, Dropdown, Menu, Modal,message } from "antd"
import { getPrefaceList, deletePreface, prefaceTop } from "../../../api/recObtainGuide"
import { FrontierTable } from "../constans"
import "../guide.scss"
import Editor from './Editor'
import { getUserInfo } from '../../../utils/auth'
export default function Frontier() {
	const [total, setTotal] = useState(0)
	const userInfo = JSON.parse(getUserInfo()) || {}
	const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
	const navigate = useNavigate()
	const [list, setList] = useState([])
	const [paramsItem, setParamsItem] = useState({ visible: false })
	// 获取列表
	const getPrefaceListApi = () => {
		getPrefaceList(params).then(({ result }) => {
			let { records, total } = result
			setList(records)
			setTotal(total)
		})
	}
	const deleteChange = (data) => {
		Modal.confirm({
			title: '提示',
			content: '是否删除当前项',
			okText: '删除',
			cancelButtonProps: true,
			onOk: () => {
				deletePreface(data).then(res => {
					if (res) getPrefaceListApi()
				})
			}
		})
	}
	let timeout;
	// input 输入选择
	const inputChange = (e) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		const fake = () => {
			setParams({ ...params, ...{ title: e.target.value ? `,${e.target.value},` : '' } })
		};
		timeout = setTimeout(fake, 1000);
	}
	useEffect(() => {
		getPrefaceListApi()
	}, [params])
	return (
		<div className='financialScreen'>
			<div className='screening'>
				<div className='ScreenContent'>
					<Input placeholder='搜索部落' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
					<Button onClick={getPrefaceListApi} type='primary'>搜索</Button>
				</div>
				{
					userInfo?.nature === 'sys' ? <Dropdown overlay={<Menu items={[
						{
							key: 1,
							label: (<div className='operationBox' onClick={() => { setParamsItem({ visible: true, type: 1 }) }}>视频</div>)
						},
						{
							key: 2,
							label: (<div className='operationBox' onClick={() => { setParamsItem({ visible: true, type: 2 }) }}>动态</div>)
						}
					]} />} placement="bottom" arrow>
						<Button type="primary"><span className='iconfont'>&#xe684;</span> 新建</Button>
					</Dropdown> : null
				}
			</div>
			<div className="intervalTop"></div>
			<Table
				columns={userInfo?.nature === 'sys' ? [...FrontierTable, ...[{
					title: '操作',
					align: 'center',
					width: 198,
					render: (t, r) => <div className='operation' onClick={(e) => { e.preventDefault() }}>
						<div className="edit" onClick={(e) => {
							e.stopPropagation()
							prefaceTop({ id: t.id }).then(res => {
								message.success(res.message)
								getPrefaceListApi()
							})
						}}>{t.isTop ? '已置顶' : '置顶'}</div>
						<div className='vertical'></div>
						<div className="edit" onClick={(e) => {
							e.stopPropagation()
							setParamsItem({ visible: true, params: r, type: r.type })
						}}>编辑</div>
						<div className='vertical'></div>
						<div onClick={(e) => {
							e.stopPropagation()
							deleteChange({ id: r.id })
						}} className="reject">删除</div>
					</div>
				}]] : FrontierTable}
				dataSource={list}
				onRow={r => {
					return {
						onClick: e => {
							navigate('details', { state: r.id })
						}
					}
				}}
				rowKey="id"
				scroll={{ y: 'calc(100vh - 100px)' }}
				pagination={{
					total: total,
					current: params.pageNo,
					pageSize: params.pageSize,
					onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
				}}
			/>
			<Editor
				type={paramsItem.type}
				visible={paramsItem.visible}
				params={paramsItem.params}
				cancel={() => { setParamsItem({ visible: false }) }}
				upload={() => { getPrefaceListApi() }}
			/>
		</div>
	)
}
