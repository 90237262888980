import React, { useEffect, useState, useRef } from 'react'
import { Modal, Table } from "antd"
import ReactDOM from "react-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { getResumeInfo, getSnapshootResume, getWitness, getPDFDetailis } from "../../api/commendBook"
import { politics, filterJoin, configuration } from "../../utils/specification"
import { booksList } from "./table"
import timeFormat from "../../utils/timeFormat"
export default function ResumeDetails() {
    const navigate = useNavigate()
    const embedRef = useRef()
    const { state } = useLocation()
    const [resume, setResume] = useState({
        stuAwardCertificates: [], // 奖励证书
        stuAwards: [], // 竞赛获奖经历
        stuEducate: [], // 教育经历
        stuHobbies: [], // 兴趣爱好
        stuIntention: [], // 求职期望
        stuOthers: [], // 其他
        stuPractices: [], // 实习经验
        stuProjects: [], // 项目经验
        stuResearch: [], // 研究经验
        stuSocieties: [], // 社团和组织经验
        stuTribes: [], // 职才部落经历
        stuWorks: [] // 作品集
    })
    const [PDF, setPDF] = useState(null)
    // 获取见证列表
    const getWitnessApi = (id) => {
        console.log(id)
        getWitness({ resumeId: id }).then(({ result }) => {
            console.log(result)
            Modal.warning({
                title: '选择手账本',
                icon: null,
                content: <Table
                    columns={booksList}
                    dataSource={result.records}
                    pagination={false}
                    centered={true}
                    onRow={r => {
                        return {
                            onClick: e => {
                                navigate('/commendBook/books/details', {
                                    state: r
                                });
                                Modal.destroyAll();
                            }
                        }
                    }}
                />,
                width: 640
            })
        })
    }
    useEffect(() => {
        if (state?.applyId) {
            getSnapshootResume({ id: state.applyId }).then(({ result }) => {
                let { snapshot } = result
                let obj = JSON.parse(snapshot)
                setResume(obj)
                if (obj?.attachedResumeId) {
                    getPDFDetailis({ id: obj.attachedResumeId }).then(res => {
                        setPDF(res)
                    })
                }
            })
        } else {
            getResumeInfo({ id: state.resumeId }).then(({ result }) => {
                setResume(result)
                if (result.attachedResumeId) {
                    getPDFDetailis({ id: result.attachedResumeId }).then(({ result }) => {
                        setPDF(result)
                    })
                }
            })
        }
    }, [])
    return (
        <div className='ResumePreview'>
            <main>
                <div className='basicsInfo'>
                    <div className='info'>
                        <div className='post'>
                            {state.name}
                            {
                                resume?.stuIntention?.length ?
                                    <><span>求职期望：{filterJoin(resume?.stuIntention[0]?.post?.split('-'), ' · ')}</span>
                                        <span>{resume?.stuIntention[0]?.money}</span></>
                                    : null
                            }
                        </div>
                        <div className='label'>
                            {
                                state.city ? <span>{state.city.split('-')[1]}</span> : null
                            }
                            {
                                state.graduate ? <span>{state.graduate}届</span> : null
                            }
                            <span>{state.education}</span>
                            <span>{politics[state.politics]}</span>
                            <span>{state.age || 0}岁</span>
                        </div>
                        <div className='label'>{filterJoin([state.school, state.specialty], ' · ')}</div>
                    </div>
                    <img className='avarat' src={resume.homepage} />
                </div>

                {
                    PDF ? <iframe src={PDF.url} id="iframe" onLoad={() => {  }} style={{ width: '100%', height: "calc(100vh - 388px)", overflow: "visible",marginTop:20 }}></iframe> :
                        configuration.map(key => {
                            return (
                                <>
                                    {
                                        resume[key.type]?.length ?
                                            <div key={key.type}>
                                                <h1>{key.title}</h1>
                                                {
                                                    resume[key.type].map(item => {
                                                        return (
                                                            <div className='rows'>
                                                                <div className='flexAlignCenter'>
                                                                    <div className='title'>
                                                                        {
                                                                            key.type === 'stuIntention' ? <>{filterJoin(item?.post?.split('-'), ' · ')}&nbsp;&nbsp;&nbsp;{item.money}</> : null
                                                                        }
                                                                        {
                                                                            (item.name || item.school || item.projectName || item.societyName) ? <>{item.name || item.school || item.projectName || item.societyName}</> : null
                                                                        }
                                                                    </div>
                                                                    <span className='time'>
                                                                        {
                                                                            item.type === 'stuEducate' ?
                                                                                <>{timeFormat(item.admissionTime, 'yyyy-mm')} - {timeFormat(item.graduationTime, 'yyyy-mm')}</> :
                                                                                ['stuTribes', 'stuPractices', 'stuProjects', 'stuResearch', 'stuSocieties', 'stuAwards', 'stuOthers'].includes(key.type) ?
                                                                                    <>{timeFormat(item.startTime, 'yyyy-mm')} - {timeFormat(item.endTime, 'yyyy-mm')}</> : null
                                                                        }
                                                                    </span>
                                                                </div>
                                                                {
                                                                    key.li ? <div className='label'>{filterJoin([item.department, item.role, item.position])}</div> : null
                                                                }
                                                                <div className='content'>
                                                                    {
                                                                        key.type === 'stuIntention' ? <>{filterJoin([item.city, item.industry])}</> :
                                                                            key.type === 'stuEducate' ? <>{filterJoin([item.education, item.profession])}</> :
                                                                                <>{item.description}</>
                                                                    }
                                                                </div>
                                                                {
                                                                    item.state === 1 ? <div className='witness'>
                                                                        <div />
                                                                        <div className='iconfont' onClick={() => { getWitnessApi(item.id) }}>
                                                                            <div className='text'>见证</div>
                                                                        </div>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> : null
                                    }
                                </>
                            )
                        })
                }
            </main>
        </div>
    )
}
