import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Pagination } from "antd"
import { getPositionsMembers } from "../../../api/position"
import { getUserInfo } from '../../../utils/auth'
import CapitalAudit from "./CapitalAudit"
import { Route } from 'react-router-dom'
export default function Existing(props) {
    const userInfo = JSON.parse(getUserInfo()) || {}
    const [members, setMembers] = useState({ pageNo: 1, pageSize: 10 })
    const [params, setParams] = useState({ visible: false })
    const [student, setStudent] = useState([])
    const [membersList, setMembersList] = useState([])
    const [total, setTotal] = useState(0)
    const getPositionsMembersApi = () => {
        let data = JSON.parse(JSON.stringify(members))
        data.positionId = props.id
        getPositionsMembers(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setMembersList(records)
        })
    }
    const updatePage = (e) => {
        setMembers({ ...members, ...e })
        setStudent([])
    }
    const studentAll = () => {
        if (student.length) {
            setStudent([])
            return false
        }
        let data = membersList.map(key => { if (key.settle == 0) return key.studentId }).filter(key => { if (key) return key })
        console.log(data)
        setStudent([...student, ...data])
    }
    const addStudent = (studentId) => {
        if (student.includes(studentId)) {
            let list = student.filter(key => key != studentId)
            setStudent(list)
        } else {
            setStudent([...student, ...[studentId]])
        }
    }
    useEffect(() => {
        getPositionsMembersApi()
    }, [members])
    return (
        <>
            {
                membersList.length ? <div className="position" style={{ zIndex: 10 }}>
                    <div className='provide'>
                        <Button disabled={!student.length} type='primary' onClick={() => setParams({ visible: true })}>一键发放</Button>
                    </div>
                    <div className='recordingHead'>
                        <Checkbox className='number' checked={student.length} onChange={() => studentAll()}>编号</Checkbox>
                        <div className='basisInfo'>基本信息</div>
                        {/* <div className='officer'>猎才官</div> */}
                        {/* <div className='days'>就职天数</div> */}
                        {/* <div className='results'>成果</div> */}
                        {/* <div className='state'>状态</div> */}
                        <div className='induction'>手机号</div>
                        <div className='induction'>入职时间</div>
                        <div className='induction'>发放金额</div>
                        <div className='induction'>发放时间</div>
                        {/* <div className='departure'>离职时间</div> */}
                        {
                            userInfo?.nature === 'finance' ? <div className='state'>操作</div> : null
                        }
                    </div>
                    {
                        membersList.map((key, index) => {
                            return (
                                <div className='detailsItem' key={index}>
                                    <Checkbox className='number' disabled={key.settle} checked={student.includes(key.studentId)} onChange={() => addStudent(key.studentId)}>{index + 1}</Checkbox>
                                    <div className='basisInfo'>
                                        <img className='avatar' src={key.avatar} alt="" />
                                        <div>
                                            <div className='flexAlignBaseline'>
                                                <div className='nickname'>{key.name}</div>
                                                <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                                                <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                                            </div>
                                            <div className='schoolInfo'>
                                                {key.school}{key.specialty ? ' · ' + key.specialty : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='officer'>{key.superiorName}</div>
                                    <div className='days'>{key.working}</div>
                                    <div className='results'>{key.isBook ? '汇报' : '待汇报'}</div>
                                    <div className='state'>{key.status ? '在职' : '离职'}</div> */}
                                    <div className='induction'>{key.phone}</div>
                                    <div className='induction'>{key.startTime}</div>
                                    <div className='departure'>{parseFloat(key?.payMoney ?? 0).toFixed(2)}</div>
                                    <div className='departure'>{key?.payTime ?? ''}</div>
                                    {
                                        userInfo?.nature === 'finance' ? (
                                            <div className='state' style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                                {
                                                    key.settle === 1 ?
                                                        <>已结算</> :
                                                        <div className='typeItem typeItemAct' style={{ fontSize: '14px' }} onClick={() => { setParams({ ...key, ['visible']: true }) }}>资金发放</div>
                                                }
                                            </div>) : null
                                    }
                                </div>
                            )
                        })
                    }
                    <Pagination showSizeChanger className='page' defaultCurrent={members.pageNo} total={total} pageSize={members.pageSize} onChange={(pageNo, pageSize) => { updatePage({ pageNo, pageSize }) }} />
                    <CapitalAudit student={student} {...params} positionId={props.id} cancel={() => { setParams({ visible: false }); setStudent([]) }} update={getPositionsMembersApi} />
                </div> : null
            }
        </>

    )
}
