import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Button, Upload, message } from "antd"
import {
    campDynamicAdd,
    campDynamicEdit,
    campVideoAdd,
    campVideoEdit
} from "../../../api/recObtainGuide"
import "../guide.scss"
import "./index.scss"
export default function Editor(props) {
    const [fileList, setFileList] = useState([])
    const fileChange = ({ fileList: newFileList }) => {
        if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
            if (props?.params?.type === 1) {
                fileList[0] = newFileList[newFileList.length - 1].response.result.url
            } else {
                fileList.push(newFileList[newFileList.length - 1].response.result.url)
            }
            setFileList([...fileList])
        }
    };
    const dynamic = (e) => {
        let res = ''
        if (props.params.id) {
            res = campDynamicEdit({ ...props.params, ...e })
        } else {
            e.type = props.params.type
            e.campId = props.campId
            res = campDynamicAdd(e)
        }
        res.then(item => {
            if (item) {
                setFileList([])
                props.update()
                props.cancel()
            }
        })
    }
    const video = (e) => {
        let res = ''
        if (props.params.id) {
            res = campVideoEdit({ ...props.params, ...e })
        } else {
            e.campId = props.campId
            res = campVideoAdd(e)
        }
        res.then(item => {
            if (item) {
                setFileList([])
                props.update()
                props.cancel()
            }
        })
    }
    const onFinish = (e) => {
        if (!fileList.length) {
            message.error(`请上传${props.type === 2 ? '封面图片' : props.type === 3 ? '视频' : ''}`)
            return false
        }
        switch (props.type) {
            case 2:
                e.titleUrl = fileList.join(',');
                dynamic(e)
                break;
            case 3:
                e.video = fileList.join(',')
                video(e)
                break;
        }
    }
    useEffect(() => {
        if (props?.params?.id) {
            switch (props.type) {
                case 2:
                    setFileList(props.params.titleUrl.split(',') || [])
                    break;
                case 3:
                    setFileList([props.params.video] || [])
                    break;
            }
        }
    }, [props.params])
    const VideoUpload = () => {
        return (
            <Form.Item label="视频" className='requireds'>
                <Upload
                    action={process.env.REACT_APP_BASE_UPLOAD_API}
                    onChange={(e) => { fileChange(e) }}
                    accept=".mp4"
                >
                    {
                        fileList.length ?
                            fileList.map((key, index) => {
                                return <div className="uploadButton uploadButtonImg" key={index}>
                                    <video src={key} />
                                </div>
                            })
                            :
                            <Button className='uploadButton'>
                                <div className='iconfont'>&#xe60f;</div>
                                <p>支持MP4格式</p>
                            </Button>
                    }
                </Upload>
            </Form.Item>
        )
    }
    const ImageUpload = () => {
        return (
            <Form.Item label="封面图片" className='requireds'>
                {
                    fileList.map((key, index) => {
                        return <div className="uploadButton uploadButtonImg" key={index}>
                            <img src={key} />
                        </div>
                    })
                }
                <Upload
                    action={process.env.REACT_APP_BASE_UPLOAD_API}
                    onChange={(e) => { fileChange(e) }}
                    accept=".png,.jpg"
                >
                    <Button className='uploadButton'>
                        <div className='iconfont'>&#xe60f;</div>
                        <p>不超过10M</p>
                        <p>支持PNG/JPG格式</p>
                    </Button>
                </Upload>
            </Form.Item>
        )
    }
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            width={638}
            destroyOnClose
            maskClosable
            wrapClassName="reserveModal"
            className="dynamicEditorModal"
            onCancel={() => { props.cancel() }}
        >
            <Form
                initialValues={{
                    remember: true,
                    ...props.params
                }}
                onFinish={onFinish}
                autoComplete="off"
                className='editor'
            >
                <Form.Item name="title" label="标题" rules={[{ required: true, message: '请输入你的标题' }]}>
                    <Input placeholder="请输入你的标题" />
                </Form.Item>
                {
                    props.type === 2 ?
                        <>
                            <Form.Item name="content" label="内容" rules={[{ required: true, message: '请描述你的内容' }]}>
                                <Input.TextArea placeholder="请描述你的内容" />
                            </Form.Item>
                            {
                                props?.params?.type === 2 ?
                                    <ImageUpload /> :
                                    props?.params?.type === 1 ?
                                        <VideoUpload /> : null
                            }
                        </> :
                        props.type === 3 ? <>
                            <Form.Item name="content" label="内容" rules={[{ required: true, message: '请描述你的内容' }]}>
                                <Input.TextArea placeholder="请描述你的内容" />
                            </Form.Item>
                            <VideoUpload />
                        </> : null
                }
                <Form.Item name="url" label="公众号" >
                    <Input placeholder="请输入公众号链接" />
                </Form.Item>
                <div className='flexSbCenter' >
                    <Button type="default" onClick={() => { props.cancel(); setFileList([]) }} loading={false} className='submit'>取消</Button>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>确定</Button>
                </div>
            </Form>
        </Modal>
    )
}
