import React, { useEffect, useState } from 'react'
import { getHomeData, getMonthlyStatistics } from "../../api/home"
import "./index.scss"
import * as echarts from "echarts"
import { positionType } from '../../utils/specification'

export default function Index() {
	const fields = { 0: '今日', 1: '昨日', 2: '本月', 3: '上月' }
	const member = [
		{ type: 'todayCount', color: '#2D69EB' },
		{ type: 'yesterdayCount', color: '#29C19B' },
		{ type: 'monthCount', color: '#FCB534' },
		{ type: 'toMonth', color: '#FB8F8C' }
	] // 顶部统计数据
	const [assumption, setAssumption] = useState([
		{
			color: 'linear-gradient(157deg, #9794FF 0%, #588CFB 100%)',
			data: [],
		},
		{
			color: 'linear-gradient(180deg, #3DC9CE 0%, #50DFBB 100%)',
			data: []
		},
		{
			color: 'linear-gradient(180deg, #FF7060 0%, #FCB534 100%)',
			data: []
		},
		{
			color: 'linear-gradient(180deg, #FB8CCB 0%, #FB8F8C 100%)',
			data: []
		}
	]) // 就职统计
	const hunter = {
		background: '#FFECEC',
		color: '#E9513E',
		childer: ['todayHunter', 'yesterdayHunter', 'monthHunter', 'toMonthHunter']
	} // 猎才官统计
	const just = {
		background: '#FFF5E4',
		color: '#FCB534',
		childer: ['toMonthCamp', 'yesterdayCamp', 'monthCamp', 'toMonthCamp']
	}// 储备营统计
	const [details, setDetails] = useState({
		monthCamp: 0,
		monthCount: 0,
		monthHunter: 0,
		toMonth: 0,
		toMonthCamp: 0,
		toMonthHunter: 0,
		todayCamp: 0,
		todayCount: 0,
		todayHunter: 0,
		yesterdayCamp: 0,
		yesterdayCount: 0,
		yesterdayHunter: 0,
	})
	// 格式化就职信息
	const assumptionFormatting = (data) => {
		let assumptionInfo = JSON.parse(JSON.stringify(assumption))
		for (let index in data) {
			let list = [0, 0, 0, 0], sub = 0
			data[index].forEach(key => {
				if (key.type) {
					list[key.type - 1] = key.count
				}
			})
			switch (index) {
				case 'monthWork':
					sub = 2
					break;
				case 'toMonthWork':
					sub = 3
					break;
				case 'yesterdayWork':
					sub = 1
					break;
			}
			assumptionInfo[sub].data = list
		}
		setAssumption(assumptionInfo)
	}
	useEffect(() => {
		getHomeData().then(({ result }) => {
			assumptionFormatting(result.params)
			delete result.params
			setDetails(result)
		})
		getMonthlyStatistics().then(({ result }) => {
			console.log(result, 'getMonthlyStatisticss')
			let numberArr = []
			let incomeArr = []
			result.forEach(key => {
				numberArr.push(key.number)
				incomeArr.push(key.income)
			})
			let myChart = echarts.init(document.getElementById('monthly'));
			myChart.setOption({
				tooltip: {},
				xAxis: {
					data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
				},
				yAxis: {},
				series: [
					{
						type: 'bar',
						barWidth: '20%',
						data: numberArr,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#FFCF7D' }, { offset: 1, color: '#EF9700' }])
						}
					},
					{
						type: 'bar',
						data: incomeArr,
						barWidth: '20%',
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#78A5FF' }, { offset: 1, color: '#0155FE' }])
						}
					}
				],

			});
		})
	}, [])
	return (
		<div className='IndexPage'>
			<div className='memberBox flexAlignCenter'>
				{member.map((key, index) => {
					return (
						<div key={index} className='memberItem' style={{ backgroundImage: `url(/assets/home${index + 1}.png)` }}>
							<div className='memberTitle'> <span style={{ background: key.color }}></span>{fields[index]}新增人数</div>
							<div className='memberNumber'>{details[key.type]}人</div>
						</div>
					)
				})}
			</div>
			<div className='centent'>
				<div className='leftBox'>
					<div className='monthlyBox'>
						<div className='monthlyHeader flexAlignCenter'>
							<div className='monthlyTitle'>月度统计</div>
							<div className='monthlyType flexAlignCenter'>
								<div className='monthlyItem'>注册人数</div>
								<div className='monthlyItem'>收益金额（￥）</div>
							</div>
						</div>
						<div id='monthly'></div>
					</div>
					<div className='assumption'>
						{
							assumption.map((key, index) => {
								return (
									<div className='assumptionItem' key={index}>
										<div className='title' style={{ background: key.color }}>{fields[index]}就职</div>
										<div className='postNumber'>
											{
												key.data.map((item, index) => {
													return (
														<div className='postNumberItem' key={index}>
															<div>{positionType[index + 1]}</div>
															<div>{item}人</div>
														</div>
													)
												})
											}
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
				<div className='rightBox'>
					<div className='rightBoxRows'>
						<div className='rightTitle flexAlignCenter'>
							<div className='vertical' style={{ background: hunter.color }}></div>
							<span style={{ color: hunter.color }}>预备委员统计</span>
						</div>
						<div className='rightWrap'>
							{
								hunter.childer.map((item, index) => {
									return (
										<div className='rightItem' key={index} style={{ background: hunter.background }}>
											<div className='title'>{fields[index]}加入预备委员人数</div>
											<div className='number' style={{ color: hunter.color }}>{details[item]}人</div>
										</div>
									)
								})
							}
						</div>
					</div>
					<div className='rightBoxRows'>
						<div className='rightTitle flexAlignCenter'>
							<div className='vertical' style={{ background: just.color }}></div>
							<span style={{ color: just.color }}>导师工作坊统计</span>
						</div>
						<div className='rightWrap'>
							{
								just.childer.map((item, index) => {
									return (
										<div className='rightItem' key={index} style={{ background: just.background }}>
											<div className='title'>{fields[index]}加入工作坊人数</div>
											<div className='number'>{details[item]}人</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
