import React, { useState, useEffect } from 'react'
import { Modal, Table } from "antd"
import { columns } from "./table.js"
import { getPushList, delPush } from "../../api/position"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import "./push.scss"
export default function Push() {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState()
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
    const [total, setTotal] = useState(0)
    // 定义获取推送库列表
    const getPushApi = () => {
        getPushList(params).then(({ result }) => {
            let { total, records } = result
            setList(records)
            setTotal(total)
        })
    }
    // 定义删除推送库列表
    const delPushApi = (id) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除当前项',
            okText: '删除',
            cancelButtonProps: true,
            onOk: () => {
                delPush({id}).then(res => {
                    if (res) {
                        getPushApi()
                    }
                })
            }
        })
    }
    useEffect(() => {
        if (pathname === '/position/push') {
            getPushApi()
        }
    }, [params, pathname])
    return (
        <>
            {
                pathname === '/position/push' ? <Table
                    className='pushList'
                    columns={[...columns, ...[
                        {
                            title: '操作',
                            width: 100,
                            align: 'center',
                            dataIndex: 'id',
                            render: (t) => <div className='flexAlignCenter'>
                                <div className='del' onClick={(e) => { e.stopPropagation(); delPushApi(t) }}>&#xe8b6;</div>
                            </div>
                        }
                    ]]}
                    rowKey="id"
                    onRow={r => {
                        return {
                            onClick: e => {
                                navigate('details', {
                                    state: { id: r.id }
                                })
                            }
                        }
                    }}
                    dataSource={list}
                    pagination={{
                        total: total,
                        current: params.pageNo,
                        pageSize: params.pageSize,
                        onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                    }}
                    scroll={{ y: 'calc(100vh - 100px)' }}
                /> : <Outlet />
            }
        </>

    )
}
