import React, {useEffect, useState } from 'react'
import Screening from './components/Screening'
import { getHunterList, setBlacklist } from "../../api/hunting"
import { Table } from "antd"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { columnsList } from "./table"
import "./hunting.scss"
export default function List() {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const getHunterListApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        for (let index in data) {
            if(!data[index]){
                delete data[index]
            }
        }
        getHunterList(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    const blacklist = (id) => {
        setBlacklist(id).then(({ result }) => {
            let arr = JSON.parse(JSON.stringify(list))
            arr.forEach(key => {
                if (key.id === id) {
                    key.isBlack = result.isBlack
                }
            })
            setList(arr)
        })
    }
    useEffect(() => {
        getHunterListApi()
    }, [params])
    return (
        pathname === '/hunting/list/details'
            ? <Outlet />
            : <div className='huntingPage'>
                <Screening state={(e)=>{setParams({...params,...e})}} />
                <Table columns={[
                    ...columnsList, ...[{
                        title: '操作',
                        align: 'center',
                        width: 128,
                        render: (key) => <div className='options'>
                            <div className={key.isBlack ? 'join' : 'remove'} onClick={(e) => { e.stopPropagation(); blacklist(key.id) }}>{key.isBlack ? '移除黑名单' : '加入黑名单'}</div>
                        </div>
                    }
                    ]]} dataSource={list} scroll={{ y: 'calc(100vh - 100px)' }}
                    pagination={{
                        total: total,
                        current: params.pageNo,
                        pageSize: params.pageSize,
                        onChange: (pageNo, pageSize) => { setParams({ ...{ pageNo, pageSize } }) }
                    }}
                    rowKey="id"
                    onRow={r => { return { onClick: e => { navigate('details', { state: r.id }) } } }} />
            </div>
    )
}
