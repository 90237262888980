import request from '../utils/request'
// 登录接口
export function getHomeData(data) {
  return request({
    url: 'sys/admin/front/page',
    method: 'post',
    data
  })
}

// 获取月度统计
export function getMonthlyStatistics(){
  return request({
    url:'sys/admin/monthly',
    method:'get'
  })
}
