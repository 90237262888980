import { BrowserRouter } from "react-router-dom"
import { RoutesList } from './routes';

import "./App.css"

export default function App() {
  return (
    <BrowserRouter>
      <RoutesList />
    </BrowserRouter>
  )
};
