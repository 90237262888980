import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Table } from "antd"
import { getCampList, isTopCamp, offline, online } from "../../../api/recObtainGuide"
import { tribe } from "../constans"
import "../guide.scss"
import Editor from './Editor'
import { getUserInfo } from '../../../utils/auth'
export default function ReserveCampListEditorList() {
	const navigate = useNavigate()
	const userInfo = JSON.parse(getUserInfo()) || {}
	const [params, setParams] = useState({ pageNo: 1, pageSize: 10, name: '' })
	const [itemInfo, setItemInfo] = useState({ visible: false })
	const [list, setList] = useState([])
	const [total, setTotal] = useState(0)
	const getCampListApi = () => {
		let data = JSON.parse(JSON.stringify(params))
		for (let index in data) {
			if (!data[index]) {
				delete data[index]
			}
		}
		getCampList(data).then(({ result }) => {
			let { records, total } = result
			setList(records)
			setTotal(total)
		})
	}
	let timeout;
	// input 输入选择
	const inputChange = (e) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		const fake = () => {
			setParams({ ...params, ...{ name: e.target.value } })
		};
		timeout = setTimeout(fake, 1000);
	}

	// 上下架部落
	const unmount = ({ id, isOnline }) => {
		if (isOnline) offline(id).then(res => { setParams({ ...params, ['pageNo']: 1 }) })
		else online(id).then(res => { setParams({ ...params, ['pageNo']: 1 }) })
	}
	useEffect(() => {
		getCampListApi()
	}, [params])
	return (
		<div className='financialScreen'>
			<div className='screening'>
				<div className='ScreenContent'>
					<Input placeholder='搜索部落' onInput={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
					<Button type='primary' onClick={getCampListApi}>搜索</Button>
				</div>
				{
					userInfo?.nature === "sys" ? <Button type="primary" onClick={() => { setItemInfo({ visible: true }) }}><span className='iconfont'>&#xe684;</span> 新建</Button> : null
				}
			</div>
			<Table
				columns={userInfo?.nature === 'sys' ? [...tribe, ...[{
					title: '操作',
					align: 'center',
					width: 190,
					render: (t, r) => <div className='operation' onClick={(e) => { e.preventDefault() }}>
						<div className="reject" onClick={e => {
							e.stopPropagation();
							isTopCamp(r.id).then(res => { if (res) { getCampListApi() } })
						}}
						>
							{r.isTop ? '已置顶' : '置顶'}
						</div>
						<div className="vertical"></div>
						<div className="edit" onClick={(e) => {
							e.stopPropagation();
							setItemInfo({ visible: true, params: { ...r, ...{ label: r.label.split(',') } } })
						}}>编辑</div>
						<div className="vertical"></div>
						<div className="edit" onClick={(e) => {
							e.stopPropagation();
							unmount(r)
						}}>{r.isOnline == 1 ? '下' : '上'}架</div>
					</div>
				}]] : tribe}
				dataSource={list}
				onRow={r => { return { onClick: e => { navigate('details', { state: r, }) } } }}
				rowKey="id"
				scroll={{ y: 'calc(100vh - 100px)' }}
				pagination={{
					total: total,
					current: params.pageNo,
					pageSize: params.pageSize,
					onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
				}}
			/>
			<Editor visible={itemInfo.visible} update={getCampListApi} params={itemInfo.params} cancel={() => { setItemInfo({ visible: false }) }} />
		</div>
	)
}
