import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, Table, Dropdown, Menu, message, Select, Modal } from "antd"
import {
	getCampPersonnelList,
	getCampArticlelList,
	getCampVideoList,
	getCampEnterpriseList,
	deleteMember, // 移除学生成员
	deleteDynamic,
	deleteVideo,
	campDynamicEdit, // 编辑动态,
	editCamplabel, // 给予/移除部落职才标签,
	dynamicTop, // 动态置顶,
	deleteSpecify // 移除绑定企业
} from "../../../api/recObtainGuide"
import { getPushList, studentsAddPush } from "../../../api/position"
import { student, dynamic, video, enterprise } from "./table"
import { objTurnArr } from '../../../utils/specification'
import DynamicEditor from "./DynamicEditor" // 动态列表和视频列表数据新增/编辑
import DynamicAudit from "./DynamicAudit"
import { getUserInfo } from '../../../utils/auth'
import "./index.scss"
const { Option } = Select

export default function Details() {
	const userInfo = JSON.parse(getUserInfo()) || ''
	const navigate = useNavigate()
	const { state } = useLocation()
	const typeList = objTurnArr({
		1: "人员列表",
		2: "动态列表",
		3: "视频列表",
		4: '企业列表'
	})
	const [params, setParams] = useState({ pageNo: 1, pageSize: 10, type: 1, name: '' })
	const [status, setStatus] = useState(1)
	const [list, setList] = useState([])
	const [options, setOptions] = useState([])
	const [dynamicItem, setDynamicItem] = useState({ visible: false })
	const [dynamicAudit, setDynamicAudit] = useState({ visible: false })
	const [total, setTotal] = useState(0)
	// 学生推荐到人才库
	const studentsAddApi = (studentId, pushId) => {
		studentsAddPush({ studentId, pushId }).then(({ result }) => {
			message.info(result)
		})
	}
	// 获取推荐闪光列表
	const getPushListApi = () => {
		getPushList({ pageSize: 100 }).then(({ result }) => {
			let { records } = result
			setOptions(records)
		})
	}
	// 获取人员列表数据
	const getCampListApi = () => {
		let data = JSON.parse(JSON.stringify(params))
		for (let index in data) {
			if (!data[index]) {
				delete data[index]
			}
		}
		delete data.type;
		data.campId = state.id
		console.log(data, '请求参数')
		switch (params.type) {
			case 1:
				// 获取职才部落人员列表
				getCampPersonnelList(data).then(({ result }) => {
					let { records, total } = result
					setList(records)
					setTotal(total)
				})
				break;
			case 2:
				// 获取职才部落动态列表
				if (data.name) {
					data.title = `,${data.name},`
					delete data.name
				}
				data.status = status
				getCampArticlelList(data).then(({ result }) => {
					let { records, total } = result
					setList(records)
					setTotal(total)
				})
				break;
			case 3:
				// 获取职才部落视频列表
				if (data.name) {
					data.title = `,${data.name},`
					delete data.name
				}
				data.order = 'DESC';
				data.column = "updateTime"
				getCampVideoList(data).then(({ result }) => {
					let { records, total } = result
					setList(records)
					setTotal(total)
				})
				break;
			case 4:
				// 获取职才部落企业列表
				getCampEnterpriseList(data).then(({ result }) => {
					let { records, total } = result
					setList(records)
					setTotal(total)
				})
				break;
		}
	}
	let timeout;
	// input 输入选择
	const inputChange = (e) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		const fake = () => {
			setParams({ ...params, ...{ name: e.target.value } })
		};
		timeout = setTimeout(fake, 1000);
	}
	const editTop = ({ id }) => {
		dynamicTop({ id }).then(res => {
			if (res) {
				message.success(res.message)
				getCampListApi()
			}
		})

	}
	const editList = (params = {}) => {
		setDynamicItem({ visible: true, params })
	}

	// 移除企业
	const remove = (id) => {
		if (id === state.specify) deleteSpecify(state.id).then(res => res && getCampListApi())
		else deleteMember({ id }).then(res => { if (res) { getCampListApi() } })
	}
	useEffect(() => {
		getPushListApi()
	}, [])
	useEffect(() => {
		getCampListApi()
	}, [params, status])
	return (
		<div className='tribeDetails'>
			<div className='typeFlex'>
				{
					typeList.map((key, index) => {
						return <div className={`typeItem${params.type === index ? ' typeItemAct' : ''}`} key={index} onClick={() => { setParams({ type: index, pageNo: 1, pageSize: 10 }) }}>{key}</div>
					})
				}
			</div >
			<div className='screening'>
				<div className='ScreenContent'>
					<Input placeholder='搜索' onInput={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
					<Button type='primary' onClick={getCampListApi}>搜索</Button>
					{
						params.type === 2 ? <Select value={status} style={{ width: '100px', textAlign: 'center', marginLeft: '20px' }} onChange={(e) => { setStatus(e) }}>
							<Option value={1}>已通过</Option>
							<Option value={0}>待审核</Option>
							<Option value={2}>已驳回</Option>
						</Select> : null
					}
				</div>
				{
					params.type === 2 ?
						<Dropdown overlay={<Menu items={[
							{
								key: 1,
								label: (<div className='operationBox' onClick={() => { setDynamicItem({ visible: true, params: { type: 1 } }) }}>视频</div>)
							},
							{
								key: 2,
								label: (<div className='operationBox' onClick={() => { setDynamicItem({ visible: true, params: { type: 2 } }) }}>图文</div>)
							}
						]} />} placement="bottom" arrow>
							<Button type="primary"><span className='iconfont'>&#xe684;</span> 新建</Button>
						</Dropdown> : params.type === 3 && userInfo?.nature === "sys"
							?
							<Button type='primary' onClick={() => { editList() }}>新建</Button> : null
				}
			</div>
			{
				params.type === 1 ?
					<Table
						columns={[...student, ...[{
							title: '操作',
							align: 'center',
							width: 150,
							render: (key) => <div className='operation' onClick={(e) => { e.preventDefault() }}>
								<Dropdown overlay={<Menu items={
									options.map((item, index) => {
										return {
											key: index + 1,
											label: (<div className='operationBox' onClick={e => { e.stopPropagation(); studentsAddApi(key.id, item.id) }}>{item.name}</div>)
										}
									})
								} />} placement="bottom" arrow>
									<div className="shopping iconfont">&#xe69c;</div>
								</Dropdown>
								<div className='vertical'></div>
								<Dropdown overlay={<Menu items={[
									{
										key: 1,
										label: (<div className="reject operationBox"
											onClick={e => {
												e.stopPropagation();
												deleteMember({ id: key.id }).then(res => { if (res) { getCampListApi() } })
											}}>移出部落</div>
										)
									},
									{
										key: 2,
										label: (<div className='operationBox'
											onClick={(e) => {
												e.stopPropagation();
												if (parseInt(key.label) === 1 && userInfo?.nature === "school") return false;
												editCamplabel({ ...key, ...{ label: parseInt(key.label) ? 0 : 1 } }).then(res => { if (res) { getCampListApi() } })
											}}
										>{parseInt(key.label) ? '取消实践优等生' : '给予实践优等生'}</div>),
										disabled: parseInt(key.label) === 1 && userInfo?.nature === "school"
									}
								]} />} placement="bottom" arrow>
									<div className="edit iconfont">&#xe8ac;</div>
								</Dropdown>
							</div>
						}]]}
						rowKey="id"
						dataSource={list}
						onRow={r => { return { onClick: e => { console.log(e, "EVENT") } } }}
						scroll={{ y: 'calc(100vh - 100px)' }}
						pagination={{
							total: total,
							current: params.pageNo,
							pageSize: params.pageSize,
							onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
						}}
					/> : params.type === 2 ?
						<Table
							columns={[...dynamic, ...[{
								title: '操作',
								align: 'center',
								width: 198,
								render: (key) =>
									<div className='operation' onClick={(e) => { e.stopPropagation() }}>
										{
											key.status === 1 ?
												<>
													<div className="edit" onClick={e => { editTop(key) }}>{key.sort ? '已置顶' : '置顶'}</div>
													<div className='vertical'></div>
													<div className="edit" onClick={e => { editList(key) }}>编辑</div>
													<div className='vertical'></div>
													<div className="reject" onClick={e => { deleteDynamic({ id: key.id }).then(res => { if (res) { getCampListApi() } }) }}>删除</div>
												</>
												:
												key.status === 0 && userInfo?.nature === "sys"
													?
													<>
														<Button onClick={() => {
															campDynamicEdit({ ...key, ...{ status: 1 } }).then(res => {
																if (res) {
																	getCampListApi()
																}
															})
														}}>审核</Button>
														<Button onClick={() => { setDynamicAudit({ visible: true, params: key }) }}>驳回</Button>
													</>
													:
													key.status === 2
														?
														<>已驳回</>
														:
														null

										}
									</div>
							}]]}
							rowKey="id"
							dataSource={list}
							onRow={r => {
								return {
									onClick: e => {
										navigate('dynamicDetails', {
											state: { id: r.id, type: params.type }
										})
									}
								}
							}}
							scroll={{ y: 'calc(100vh - 100px)' }}
							pagination={{
								total: total,
								current: params.pageNo,
								pageSize: params.pageSize,
								onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
							}}
						/> :
						params.type === 3 ?
							<Table
								columns={userInfo.nature === 'sys' ? [...video, ...[{
									title: '操作',
									align: 'center',
									width: 150,
									render: (key) => <div className='operation' onClick={(e) => { e.preventDefault() }}>
										<div className="edit" onClick={e => { e.stopPropagation(); editList(key) }}>编辑</div>
										<div className='vertical'></div>
										<div className="reject" onClick={e => { e.stopPropagation(); deleteVideo({ id: key.id }).then(res => { if (res) { getCampListApi() } }) }}>删除</div>
									</div>
								}]] : video}
								rowKey="id"
								dataSource={list}
								onRow={r => {
									return {
										onClick: e => {
											navigate('dynamicDetails', {
												state: { id: r.id, type: params.type }
											})
										}
									}
								}}
								scroll={{ y: 'calc(100vh - 100px)' }}
								pagination={{
									total: total,
									current: params.pageNo,
									pageSize: params.pageSize,
									onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
								}}
							/> :
							params.type === 4 ? <Table
								columns={userInfo?.nature === 'sys' ? [...enterprise, ...[{
									title: '操作',
									align: 'center',
									width: 150,
									render: (key) => <div className='operation' onClick={(e) => { e.preventDefault() }}>
										<div className="reject" onClick={e => { e.stopPropagation(); remove(key.id) }}>移除</div>
									</div>
								}]] : enterprise}
								rowKey="id"
								dataSource={list}
								scroll={{ y: 'calc(100vh - 100px)' }}
								pagination={{
									total: total,
									current: params.pageNo,
									pageSize: params.pageSize,
									onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
								}}
							/> : null
			}
			<DynamicEditor
				type={params.type}
				params={dynamicItem.params}
				visible={dynamicItem.visible}
				campId={state.id}
				update={() => { getCampListApi() }}
				cancel={() => { setDynamicItem({ visible: false }) }}
			/>
			<DynamicAudit
				params={dynamicAudit.params}
				visible={dynamicAudit.visible}
				update={() => { getCampListApi() }}
				cancel={() => { setDynamicAudit({ visible: false }) }}
			/>
		</div >
	)
}
