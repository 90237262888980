import React, { useState, useEffect } from 'react'
import { filterJoin } from "../../utils/specification"
import { Button, DatePicker, Select } from "antd"
import moment from "moment"
import { getFinanceInfo } from "../../api/financial"
import { getStudentsList } from "../../api/students"
import "./index.scss"
export default function Statistical() {
    const { RangePicker } = DatePicker;
    const { Option } = Select
    const [params, setParams] = useState({ endTime: '', startTime: '', studentId: 0 })
    const [details, setDetails] = useState({})
    const [student, setStudent] = useState([])
    const credit = [
        { title: '充值', index: 'creditRecharge' },
        { title: '提现', index: 'creditWithdraw' },
        { title: '扣罚', index: 'creditPenalty' }
    ]
    const redPacket = [
        { title: '余额收益', index: 'balance', type: 'todayCountBg' },
        { title: '余额提现', index: 'balanceWithdraw', type: 'todayCountBg' },
        { title: '红包收益', index: 'redEnvelope', type: 'monthCountBg' },
        { title: '红包提现', index: 'redWithdraw', type: 'monthCountBg' }
    ]
    const hunter = [
        { title: '猎才金', index: 'hunter' },
        { title: '提现', index: 'hunterWithdraw' },
    ]
    let timeout;
    // input 输入选择
    const inputChange = (name) => {
        if (!name) {
            return false
        }
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            getStudentsList({ name:`,${name},` }).then(({ result }) => {
                setStudent(result.records)
            })
        };
        timeout = setTimeout(fake, 300);
    }
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    const selectTime = (e) => {
        if (e) {
            console.log(e, '线下是的')
            let arr = []
            e.forEach(key => {
                arr.push(moment(key).format('YYYY-MM-DD'))
                console.log(moment(key).format('YYYY-MM-DD'), '选择时间')
            });
            setParams({ ...params, ...{ startTime: arr[0], endTime: arr[1] } })
        } else {
            setParams({ ...params, ...{ startTime: '', endTime: '' } })
        }
    }
    const selectStudent = (studentId = '') => {
        setParams({ ...params, ...{ studentId } })
    }
    useEffect(() => {
        let data = JSON.parse(JSON.stringify(params))
        for (let index in data) {
            if (data[index] === 0) {
                delete data[index]
            }
        }
        getFinanceInfo(data).then(({ result }) => {
            setDetails(result)
        })
    }, [params])
    return (
        <div>
            <div className='ScreenContent'>
                <Select
                    showSearch
                    allowClear
                    onClear={() => { setStudent([]) }}
                    className='ScreenContent'
                    optionFilterProp="children"
                    placeholder="请输入学生名字"
                    onChange={(e) => { selectStudent(e) }}
                    onSearch={inputChange}
                    prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }}
                >
                    {
                        student.map(key => {
                            return <Option value={key.id} key={key.id}>{key.name}</Option>
                        })
                    }
                </Select>
                <RangePicker disabledDate={disabledDate} onChange={selectTime} />
                <Button type='primary'>查询</Button>
            </div>
            <div className='financialPlatform '>
                <div className='student'>
                    <div className='title'>学生</div>
                    <div className='credit'>
                        {
                            credit.map((key, index) => {
                                return (
                                    <div className='creditItem' key={index}>
                                        <div className='creditTitle'>信用金累计{key.title}（元）</div>
                                        <div className='creditMoney'>{parseFloat(details[key.index]).toFixed(2)}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='redPacket'>
                        {
                            redPacket.map((key, index) => {
                                return (
                                    <div className={filterJoin(['redPacketItem', key.type])} key={index}>
                                        <div className='redPacketTitle'>{key.title}累计（元）</div>
                                        <div className='redPacketMoney'>{parseFloat(details[key.index]).toFixed(2)}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='hunter'>
                    <div className='title'>预备委员</div>
                    {
                        hunter.map((key, index) => {
                            return (
                                <div className='hunterItem' key={index}>
                                    <div className='hunterTitle'>累计{key.title}（元）</div>
                                    <div className='hunterMoney'>{parseFloat(details[key.index]).toFixed(2)}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div >
    )
}
