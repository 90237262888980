const TokenKey = 'Admin-Token'
const UserInfo = 'Admin-UserInfo'
const Permission = 'Admin-Permission'

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function getUserInfo() {
  return sessionStorage.getItem(UserInfo)
}

export function setUserInfo(Info) {
  return sessionStorage.setItem(UserInfo, Info)
}

export function setPermission(Info) {
  return sessionStorage.setItem(Permission, Info)
}

export function getPermission() {
  return sessionStorage.getItem(Permission)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

export function removeUserInfo() {
  return sessionStorage.removeItem(UserInfo)
}
export function removePermission() {
  return sessionStorage.removeItem(Permission)
}
