import React, { useState, useEffect } from 'react'
import { getFinancialAudit, auditFinancial } from "../../api/financial"
import { Table } from 'antd'
import { audit as columns } from "./table"
import "./index.scss"
export default function Details() {
    const [params, setParams] = useState({ type: 1, pageNo: 1, pageSize: 10 })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const getFinanceApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        getFinancialAudit(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    useEffect(() => {
        getFinanceApi()
    }, [params])
    return (
        <Table
            rowKey="id"
            className='FinancialAudit'
            columns={[...columns, ...[{
                title: '操作',
                align: 'center',
                width: 200,
                render: (key) =>
                    <div className='operation'>
                        <div className='edit' onClick={(e) => { auditFinancial({ id: key.id, status: 1 }).then(res => { if (res) { getFinanceApi() } }) }}>通过</div>
                        <div className='vertical' />
                        <div className='reject' onClick={(e) => { auditFinancial({ id: key.id, status: 2 }).then(res => { if (res) { getFinanceApi() } }) }}>拒绝</div>
                    </div>
            }]]}
            dataSource={list}
            pagination={{
                total: total,
                current: params.pageNo,
                pageSize: params.pageSize,
                onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
            }}
            scroll={{ y: 'calc(100vh - 100px)' }}
        />
    )
}
