import React, { useEffect, useState } from 'react'
import Edit from "./components/Edit"
import { getSchoolList, addSchoolInfo, editSchoolInfo, deleteSchoolInfo } from "../../api/school"
import { useNavigate } from 'react-router-dom'
import { columns } from "./table"
import "./index.scss"
import { Table,Button,Input } from 'antd'
export default function School() {
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [editShow, setEditShow] = useState(false)
    const [selectData, setSelectData] = useState({})
    const navigate = useNavigate()
    const getSchoolListApi = () => {
        if(!params.name){
            delete params.name
        }
        getSchoolList(params).then(({ result }) => {
            setTotal(result.total)
            setList(result.records)
            setEditShow(false)
        })
    }
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setParams({ ...params, ['name']: e.target.value })

        };
        timeout = setTimeout(fake, 1000);
    }
    const UndateTwoBrokerage = (e) => {
        if (e.id) {
            editSchoolInfo(e).then(res => {
                if (res) getSchoolListApi()
            })
        } else {
            addSchoolInfo(e).then(res => {
                if (res) getSchoolListApi()
            })
        }
    }
    const buttonFunction = (data, type) => {
        if (type) {
            setSelectData(data)
            setEditShow(true)
        }
    }
    useEffect(() => {
        getSchoolListApi()
    }, [params])
    return (
        <div className='schoolPage'>
            {/* <Screening onEdit={() => {  }} /> */}
            <div className='screening'>
                <Input placeholder='请输入学校名称' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
                <Button type="primary" onClick={() => { setEditShow(true); setSelectData({}) }}><span className='iconfont'>&#xe684;</span> 新增学校</Button>
            </div >
            <Table
                columns={[...columns, ...[{
                    title: '操作',
                    align: 'center',
                    width: 148,
                    render: (t, r) =>
                        <div className="agree iconfont" onClick={(e) => { e.stopPropagation(); buttonFunction(r, 1) }}>
                            &#xe8ac;
                        </div>
                }]]}
                dataSource={list}
                scroll={{ y: 'calc(100vh - 100px)' }}
                onRow={r => { return { onClick: e => { navigate('details', { state: r.id }) } } }}
                pagination={{
                    total: total,
                    current: params.pageNo,
                    pageSize: params.pageSize,
                    onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
                }}
            ></Table>
            <Edit visible={editShow} selectData={selectData} UndateVisible={() => { setEditShow(false) }} UndateTwoBrokerage={UndateTwoBrokerage} />
        </div>
    )
}
