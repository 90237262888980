import { Image } from "antd"
import { filterJoin, positionType } from "../../utils/specification"
export const columnsAudit = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        render: (t, key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key?.graduate + '届'}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '标签',
        align: 'center',
        render: (key) =>
            <div className='label'>
                {key.isFlash === 1 ? <div className='labelItem item1'>闪光应届生</div> : null}
                {key.hunter === 1 ? <div className='labelItem item2'>预备委员</div> : null}
                {key.isGeneral === 1 ? <div className='labelItem item2'>实践优等生</div> : null}
                {key.isTeam === 1 ? <div className='labelItem item3'>省协会委员</div> : null}
            </div>
    },
    {
        title: '审核内容',
        render: (key) => <div className='advantage'>{key.updateTime}   <span>申请理由：</span> {key.advantage || '无'}</div>
    },
    {
        title: '证明材料',
        dataIndex: 'url',
        align: 'center',
        render: (t) => <Image width={100} height={60} src={t} />
    }
]
export const columnsList = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        width: 400,
        render: (t, key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key?.graduate + '届'}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '标签',
        align: 'center',
        render: (key) =>
            <div className='label'>
                {key.isFlash === 1 ? <div className='labelItem item1'>闪光应届生</div> : ''}
                <div className='labelItem item2'>预备委员</div>
                {key.isGeneral === 1 ? <div className='labelItem item2'>实践优等生</div> : ''}
                {key.isTeam === 1 ? <div className='labelItem item3'>省协会委员</div> : ''}
            </div>
    },
    {
        title: '上级预备委员',
        dataIndex: 'superiorName',
        align: 'center',
        render: (t) => <>{t || '无'}</>
    },
    {
        title: '团队人数',
        align: 'center',
        dataIndex: 'team',
        render: (t) => <>{t}</>
    },
    {
        title: '推荐就职次数',
        align: 'center',
        dataIndex: 'recommend',
        render: (t) => <>{t || 0}</>
    },
    {
        title: '佣金',
        align: 'center',
        dataIndex: 'commission',
        render: (t) => <>{t || 0}</>
    }
]
export const earnings = [
    {
        align: 'center',
        width: 88,
        render: (t, r, i) => <>{i + 1}</>
    },
    {
        render: (key) =>
            <div className="rows">
                {key.createTime ? key.createTime.split(' ')[0] : ''}
                <span>{key.name}</span>
                参与 {key.positionName} ·
                <span>{positionType[key.positionType]}</span>
                获得猎才金 {key.income}元
            </div>
    }
]