import request from '../utils/request'
import qs from "qs"
// 获取学校列表 (学校管理表-分页列表查询)
export function getSchoolList(data) {
    data = qs.stringify(data)
    return request({
        url: `sys/recSchoolController/list${data ? '?' + data : data}`,
        method: 'get'
    })
}
// 添加学校信息 (学校管理表-添加)
export function addSchoolInfo(data) {
    return request({
        url: 'sys/recSchoolController/add',
        method: "POST",
        data
    })
}
// 编辑学校信息 (学校管理表-编辑)
export function editSchoolInfo(data) {
    return request({
        url: 'sys/recSchoolController/edit',
        method: "PUT",
        data
    })
}
// 获取学校详情 (查看学校详情)
export function getSchoolInfo(data) {
    return request({
        url: `sys/recSchoolController/query/${data}`,
        method: "GET"
    })
}
// 获取月度统计 (月度统计)
export function getMonthlyStatistics(data) {
    return request({
        url: `sys/recSchoolController/monthly/${data}`,
        method: "GET"
    })
}
// 获取学校待办数据 (获取待办列表)
export function getAgencyList(data) {
    return request({
        url: `sys/recSchoolController/agency/${data}`,
        method: "GET"
    })
}