import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation, Outlet } from "react-router-dom"
import { booksTable } from "./table"
import { getHandBookList, setPass, setReject, setRecommend } from "../../api/commendBook"
import { Input, Table, Modal, message } from "antd"
import "./index.scss"
import { getUserInfo } from '../../utils/auth'
export default function Books() {
    const navigate = useNavigate()
    const userInfo = JSON.parse(getUserInfo()) || {}
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10, title: '' })
    const { pathname } = useLocation()
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const rejectInput = useRef()
    // 定义请求手帐本api
    const getHandBookApi = () => {
        for (let index in params) {
            if (!params[index]) {
                delete params[index]
            }
        }
        getHandBookList(params).then(({ result }) => {
            let { records, total } = result
            setList(records)
            setTotal(total)
        })
    }
    // 定义手帐本通过
    const confirm = (id) => {
        Modal.confirm({
            title: '提示',
            content: '是否确认通过',
            onOk: () => {
                setPass({ id }).then(res => {
                    if (res) {
                        getHandBookApi()
                    }
                })
            }
        })
    }
    // 定义手帐本拒绝
    const reject = (id) => {
        Modal.confirm({
            title: '提示',
            content: (<Input className='rejectInput' ref={rejectInput} placeholder='请输入拒绝备注' />),
            okText: '拒绝',
            onOk: () => {
                let remark = rejectInput.current.input.value
                if (!remark) {
                    message.error('请输入拒绝备注')
                } else {
                    setReject({ id, remark }).then(res => {
                        if (res) {
                            getHandBookApi()
                        }
                    })
                }
            }
        })
    }
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setParams({ ...params, ['title']: e.target.value, ['pageNo']: 1 })

        };
        timeout = setTimeout(fake, 1000);
    }
    useEffect(() => {
        getHandBookApi()
    }, [params])
    return (
        <>
            {
                pathname === '/commendBook/books' ? <div className='books'>
                    <div className='screening'>
                        <Input placeholder='搜索手帐本' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
                    </div>
                    <Table
                        columns={[...booksTable, ...[{
                            title: '操作',
                            align: 'center',
                            dataIndex: 'state',
                            width: 158,
                            render: (t, r) => <div className='operation'>
                                {
                                    t === 0 ? <>
                                        <div className='edit' onClick={(e) => { e.stopPropagation(); confirm(r.id) }}>通过</div>
                                        <div className='vertical' />
                                        <div className='reject' onClick={(e) => { e.stopPropagation(); reject(r.id) }}>拒绝</div>
                                    </> : t === 1 && r?.status === 1 && userInfo?.nature === 'sys' ? (
                                        r.isRecommend === 1 ?
                                            <div className='reject' onClick={(e) => { e.stopPropagation(); setRecommend({ ...r, ...{ isRecommend: 0 } }).then(res => { if (res) { getHandBookApi() } }) }}>取消推荐</div>
                                            :
                                            <div className='edit' onClick={(e) => { e.stopPropagation(); setRecommend({ ...r, ...{ isRecommend: 1 } }).then(res => { if (res) { getHandBookApi() } }) }}>推荐</div>)
                                        : t === 2 ? <div>已拒绝</div> : <>{r?.status===3?'未公开':''}</>
                                }
                            </div>
                        }]]}
                        dataSource={list}
                        onRow={r => {
                            return {
                                onClick: e => {
                                    navigate('details', {
                                        state: r
                                    })
                                }
                            }
                        }}
                        pagination={{
                            total: total,
                            current: params.pageNo,
                            pageSize: params.pageSize,
                            onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
                        }}
                        rowKey="id"
                        scroll={{ y: 'calc(100vh - 100px)' }}
                    />
                </div> : <Outlet />
            }
        </>

    )
}
