import { filterJoin } from "../../utils/specification"
export const columns = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '标签',
        align: 'center',
        render: (t, key) =>
            <div className='label'>
                {key.isFlash === 1 ? <div className='labelItem item1'>闪光应届生</div> : ''}
                {key.hunter === 1 ? <div className='labelItem item2'>预备委员</div> : ''}
                {key.isGeneral === 1 ? <div className='labelItem item2'>实践优等生</div> : ''}
                {key.isTeam === 1 ? <div className='labelItem item3'>省协会委员</div> : ''}
            </div>
    },
    {
        title: '联系方式',
        dataIndex: 'phone',
        align: 'center'
    },
    {
        title: '信用金',
        dataIndex: 'credit',
        align: 'center',
        render: (t) => <>{t || 0}</>
    },
    {
        title: '入驻时间',
        dataIndex: 'createTime',
        align: 'center',
        render: (t) => <>{t.split(' ')[0]}</>
    }
]
export const columnsAudit = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        render: (t, key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.graduate ? `${key.graduate}届` : null}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '标签',
        align: 'center',
        render: (key) =>
            <div className='label'>
                <div className='labelItem item1'>闪光应届生</div>
                {key.hunter === 1 ? <div className='labelItem item2'>预备委员</div> : null}
                {key.isGeneral === 1 ? <div className='labelItem item2'>实践优等生</div> : null}
                {key.isTeam === 1 ? <div className='labelItem item3'>省协会委员</div> : null}
            </div>
    },
    {
        title: '审核内容',
        render: (key) => <div className='advantage'>{key.updateTime}   <span>申请理由：</span> {key.remark || '无'}</div>
    }
]
const statusList = { 1: '公开', 2: '半公开', 3: '不公开' }
const bookType = {
    1: '职才部落经历',
    2: '工作实习经历',
    3: '社团和组织经历',
    4: '项目经历',
    5: '竞赛/获奖经历',
    6: '作品集（含技能强项）',
    7: '研究经历',
    8: '其他'
}
export const commendBook = [
    {
        render: (t, r, i) => <>{i + 1}</>,
        width: 68,
        align: 'center'
    },
    {
        dataIndex: "content"
    },
    {
        render: (key) => <>{filterJoin([bookType[key.type], statusList[key.status]], ' · ')}</>,
        align: "center"
    },
    {
        dataIndex: 'createTime',
        render: (t) => <>{t.split(" ")[0]}</>,
        align: "center"
    },
    {
        dataIndex: 'remark',
        render: (t, r) => <div className="complete">{r.state === 1 ? t : null}</div>,
        width: 88,
        align: 'center'
    }
]
export const tribe = [
    {
        dataIndex: 'name'
    },
    {
        dataIndex: 'createTime',
        render: (t) => <>{t.split(" ")[0]}</>,
        align: "center"
    },
    {
        dataIndex: 'label',
        width: 88,
        align: 'center',
        render: (t) => <div className="tribeBtn">{t}</div>,
    }
]
const interviewState = {
    sign_up: "待审核",
    forward: '企业待审核',
    invite: '待面试邀请',
    sending: "待接受面试",
    accept: '待面试',
    completed: '面试完成',
    refuse: '拒绝面试',
    invitation: '入职邀请'
}
export const position = [
    {
        render: (t, r, i) => <>{i + 1}</>,
        width: 68,
        align: 'center'
    },
    {
        dataIndex: "name"
    },
    {
        dataIndex: 'createTime',
        render: (t) => <>{t.split(" ")[0]}</>,
        align: "center"
    },
    {
        dataIndex: 'status',
        render: (t) => <div className={t === 'end' ? 'complete' : 'notComplete'}>{interviewState[t]}</div>,
        width: 88,
        align: 'center'
    }
]
export const wallet = [
    {
        render: (key) => <>{key.remark}</>
    },
    {
        dataIndex: 'money',
        align: "center",
        width: 200
    },
    {
        dataIndex: 'createTime',
        align: "center",
        width: 200
    }
]


export const hrColumns = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {key.employerName}
                    </div>
                </div>
            </div>
    },
    {
        title: '联系方式',
        dataIndex: 'phone',
        align: 'center'
    },
    {
        title: '工作年限',
        dataIndex: 'workingYears',
        align: 'center',
        render: (t) => <>{t || 0}年</>,
    },
]

export const hrDetailsColumns = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '简历用途',
        align: 'center',
        dataIndex: 'jobObjective',
    },
    {
        title: '收藏时间',
        align: 'center',
        dataIndex: 'collectTime',
    },
]