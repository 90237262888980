import React from "react";
import { Form, Input, Modal, Button } from "antd"
import "./Edit.scss";
export default function Edit(props) {
    const { selectData } = props
    const handleCancel = () => {
        props.UndateVisible()
    };
    const onFinish = (values) => {
        console.log(values)
        if (selectData.id) {
            values.id = selectData.id
        }
        props.UndateTwoBrokerage(values)
    };
    return (
        <Modal
            visible={props.visible}
            title={selectData.id ? '编辑学校' : '新增学校'}
            footer={null}
            onCancel={handleCancel}
            destroyOnClose
        >
            <Form name="basic"
                initialValues={{
                    remember: true,
                    name: selectData.name,
                    contact: selectData.contact,
                    phone: selectData.phone,
                    password: selectData.password,
                }}
                onFinish={(e) => { onFinish(e) }}
                autoComplete="off">
                <div className="title">学校信息：</div>
                <Form.Item
                    name="name"
                    label="学校名称"
                    rules={[
                        {
                            required: selectData.id ? false : true,
                            message: '请输入学校名称'
                        }
                    ]}
                >
                    <Input placeholder='请输入学校名称' disabled={selectData.id} />
                </Form.Item>
                <div className="title">责任人信息：</div>
                <Form.Item
                    name="contact"
                    label="责任人"
                    rules={[
                        {
                            required: true,
                            message: '请输入责任人姓名'
                        }
                    ]}
                >
                    <Input placeholder='请输入责任人姓名' />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="联系方式"
                    rules={[
                        {
                            required: true,
                            message: '请输入责任人联系方式'
                        }
                    ]}
                >
                    <Input placeholder='请输入责任人联系方式' maxLength={20} />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="登录密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入登录密码'
                        }
                    ]}
                >
                    <Input.Password placeholder='请输入登录密码' maxLength={20} />
                </Form.Item>
                <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}