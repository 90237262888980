import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Table, message } from "antd"
import { getGuideList, deleteGuideList } from "../../../api/recObtainGuide"
import { GuideTable } from "../constans"
import "../guide.scss"
export default function Guide() {
	const navigate = useNavigate()
	const [total, setTotal] = useState(0)
	const [search, setSearch] = useState("")
	const typeClassigy = [
		{ title: '全部', index: '' },
		{ title: '已编辑', index: 1 },
		{ title: '未编辑', index: 0 }
	]
	const [list, setList] = useState([])
	const [params, setParams] = useState({ type: '', pageNo: 1, pageSize: 20 })

	// 获取专业导向列表
	const getGuideApi = () => {
		let data = JSON.parse(JSON.stringify(params))
		for (let index in data) {
			if (data[index] === '') {
				delete data[index]
			}
		}
		getGuideList(data).then(({ result }) => {
			let { records, total } = result
			setList(records)
			setTotal(total)
		})
	}

	let timeout;
	// input 输入选择
	const inputChange = (e) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		const fake = () => {
			setParams({ ...params, ...{ name: e.target.value } })
		};
		timeout = setTimeout(fake, 1000);
	}
	useEffect(() => {
		getGuideApi()
	}, [params])

	return (
		<div className='financialScreen'>
			<div className='typeWrap'>
				<div className='flexAlignCenter flex1'>
					{
						typeClassigy.map((key, index) => {
							return <div key={index} className={`typeItem ${params.type === key.index ? 'typeItemAct' : ''}`} onClick={() => { setParams({ ...params, ...{ type: key.index } }) }}>{key.title}</div>
						})
					}
				</div>
				<div className='ScreenContent'>
					<Input placeholder='请输入专业名称' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
					<Button onClick={getGuideApi} type='primary'>搜索</Button>
				</div>
			</div>
			<Table
				style={{ marginTop: '20px' }}
				columns={GuideTable}
				dataSource={list}
				onRow={key => { return { onClick: e => { navigate('details', { state: key.name }) } } }}
				rowKey="id"
				scroll={{ y: 'calc(100vh - 100px)' }}
				pagination={{
					total: total,
					current: params.pageNo,
					pageSize: params.pageSize,
					onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
				}}
			/>
		</div>
	)
}
