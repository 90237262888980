import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { editCompanyInfo } from "../../api/enterprise"
import { Form, Upload, Button, Select, Input, message, Image } from 'antd';
import { objTurnArr, scale, industry, financing, property } from "../../utils/specification"
import "./index.scss"
const { Option } = Select;
export default function Editor() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [params] = useState(state || {})
  const [fileList, setFileList] = useState({ photo: [], video: '', license: '', logo: '', hood: '' })
  const stateType = {
    financing: objTurnArr(financing), // 融资阶段
    scale: objTurnArr(scale), // 企业规模
    industry,
    property: objTurnArr(property)
  }
  // 提交/修改数据
  const onFinish = (e) => {
    console.log(e)
    if (!fileList.license) {
      message.error('请上传营业执照')
      return false
    }
    const fileArr = JSON.parse(JSON.stringify(fileList))
    for (let index in fileArr) {
      if (fileArr[index]) {
        e[index] = Array.isArray(fileArr[index]) ? fileArr[index].join(',') : fileArr[index]
      }
    }
    e.recIndustry = e.recIndustry.join(',')
    editCompanyInfo({ ...state, ...e }).then(({ result }) => {
      message.success(result, 2, navigate(-1));
    })
  }
  const fileChange = ({ fileList: newFileList }, type) => {
    if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
      if (type === 'photo') {
        fileList[type].push(newFileList[newFileList.length - 1].response.result.url)
      } else {
        fileList[type] = newFileList[newFileList.length - 1].response.result.url
      }
      console.log(fileList, 'newFileList[newFileList.length - 1].response.result.urlnewFileList[newFileList.length - 1].response.result.urlnewFileList[newFileList.length - 1].response.result.url')
      setFileList({ ...fileList })
    }
  };
  useEffect(() => {
    if (state?.license) {
      fileList.license = state.license
    }
    if (state?.logo) {
      fileList.logo = state.logo
    }
    if (state?.photo) {
      fileList.photo = state.photo.split(',')
    }
    if (state?.video) {
      fileList.video = state.video
    }
    if (state?.hood) {
      fileList.hood = state.hood
    }
    setFileList({ ...fileList })
  }, [state])
  return (
    <Form
      name="basic"
      style={{ width: '560px' }}
      initialValues={{
        remember: true,
        ...params
      }}
      onFinish={onFinish}
      autoComplete="off"
      className='enterpriseEditor'
    >
      <div className='title'>职位基本信息</div>
      {/* 企业名称 */}
      <Form.Item
        name="nameFull"
        label="企业名称"
        rules={[{ required: true, message: '请输入企业全称，与营业执照相符' }]}
      >
        <Input placeholder="请输入企业全称，与营业执照相符" />
      </Form.Item>
      {/* 企业简称 */}
      <Form.Item
        name="name"
        label="企业简称"
        rules={[{ required: true, message: '请输入企业简称' }]}
      >
        <Input placeholder="请输入企业简称" />
      </Form.Item>
      {/* 企业地址 */}
      <Form.Item
        name="address"
        label="企业地址"
        rules={[{ required: true, message: '请输入地址，与营业执照相符' }]}
      >
        <Input placeholder="请输入地址，与营业执照相符" />
      </Form.Item>
      {/* 企业详情 */}
      <Form.Item
        name="introduction"
        label="企业详情"
        rules={[{ required: true, message: '请填写企业详情' }]}
      >
        <Input.TextArea placeholder="请填写企业详情" />
      </Form.Item>
      {/* 企业类型 */}
      <Form.Item name='property' label="企业类型" rules={[{ required: true, message: '请选择企业类型' }]}>
        <Select placeholder="请选择企业类型">
          {
            stateType.property.map((key, index) => {
              return <Option key={index} value={index}>{key}</Option>
            })
          }
        </Select>
      </Form.Item>
      {/* 融资阶段 */}
      <Form.Item name='financing' label="融资阶段" rules={[{ required: true, message: '请选择融资阶段' }]}>
        <Select placeholder="请选择融资阶段">
          {
            stateType.financing.map((key, index) => {
              return <Option key={index} value={index}>{key}</Option>
            })
          }
        </Select>
      </Form.Item>
      {/* 企业规模/企业类型 */}
      <div className='flexAlignCenter' style={{ width: '782px' }}>
        <Form.Item name='scale' label="规模" rules={[{ required: true, message: '请选择规模' }]}>
          <Select placeholder="请选择规模">
            {
              stateType.scale.map((key, index) => {
                return <Option key={index} value={index}>{key}</Option>
              })
            }
          </Select>
        </Form.Item>
        <Form.Item name='recIndustry' label="行业" rules={[{ required: true, message: '请选择行业' }]}>
          <Select placeholder="请选择行业" mode="multiple" className='hangye'>
            {
              stateType.industry.map((key, index) => {
                return <Option key={index} value={key} label={key} />
              })
            }
          </Select>
        </Form.Item>
      </div>
      {/* 企业营业执照/企业logo */}
      <div className='flexAlignCenter'>
        <Form.Item label="营业执照" className='required'>
          <Upload
            action={process.env.REACT_APP_BASE_UPLOAD_API}
            onChange={(e) => { fileChange(e, 'license') }}
            accept=".png,.jpg"
          >
            {
              fileList.license ?
                <Image src={fileList.license} /> :
                <Button className='uploadButton'>
                  <div className='iconfont'>&#xe60f;</div>
                  <p>不超过10M</p>
                  <p>支持PNG/JPG格式</p>
                </Button>
            }
          </Upload>
        </Form.Item>
        <Form.Item label="LOGO" style={{ marginLeft: '58px' }}>
          <Upload
            action={process.env.REACT_APP_BASE_UPLOAD_API}
            onChange={(e) => { fileChange(e, 'logo') }}
            accept=".png,.jpg"
          >
            {
              fileList.logo ?
                <Image src={fileList.logo} /> :
                <Button className='uploadButton'>
                  <div className='iconfont'>&#xe60f;</div>
                  <p>不超过10M</p>
                  <p>支持PNG/JPG格式</p>
                </Button>
            }
          </Upload>
        </Form.Item>
      </div>
      <div className='titleLabel'>企业宣传&nbsp;&nbsp;&nbsp;</div>
      <Form.Item label="企业门头照" className='required'>
        <Upload
          action={process.env.REACT_APP_BASE_UPLOAD_API}
          onChange={(e) => { fileChange(e, 'hood') }}
          accept=".png,.jpg"
        >
          {
            fileList.hood ?
              <Image src={fileList.hood} /> :
              <Button className='uploadButton'>
                <div className='iconfont'>&#xe60f;</div>
                <p>不超过10M</p>
                <p>支持PNG/JPG格式</p>
              </Button>
          }
        </Upload>
      </Form.Item>
      <Form.Item label="企业图片" >
        {
          fileList.photo.map((key, index) => {
            return <Image src={key} key={index} />
          })
        }
        {

          fileList.photo.length < 9 ?
            <Upload
              action={process.env.REACT_APP_BASE_UPLOAD_API}
              onChange={(e) => { fileChange(e, 'photo') }}
              accept=".png,.jpg"
            >
              <Button className='uploadButton'>
                <div className='iconfont'>&#xe60f;</div>
                <p>不超过10M</p>
                <p>支持PNG/JPG格式</p>
              </Button>
            </Upload> : null
        }
      </Form.Item>
      <Form.Item label="企业视频" >
        <Upload
          action={process.env.REACT_APP_BASE_UPLOAD_API}
          onChange={(e) => { fileChange(e, 'video') }}
          accept=".mp4"
        >
          {
            fileList.video ?
              <video src={fileList.video} /> :
              <Button className='uploadButton'>
                <div className='iconfont'>&#xe60f;</div>
                <p>仅限MP4</p>
                <p>不超过100M</p>
              </Button>
          }
        </Upload>
      </Form.Item>
      <Button type="primary" htmlType="submit" className='submit'>立即发布</Button>
    </Form >
  )
}
