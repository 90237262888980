export const columns = [
    {
        title: '编号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '学校名称',
        dataIndex: 'name',
        align: 'center'
    },
    {
        title: '学生人数',
        dataIndex: 'studentNum',
        render: (text) => <>{text}人</>,
        align: 'center'
    },
    {
        title: '预备委员',
        dataIndex: 'talentNum',
        render: (text) => <>{text}人</>,
        align: 'center'
    },
    {
        title: '责任人/联系电话',
        render: (t, r) => <>{r.contact} {r.phone}</>,
        align: 'center'
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        align: 'center'
    }
]