// App.jsx
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { getToken, getUserInfo, getPermission } from "../../utils/auth"
import LayoutView from './LayoutView';
export default function Layout() {
    const token = getToken() || '';
    const userInfo = getUserInfo() || '';
    // const permission = getPermission() || [];
    if (userInfo != '') {
        console.log(JSON.parse(userInfo), 'userInfo')
    }
    // if (permission.length) {
    //     console.log(JSON.parse(permission), 'permission')
    // }
    return (
        token ? (
            <Suspense>
                <LayoutView userInfo={userInfo} />
            </Suspense>
        ) : <Navigate to="/login" />
    )
}
