import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useLocation } from "react-router-dom"
import {
	getStudentsQuery,
	getStudentsResumeInfoDetails,
	getStudentsWithdraw,
	getBalance, // 猎才金收益记录
} from "../../api/students"
import { commendBook, tribe, position, wallet } from "./table"
import "./index.scss"
const typeList = [
	{ title: '信用金', rule: 1 },
	{ title: '红包', rule: 1 },
	{ title: '猎才金', rule: 0 }
]
const stateList = [
	{ title: '充值', rule: [0] },
	{ title: '收益', rule: [1, 2] },
	{ title: '提现', rule: [0, 1, 2] },
	{ title: '扣罚', rule: [0] }
]
const visionList = ['视力良好:无近视', '轻度近视:≤300度', '中度近视:300~600度', '高度近视:＞600度'] // 视力选择
function DetailsItem({ textClassName = "", text = "" }) {
	return (
		<div className="detailsItem">
			<div className="ol"></div>
			<div className={textClassName}>{text}</div>
		</div>
	)
}

function Details() {
	const { state } = useLocation()
	const [params, setParams] = useState({})
	const [info, setInfo] = useState({})
	const [request, setRequest] = useState({ type: 0, status: 0, pageNo: 1, pageSize: 10 })
	const [total, setTotal] = useState(0)
	const [walletInfo, setWalletInfo] = useState({})
	const [resumeList, setResumeList] = useState([])
	const [stuWalletDetails, setStuWalletDetails] = useState([])
	// 选择类型
	const selectType = (type) => {
		for (let status = 0; status < stateList.length; status++) {
			if (stateList[status].rule.includes(type)) {
				setRequest({ ...request, ...{ type, status, pageNo: 1 } })
				return false
			}
		}
	}
	useEffect(() => {
		// 获取学生基础信息和职位等信息
		getStudentsQuery(state).then(({ result }) => {
			let { student, stuPrecise } = result
			setParams(result)
			student.precise = stuPrecise
			setInfo(student)
		})
		// 获取学生简历信息
		getStudentsResumeInfoDetails(state).then(({ result }) => {
			setResumeList(result)
		})
	}, [state])
	useEffect(() => {
		let data = JSON.parse(JSON.stringify(request)), typeList = {
			'00': 'credit',
			'02': 'credit_withdraw',
			"03": 'deduct',
			'11': 'envelopes',
			"12": 'envelopes_withdraw',
			"21": 'hunt',
			"22": 'hunt_withdraw'
		}
		getBalance({ pageSize: 5, studentId: state, type: typeList[`${data.type}${data.status}`] }).then(({ result }) => {
			let { records, total } = result
			setStuWalletDetails(records)
			setTotal(total)
		})
	}, [request])
	return (
		<div className="studentsDetails">
			<div className="userCard">
				<div className="userInfo">
					<div className="userAvatar">
						<img src={`${info?.avatar ?? "--"}`} />
					</div>
					<div className="userName">
						{info?.name ?? "--"}
						<img className='gender' src={`/assets/${info.sex === 1 ? 'male' : info.sex === 2 ? 'female' : ''}.png`} alt="" />
					</div>
					<div className="detailsCard">
						<DetailsItem text={`${info?.graduate ?? "--"}届`} />
						<DetailsItem text={`${info?.age ?? "--"}岁`} />
						<DetailsItem text={`${info?.school ?? "--"} · ${info?.specialty ?? "--"}`} />
					</div>
					<div className="detailsCard">
						<DetailsItem text={`身高:${info?.precise?.height ?? '--'}cm`} />
						<DetailsItem text={`体重:${info?.precise?.weight ?? '--'}kg`} />
						<DetailsItem text={`视力:${visionList[info?.precise?.vision] ?? '--'}`} />
					</div>
					<div className="detailsCard">
						<DetailsItem text={`兴趣爱好:${info?.precise?.hobby ?? "--"}`} />
					</div>
					<div className="detailsCard">
						<DetailsItem text={`性格特点:${info?.precise?.traits ?? "--"}`} />
					</div>
					<div className="achievement">
						<div className="myAchievement">我的成就</div>
						{info.isFlash === 1 ? <span className="achievementItem">闪光应届生</span> : null}
						{info.hunter === 1 ? <span className="achievementItem2">预备委员</span> : null}
						{info.isGeneral === 1 ? <span className="achievementItem2">实践优等生</span> : null}
						{info.isTeam === 1 ? <span className="achievementItem">省协会委员</span> : null}
					</div>
					<div className="achievement">
						<div className="myAchievement">联系方式</div>
						<div className="detailsCard">
							<div className="call">
								<span className='phone'>&#xe969;</span>	 {info?.phone ?? "--"}
								{/* <PhoneOutlined style={{ color: "#1764FE", marginRight: "20px" }} /> */}
							</div>
							<div className="call">
								由<span>{info?.superiorName}</span>{info?.createTime?.split(" ")[0]}猎入
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="info">
						<div className="infoCard">
							<div>{info?.wallet?.money ?? 0}</div>
							<div>余额</div>
						</div>
						<div className="div1 divCard"></div>
						<div className="infoCard">
							<div>{info?.wallet?.envelope ?? 0}</div>
							<div>红包</div>
						</div>
						<div className="div2 divCard"></div>
						<div className="infoCard">
							<div>{info?.wallet?.credit ?? 0}</div>
							<div>信用金</div>
						</div>
						<div className="div3 divCard"></div>
						<div className="infoCard">
							<div>{params?.getPart ?? 0}</div>
							<div>兼职次数</div>
						</div>
						<div className="div4 divCard"></div>
						<div className="infoCard">
							<div>{params?.getPractice ?? 0}</div>
							<div>实习次数</div>
						</div>
						<div className="div5 divCard"></div>
						<div className="infoCard">
							<div>{params?.getActivity ?? 0}</div>
							<div>活动次数</div>
						</div>
						<div className="div6 divCard"></div>
						<div className="infoCard">
							<div>{params?.getFull ?? 0}</div>
							<div>全职次数</div>
						</div>
					</div>
					<div className="transactionInfo">
						<div className="tabs">
							<div className="tabsCard">
								{
									typeList.map((item, index) => {
										return <div onClick={() => { selectType(index) }} className={request.type === index ? "tabActive" : "tabItem"} key={index}>{item.title}</div>
									})
								}
							</div>
							<div className="tabsCardR">
								{
									stateList.map((item, index) => {
										return item.rule.includes(request.type) ? <div onClick={() => { setRequest({ ...request, ...{ status: index } }) }} className={request.status === index ? "tabActive" : "tabItem"} key={index}>{item.title}</div> : null
									})
								}
							</div>
						</div>
						<div className="divd"></div>
						<div className="transactionInfoList">
							<Table
								rowKey="id"
								showHeader={false}
								columns={wallet}
								dataSource={stuWalletDetails}
								pagination={{
									total: total,
									pageSize: request.pageSize,
									current: request.pageNo,
									onChange: (pageNo, pageSize) => { setRequest({ ...request, ...{ pageNo, pageSize } }) }
								}}
								scroll={{ y: 245 }}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="cardItem">
				<div className="transactionInfo">
					<div className="tabs">
						<div className="tabsCard">
							<div className="tabsL">
								兼职记录
							</div>
						</div>
						<div className="tabsCardR">
							<div className="tabsR">参加<span>{params?.joinPart ?? "0"}</span>次兼职，完成<span>{params?.getPart ?? "0"}</span>次</div>
						</div>
					</div>
					<div className="divd"></div>
					<div className="transactionInfoList">
						<Table
							rowKey="id"
							showHeader={false}
							columns={position}
							dataSource={params.part}
							pagination={false}
							scroll={{ y: 282 }}
						/>
					</div>
				</div>
				<div className="transactionInfo">
					<div className="tabs">
						<div className="tabsCard">
							<div className="tabsL">
								活动记录
							</div>
						</div>
						<div className="tabsCardR">
							<div className="tabsR">参加<span>{params?.joinActivity ?? "0"}</span>次活动，完成<span>{params?.getActivity ?? "0"}</span>次</div>
						</div>
					</div>
					<div className="divd"></div>
					<div className="transactionInfoList">
						<Table
							rowKey="id"
							showHeader={false}
							columns={position}
							dataSource={params.activity}
							pagination={false}
							scroll={{ y: 282 }}
						/>
					</div>
				</div>
			</div>
			<div className="cardItem">
				<div className="transactionInfo">
					<div className="tabs">
						<div className="tabsCard">
							<div className="tabsL">
								实习记录
							</div>
						</div>
						<div className="tabsCardR">
							<div className="tabsR">参加<span>{params?.joinPractice ?? "0"}</span>次活动，完成<span>{params?.getPractice ?? "0"}</span>次</div>
						</div>
					</div>
					<div className="divd"></div>
					<div className="transactionInfoList">
						<Table
							rowKey="id"
							showHeader={false}
							columns={position}
							dataSource={params.practice}
							pagination={false}
							scroll={{ y: 282 }}
						/>
					</div>
				</div>
				<div className="transactionInfo">
					<div className="tabs">
						<div className="tabsCard">
							<div className="tabsL">
								全职记录
							</div>
						</div>
						<div className="tabsCardR">
							{
								params?.full?.length ?
									<div className="tabsR">参加<span>{params?.joinFull ?? "0"}</span>次全职，完成<span>{params?.getFull ?? "0"}</span>次</div>
									: <div className="tabsR">还未正式就职</div>
							}
						</div>
					</div>
					<div className="divd"></div>
					<div className="transactionInfoList">
						<Table
							rowKey="id"
							showHeader={false}
							columns={position}
							dataSource={params.full}
							pagination={false}
							scroll={{ y: 282 }}
						/>
					</div>
				</div>
			</div>
			<div className="detailsFooter">
				<div className="transactionInfoCard">
					<div className="transactionInfo">
						<div className="tabs">
							<div className="tabsCard">
								<div className="tabsL">
									手帐本
								</div>
							</div>
							<div className="tabsCardR">
								<div className="tabsR">共有<span>{params?.joinBook ?? 0}</span>个手帐本，认证{params?.getBook ?? 0}个</div>
							</div>
						</div>
						<div className="divd"></div>
						<div className="transactionInfoList">
							<Table
								rowKey="id"
								showHeader={false}
								columns={commendBook}
								dataSource={params.books}
								pagination={false}
								scroll={{ y: 282 }}
							/>
						</div>
					</div>
					<div className="transactionInfo">
						<div className="tabs">
							<div className="tabsCard">
								<div className="tabsL">
									职才部落
								</div>
							</div>
							<div className="tabsCardR">
								<div className="tabsR">加入<span>{params?.joinTribe ?? 0}</span>个部落，获得{params?.getTribe}个标签</div>
							</div>
						</div>
						<div className="divd"></div>
						<div className="transactionInfoList">
							<Table
								rowKey="id"
								showHeader={false}
								columns={tribe}
								dataSource={params.tribe}
								pagination={false}
								scroll={{ y: 282 }}
							/>
						</div>
					</div>
				</div>
				<div className="transactionInfo resumeJob">
					<div className="tabs">
						<div className="tabsCard">
							<div className="tabsL">
								职荐书
							</div>
						</div>
						<div className="tabsCardR">
							<div className="tabsR">共有0份简历，认证0份</div>
						</div>
					</div>
					<div className="divd"></div>
					<div className='resumeList'>
						{
							resumeList.map((key, index) => {
								return (
									<div className='resumeItem' key={index}>
										<div className='resumeTitle'>{key.resume}</div>
										<img src={key.url} className="resumeUrl" />
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Details