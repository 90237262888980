import { filterJoin } from "../../../utils/specification"
export const student = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        width: 88,
    },
    {
        title: '基本信息',
        render: (key) => <div className='basisInfo'>
            <img className='avatar' src={key.avatar} alt="" />
            <div>
                <div className='flexAlignBaseline'>
                    <div className='nickname'>{key.studentName}</div>
                    <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                    <div className='the'>{key?.graduate}届</div>
                </div>
                <div className='schoolInfo'>
                    {filterJoin([key.school, key.specialty], ' · ')}
                </div>
            </div>
        </div>
    },
    {
        title: '加入时间',
        dataIndex: 'createTime',
        align: 'center'
    }
]
export const dynamic = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        width: 88,
    },
    {
        title: '标题',
        dataIndex: 'title'
    },
    {
        title: '视频/图片',
        render: (key) =>
            <div className="uploadButton uploadButtonImg">
                {
                    key.type === 1 ? <video src={key.titleUrl} /> : key.type === 2 ? <img src={key.titleUrl.split(',')[0]} /> : null
                }
            </div>
    },
    {
        title: '点赞',
        dataIndex: 'likes',
        align: 'center',
        render: (t) => <>{t || 0}</>
    },
    {
        title: '评论',
        dataIndex: 'comment',
        align: 'center',
        render: (t) => <>{t || 0}</>
    },
    {
        title: '发布时间',
        dataIndex: 'createTime',
        align: 'center'
    }
]
export const video = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        width: 88,
    },
    {
        title: '标题',
        dataIndex: 'title',
        align: 'center'
    },
    {
        title: '介绍',
        dataIndex: 'content',
        align: 'center'
    },
    {
        title: '视频',
        dataIndex: 'video',
        align: 'center',
        render: (t) =>
            <div className="uploadButton uploadButtonImg">
                <video src={t} controls onClick={e => { e.stopPropagation(); }} />
            </div>
    },
    {
        title: '上传时间',
        dataIndex: 'createTime',
        align: 'center'
    }
]
export const enterprise = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        width: 88,
    },
    {
        title: '企业名称',
        dataIndex: 'name',
        align: 'center'
    },
    {
        title: '加入时间',
        dataIndex: 'createTime',
        align: 'center'
    }
]

export const comment = [
    {
        render: (t, r) =>
            <div className="rows">
                <img src={r.avatar} />
                <div style={{ marginLeft: '10px' }}>
                    <div className="name">{r.name}</div>
                    <div className="time">{r.createTime}</div>
                    <div>{r.content}</div>
                </div>
            </div>
    }
]