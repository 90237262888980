import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Select, Upload, Button, message } from "antd"
import { addPreface, editPreface } from "../../../api/recObtainGuide"
import { industry } from "../../../utils/specification"
import "./index.scss"
import { CloseCircleOutlined } from '@ant-design/icons';
export default function Editor(props) {
    const { Option } = Select;
    const typeObj = { 1: '视频', 2: '图片' }
    const [fileList, setFileList] = useState([])
    const fileChange = ({ fileList: newFileList }) => {
        if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
            switch (props.type) {
                case 1:
                    fileList[0] = newFileList[newFileList.length - 1].response.result.url
                    break;
                case 2:
                    fileList.push(newFileList[newFileList.length - 1].response.result.url)
                    break;
            }
            setFileList([...fileList])

        }
    };
    const onFinish = (e) => {
        console.log(props)
        if (!fileList.length) {
            message.error(`请上传${typeObj[props.type]}`)
            return false
        }
        let res = ''
        switch (props.type) {
            case 1:
                e.video = fileList.join(',')
                break;
            case 2:
                e.image = fileList.join(',')
                break;
        }
        if (props?.params?.id) {
            res = editPreface({ ...props.params, ...e })
        } else {
            e.type = props.type
            res = addPreface(e)
        }
        res.then(item => {
            if (item) {
                props.upload()
                props.cancel()
                setFileList([])
            }
        })
    };
    // 删除图片
    const delFileList = (e) => {
        let arr = fileList.filter((key, index) => {
            return index != e
        })
        setFileList([...arr])
    }
    useEffect(() => {
        if (props?.params?.id) {
            switch (props.type) {
                case 1:
                    setFileList([props.params.video])
                    break;
                case 2:
                    setFileList([...props.params.image.split(',')])
                    break;
            }
        }
    }, [props.params])
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            destroyOnClose
            maskClosable
            wrapClassName="prefaceEditorModal"
            className="prefaceEditorModal"
            width={578}
            onCancel={() => { props.cancel() }}
        >
            <Form
                name="add"
                initialValues={{
                    remember: true,
                    ...props.params
                }}
                onFinish={onFinish}
                autoComplete="off"
                className='editor intervalTop'
            >
                {/* 企业名称 */}
                <Form.Item
                    name="title"
                    label="标题"
                    rules={[{ required: true, message: '请输入您的标题' }]}
                >
                    <Input placeholder="请输入您的标题" />
                </Form.Item>
                <Form.Item
                    name="category"
                    label="分类"
                    rules={[{ required: true, message: '请选择类型' }]}
                >
                    <Select placeholder="请选择类型"  >
                        {
                            industry.map((item) => <Option key={item}>
                                {item}
                            </Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="content"
                    label="内容"
                    rules={[{ required: true, message: '请描述内容' }]}
                >
                    <Input.TextArea placeholder="请描述内容" />
                </Form.Item>
                {
                    props.type === 2 ?
                        <Form.Item label="图片" className='requireds'>
                            {
                                fileList.map((key, index) => {
                                    return (
                                        <div className="uploadButton uploadButtonImg" key={index}>
                                            <img src={key} />
                                            <div className='uploadDel' onClick={() => { delFileList(index) }}><CloseCircleOutlined /></div>
                                        </div>
                                    )
                                })
                            }
                            <Upload
                                action={process.env.REACT_APP_BASE_UPLOAD_API}
                                onChange={(e) => { fileChange(e) }}
                                accept=".png,.jpg"
                            >
                                <Button className='uploadButton'>
                                    <div className='iconfont'>&#xe60f;</div>
                                    <p>不超过10M</p>
                                    <p>支持PNG/JPG格式</p>
                                </Button>
                            </Upload>
                        </Form.Item>
                        :
                        <Form.Item label="视频" className='requireds'>
                            <Upload
                                action={process.env.REACT_APP_BASE_UPLOAD_API}
                                onChange={(e) => { fileChange(e) }}
                                accept=".mp4"
                            >
                                {
                                    fileList.length ?
                                        <div className="uploadButton uploadButtonImg">
                                            <video src={fileList[0]} />
                                        </div> :
                                        <Button className='uploadButton'>
                                            <div className='iconfont'>&#xe60f;</div>
                                            <p>支持MP4格式</p>
                                        </Button>
                                }
                            </Upload>
                        </Form.Item>
                }
                <Form.Item
                    name="url"
                    label="公众号"
                >
                    <Input type="url" placeholder="请输入公众号链接" />
                </Form.Item>
                <div className='flexSbCenter' >
                    <Button type="default" onClick={() => {
                        props.cancel(); setFileList([])
                    }} loading={false} className='submit'>取消</Button>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>确定</Button>
                </div>
            </Form>
        </Modal>
    )
}
