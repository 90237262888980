import React, { useEffect, useState, useCallback } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Layout, Menu, Breadcrumb } from 'antd';
import { RightOutlined } from "@ant-design/icons"
import { getUserInfo, removeToken, removeUserInfo } from "../../utils/auth"
import { filterJoin } from "../../utils/specification"
import "./LayoutView.scss"
import "./public.scss"
const { Header, Content, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const admin = [
  getItem('首页', '/', <span className='iconfont'>&#xe610;</span>),
  getItem('职位管理', 'position', <span className='iconfont'>&#xe631;</span>, [
    getItem('职位列表', '/position/list', <span className='iconfont'>&#xe7a5;</span>),
    getItem('职位推送', '/position/push', <span className='iconfont'>&#xe7a5;</span>),
    getItem('简历审核', '/position/resumeAudit', <span className='iconfont'>&#xe7a5;</span>),
  ]),
  getItem('学校管理', 'school', <span className='iconfont'>&#xe635;</span>),
  getItem('企业管理', 'enterprise', <span className='iconfont'>&#xe602;</span>, [
    getItem('企业列表', '/enterprise/list', <span className='iconfont'>&#xe7a5;</span>),
    getItem('企业审核', '/enterprise/audit', <span className='iconfont'>&#xe7a5;</span>),
  ]),
  getItem('用户管理', 'students', <span className='iconfont'>&#xe62f;</span>, [
    getItem('学生列表', '/students/list', <span className='iconfont'>&#xe7a5;</span>),
    // getItem('学生审核', '/students/audit', <span className='iconfont'>&#xe7a5;</span>),
    getItem('HR列表', '/students/hr', <span className='iconfont'>&#xe7a5;</span>)
  ]),
  getItem('人才库管理', 'commendBook', <span className='iconfont'>&#xe601;</span>, [
    getItem('高校人才库', '/commendBook/resume', <span className='iconfont'>&#xe7a5;</span>),
    getItem('经历薄列表', '/commendBook/books', <span className='iconfont'>&#xe7a5;</span>),
    getItem('HR推送列表', '/commendBook/hrPush', <span className='iconfont'>&#xe7a5;</span>)
  ]),
  getItem('储备营管理', 'reserveCamp', <span className='iconfont'>&#xe601;</span>, [
    getItem('导师工作坊', '/reserveCamp/list', <span className='iconfont'>&#xe7a5;</span>),
    getItem('职才提升', '/reserveCamp/promote', <span className='iconfont'>&#xe7a5;</span>),
    getItem('职场前沿', '/reserveCamp/frontier', <span className='iconfont'>&#xe7a5;</span>),
    getItem('专业就业导向', '/reserveCamp/guide', <span className='iconfont'>&#xe7a5;</span>),
  ]),
  getItem('预备委员管理', 'hunting', <span className='iconfont'>&#xe608;</span>, [
    getItem('预备委员列表', '/hunting/list', <span className='iconfont'>&#xe7a5;</span>),
    getItem('审核列表', '/hunting/audit', <span className='iconfont'>&#xe7a5;</span>),
  ]),
  getItem('财务管理', 'financial', <span className='iconfont'>&#xe62a;</span>, [
    getItem('财务统计', '/financial/statistical', <span className='iconfont'>&#xe7a5;</span>),
    getItem('财务详情', '/financial/details', <span className='iconfont'>&#xe7a5;</span>),
    getItem('财务审核', '/financial/audit', <span className='iconfont'>&#xe7a5;</span>),
  ]),
  getItem('广告管理', 'advertising', <span className='iconfont'>&#xe62b;</span>),
];
const school = [
  getItem('首页', '/', <span className='iconfont'>&#xe610;</span>),
  getItem('用户管理', 'students', <span className='iconfont'>&#xe62f;</span>, [
    getItem('学生列表', '/students/list', <span className='iconfont'>&#xe7a5;</span>),
    // getItem('学生审核', '/students/audit', <span className='iconfont'>&#xe7a5;</span>),
    // getItem('HR列表', '/students/hr', <span className='iconfont'>&#xe7a5;</span>)
  ]),
  getItem('人才库管理', 'commendBook', <span className='iconfont'>&#xe601;</span>, [
    getItem('高校人才库', '/commendBook/resume', <span className='iconfont'>&#xe7a5;</span>),
    getItem('经历薄列表', '/commendBook/books', <span className='iconfont'>&#xe7a5;</span>),
    // getItem('HR推送列表', '/commendBook/hrPush', <span className='iconfont'>&#xe7a5;</span>),
  ]),
  getItem('储备营管理', 'reserveCamp', <span className='iconfont'>&#xe601;</span>, [
    getItem('导师工作坊', '/reserveCamp/list', <span className='iconfont'>&#xe7a5;</span>),
    // getItem('职才提升', '/reserveCamp/promote', <span className='iconfont'>&#xe7a5;</span>),
    // getItem('职场前沿', '/reserveCamp/frontier', <span className='iconfont'>&#xe7a5;</span>)
  ]),
  getItem('预备委员管理', 'hunting', <span className='iconfont'>&#xe608;</span>, [
    getItem('预备委员列表', '/hunting/list', <span className='iconfont'>&#xe7a5;</span>),
    getItem('审核列表', '/hunting/audit', <span className='iconfont'>&#xe7a5;</span>),
  ])
]
const finance = [
  getItem('职位列表', '/position/list', <span className='iconfont'>&#xe631;</span>),
]
let pathKey = []
function flattenRoutes(arr) {
  arr.forEach(key => {
    if (Array.isArray(key.children)) {
      pathKey.push(key)
      flattenRoutes(key.children)
    } else {
      pathKey.push(key)
    }
  })
}
const userInfo = getUserInfo() ? JSON.parse(getUserInfo()) : ''
flattenRoutes(userInfo.nature === 'sys' ? admin : userInfo.nature === 'school' ? school : userInfo.nature === 'finance' ? finance : [])
export default function LayoutView() {
  const items = userInfo.nature === 'sys' ? admin : userInfo.nature === 'school' ? school : userInfo.nature === 'finance' ? finance : []
  const navigate = useNavigate()
  const [pathIndex, setPathIndex] = useState()
  const [breadcrumbItem, setBreadcrumbItem] = useState([])
  const calculate = useCallback(() => {
    let urlParams = new URL(window.location.href);
    let pathname = urlParams?.pathname;
    let breadcrumb = pathname.split('/').filter(key => {
      return key !== ''
    })
    let arr = []
    breadcrumb.forEach((key, index) => {
      arr.push(filterJoin([arr[index - 1], key], '/'))
    })
    arr.forEach((key, index) => {
      if (index) {
        arr[index] = `/${key}`
      }
    })
    if (!arr.length) {
      arr = ['/']
    }
    console.log(arr)
    console.log(arr[arr.length >= 2 ? 1 : arr.length - 1])
    setPathIndex(arr[arr.length >= 2 ? 1 : arr.length - 1])
    setBreadcrumbItem(pathKey.filter(item => {
      return arr.includes(item.key)
    }))
  })
  const onExit = () => {
    removeToken()
    removeUserInfo()
    navigate('/login')
  }
  useEffect(() => {
    calculate()
  }, [])
  /** 选择菜单  */
  const selectMenu = ({ keyPath }) => {
    navigate(keyPath[0])
    calculate()
  };
  return (
    <Layout
      className='layout'
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider theme="light" className='Sider'>
        <div className="logo">
          <img src="/assets/logo.png" alt="" />
        </div>
        <Menu theme="light" mode="inline" items={items} selectedKeys={pathIndex} onClick={selectMenu} />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
          <div className='slogan'>让职场，陪伴见证我的大学成长</div>
          <div className='avatar'>
            {
              userInfo.nature === 'sys' ? <img src="/assets/favicon.png" alt="" /> : <>{userInfo.realname.split('')[0]}</>
            }
          </div>
          <div className='name'>{userInfo.realname}</div>
          <div className='vertical'></div>
          <div className='exit iconfont' onClick={onExit}>&#xe636;</div>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb className='Breadcrumb' separator={<RightOutlined />}>
            {
              breadcrumbItem.map((key, index) => {
                return (index === 0 ? <Breadcrumb.Item key={index}> {key.label}</Breadcrumb.Item> : <Breadcrumb.Item key={index}> <NavLink to={key.key}>{key.label}</NavLink> </Breadcrumb.Item>)
              })
            }
          </Breadcrumb>
          <div className="layout-center">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
