import { filterJoin } from "../../../utils/specification"
export const columns = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        width: 88,
    },
    {
        title: '岗位名称',
        dataIndex: 'label',
        align: 'center'
    },
    {
        title: '岗位介绍',
        dataIndex: 'content'
    },
    {
        title: '工作介绍',
        dataIndex: 'introduce'
    },
    {
        title: '从业条件',
        render: (key) => <>{filterJoin([key.conditions, key.background], " · ")}</>
    },
    {
        title: '薪资概况',
        dataIndex: 'generalize'
    },
    {
        title: '技能要求',
        dataIndex: 'skill',
        render: (t) => <div className="labelFlex">{
            t ? t.split(',').map((key, index) => {
                return <div className="labelItem" key={index}>{key}</div>
            }) : null
        }</div>
    },
    {
        title: '其他',
        dataIndex: 'other'
    }
]