import React from 'react'
import { Modal, Form, Input, Button } from "antd"
import {
    campDynamicEdit
} from "../../../api/recObtainGuide"
import "../guide.scss"
import "./index.scss"

export default function Editor(props) {
    const onFinish = (e) => {
        props.params.status = 2
        props.params.remark = e.remark
        campDynamicEdit(props.params).then(res => {
            if (res) {
                props.update()
                props.cancel()
            }
        })
    }
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            width={638}
            destroyOnClose
            maskClosable
            onCancel={() => { props.cancel() }}
        >
            <Form
                initialValues={{
                    remember: true
                }}
                onFinish={onFinish}
                autoComplete="off"
                style={{ paddingTop: '30px' }}
            >
                <Form.Item name="remark" label="驳回原因" rules={[{ required: true, message: '请输入驳回原因' }]}>
                    <Input placeholder="请输入驳回原因" />
                </Form.Item>
                <div className='flexSbCenter' >
                    <Button type="default" onClick={() => { props.cancel(); }} loading={false} className='submit'>取消</Button>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>确定</Button>
                </div>
            </Form>
        </Modal>
    )
}
