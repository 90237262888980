import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Table, Input, message } from "antd"
import { getHrPushList, addResumePushHr } from "../../api/commendBook"
import { HrPushColumns } from "./table"
import { useNavigate } from "react-router-dom";
export default function HrPush() {
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const rejectInput = useRef()
    const navigate = useNavigate()
    function getHrPushListApi() {
        getHrPushList(params).then(({ result }) => {
            let { total, records } = result
            setList(records)
            setTotal(total)
        })
    }
    // 定义删除推送库列表
    const delPushApi = (id) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除当前项',
            okText: '删除',
            cancelButtonProps: true,
            onOk: () => {
                // delPush({ id }).then(res => {
                //     if (res) {
                //         getPushApi()
                //     }
                // })
            }
        })
    }
    // 定义推送人才库
    function createPush() {
        Modal.confirm({
            title: '创建推送库',
            content: (<Input className='rejectInput' ref={rejectInput} placeholder='请输入推送库名称' />),
            onOk: () => {
                let name = rejectInput.current.input.value
                if (!name) {
                    message.error('请输入推送库名称')
                    return false
                }
                addResumePushHr({ name }).then(res => {
                    message.info('创建成功')
                    getHrPushListApi()
                })
            }
        })
    }
    useEffect(() => {
        getHrPushListApi()
    }, [])
    return (
        <div className="HrPushList">
            <div className="screening">
                <Button type="primary" onClick={() => createPush()}><span className='iconfont'>&#xe684;</span> 新建</Button>
            </div>
            <Table
                columns={[...HrPushColumns, ...[
                    // {
                    //     title: '操作',
                    //     width: 100,
                    //     align: 'center',
                    //     dataIndex: 'id',
                    //     render: (t) => <div className='del' onClick={(e) => { e.stopPropagation(); delPushApi(t) }}>&#xe8b6;</div>
                    // }
                ]]}
                rowKey="id"
                onRow={r => { return { onClick: e => { navigate('details', { state: r.id }) } } }}
                dataSource={list}
                pagination={{
                    total: total,
                    current: params.pageNo,
                    pageSize: params.pageSize,
                    onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                }}
                scroll={{ y: 'calc(100vh - 182px)' }}
            />
        </div>
    )
}
