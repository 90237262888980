import React, { useEffect, useState } from 'react'
import { Table } from "antd";
import { getHunterAuditLise } from "../../api/hunting"
import Rejected from './components/Rejected';
import { setHunterSucceed, setHunterRefuse } from "../../api/hunting"
import { getStudentsRemark } from "../../api/students"
import Screening from "./components/Screening"
import { columnsAudit } from "./table"
import { useNavigate } from 'react-router-dom';
import "./hunting.scss"
export default function Home(props) {
	const [list, setList] = useState([])
	const [total, setTotal] = useState(0)
	const [params, setParams] = useState({ pageNo: 1, pageSize: 10, type: 2 })
	const [rejectedShow, setRejectedShow] = useState(false)
	const [selectId, setSelectId] = useState()
	const process = { 1: '审核通过', 2: "审核已被拒绝" }
	const getStudentsRemarkApi = () => {
		let data = JSON.parse(JSON.stringify(params))
		for (let index in data) {
			if (!data[index]) {
				delete data[index]
			}
		}
		getStudentsRemark(params).then(({ result }) => {
			setList(result.records)
			setTotal(result.total)
		})
	}
	const buttonFunction = (data, type) => {
		console.log(data, type)
		if (type) {
			setHunterSucceed(data).then(res => {
				if (res) {
					getStudentsRemarkApi()
				}
			})
		} else {
			setRejectedShow(true)
			setSelectId(data)
		}
	}
	// 更新职位状态
	const UndateTwoBrokerage = () => {
		setHunterRefuse(selectId).then(res => {
			getStudentsRemarkApi()
			setRejectedShow(false)
		})
	}
	useEffect(() => {
		getStudentsRemarkApi()
	}, [params])
	return (
		<div className='huntingPage'>
			<Screening state={(e) => { setParams({ ...params, ...e }) }} />
			<Table
				columns={[...columnsAudit, ...[{
					title: '操作',
					align: 'center',
					width: 148,
					render: (key) => <>
						{
							key.hunter == 0 ?
								<div className='flexAlignCenter'>
									<div className="edit" onClick={() => { buttonFunction({ studentId: key.id }, 1) }}>
										同意
									</div>
									<div className='vertical'></div>
									<div className="reject" onClick={() => { buttonFunction({ studentId: key.id }, 0) }}>
										驳回
									</div>
								</div>
								: process[key.hunter]
						}
					</>
				}]]}
				dataSource={list}
				scroll={{ y: 'calc(100vh - 100px)' }}
				pagination={{
					total: total,
					current: params.pageNo,
					pageSize: params.pageSize,
					onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
				}}

			></Table>
			<Rejected visible={rejectedShow} UndateVisible={() => { setRejectedShow(false); setSelectId({}) }} UndateTwoBrokerage={UndateTwoBrokerage} />
		</div>
	)
}
