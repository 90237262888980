import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import { Image, Table, Modal } from "antd"
import { comment } from "./table"
import { getVideoDetails, getDynamicDetails, getArticleComment, delArticleComment } from "../../../api/recObtainGuide"
import "./index.scss"
export default function DynamicDetails() {
  const { state: { id, type } } = useLocation()
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
  const [list, setList] = useState([])
  const [state, setState] = useState()
  const delCommentApi = (id) => {
    Modal.confirm({
      title: '提示',
      content: '是否删除该项',
      okText: '删除',
      onOk: () => {
        delArticleComment(id).then(res => {
          if (res) getCommentApi()
        })
      }
    })
  }
  const getCommentApi = () => {
    let data = JSON.parse(JSON.stringify(params))
    data.articleId = id
    getArticleComment(data).then(({ result }) => {
      let { total, records } = result;
      setTotal(total)
      setList(records)
    })
  }
  useEffect(() => {
    let res = ''
    switch (type) {
      case 2:
        res = getDynamicDetails({ id })
        break;
      case 3:
        res = getVideoDetails({ id })
        break;
    }
    res.then(({ result }) => {
      setState(result)
    })
    getCommentApi()
  }, [])
  return (
    <div className='DynamicDetails BooksDetails'>
      <div style={{
        flex: 1
      }}>
        <div className='title'>{state?.title}</div>
        <div className='album'>
          {
            type === 2 ?
              state?.type === 2 ?
                state?.titleUrl?.split(',').map((key, index) => {
                  return <Image src={key} height="auto" key={index} />
                })
                :
                state?.type === 1 ?
                  <video src={state?.titleUrl} controls></video> :
                  null
              :
              type === 3 ? <video src={state?.video} controls /> : null
          }
        </div>
        <div className='content' dangerouslySetInnerHTML={{ __html: state?.content.replace(/[\n\r]/g, '<br/>') }} />
      </div>
      <div className='nav'>评论列表（{list.length}条）</div>
      <Table
        columns={[...comment, ...[{
          width: 88,
          dataIndex: 'id',
          align: 'center',
          render: (id) => <div className='delItem' onClick={() => { delCommentApi({ id }) }}>删除</div>
        }]]}
        rowKey="id"
        dataSource={list}
        showHeader={false}
        pagination={{
          total: total,
          pageSize: params.pageSize,
          current: params.pageNo,
          onChange: (pageNo, pageSize) => { setParams(pageNo, pageSize) }
        }}
        scroll={{ y: 'calc(100vh - 100px)' }} />
    </div>
  )
}
