import React, { useEffect, useState } from 'react'
import { Table, Image } from "antd"
import { detailsTable } from "./table"
import { useLocation } from "react-router-dom"
import { handBookType } from "../../utils/specification"
import { getHandBookComment, getBookDetails } from "../../api/commendBook"
import { delArticleComment } from "../../api/recObtainGuide"
export default function BooksDetails() {
    const [list, setList] = useState([])
    const [state, setState] = useState()
    const [total, setTotal] = useState(0)
    const { state: { id } } = useLocation()
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10, articleId: id })
    const getHandBookCommentApi = () => {
        getHandBookComment(params).then(({ result }) => {
            let { records, total } = result
            setList(records)
            setTotal(total)
        })
    }
    // 获取当前手帐本评论数据
    useEffect(() => {
        getHandBookCommentApi()
        getBookDetails({ id }).then(({ result }) => {
            setState(result)
        })
    }, [])
    return (
        <div className='BooksDetails'>
            <div className='flexAlignCenter'>
                <div style={{
                    flex: 1
                }}>
                    <div className='title'>{state?.title}</div>
                    <div className='label'>{handBookType[state?.type]}</div>
                    <div className='content'>{state?.content}</div>
                    <div className='album'>

                        {
                            state?.picture ?
                                <Image.PreviewGroup>
                                    {
                                        state.picture.split(',').map((key, index) => {
                                            return <Image key={index} src={key} height="auto" />
                                        })
                                    }
                                </Image.PreviewGroup> :
                                <video src={state?.video} controls />

                        }
                        {/* <Image />
                        <Image />
                        <Image /> */}
                    </div>
                </div>
                <div className='flexs audit'>
                    {
                        state?.remark ? <>
                            {
                                state?.remark === '企业审核属实' ?
                                    <>
                                        {
                                            state?.stateAvatar ? <img src={state.stateAvatar} className='avarat' /> : null
                                        }
                                    </> : <img className='avarat' src="/assets/favicon.png" />
                            }
                            <div className='text'>{state?.remark}</div>
                        </> : null
                    }
                </div>
            </div>
            <div className='nav'>评论列表（{list.length}条）<span>收藏（{state?.collect}）</span></div>
            <Table columns={[...detailsTable, ...[{
                width: 88,
                align: 'center',
                render: (key) => <div className='delItem' onClick={(e) => { e.stopPropagation(); delArticleComment({ id: key.id }).then(res => { if (res) { getHandBookCommentApi() } }) }}>删除</div>
            }]]} rowKey="createTime" dataSource={list} showHeader={false} scroll={{ y: 'calc(100vh - 100px)' }} />
        </div>
    )
}
