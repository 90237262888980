export const brokerageUnit = { 1: '时', 2: '天', 3: '周', 4: '月', 5: '单' } // 佣金单位
export const unit = { 1: '时', 2: '天', 3: '月' } // 薪资结算单位
export const positionType = { 1: '兼职', 2: '实习', 3: '活动', 4: '全职' } // 招聘类型
export const partType = { 1: '周末兼职', 2: '长期兼职', 3: '短期兼职', 4: '寒暑假兼职' } //兼职类型
export const paymentType = { 1: '日结', 2: '周结', 3: '月结', 4: '完工结' } // 学生结算方式
export const type = { 1: '全包干', 2: '人员负责到底', 3: '提供初始人员' } // 平台类型
export const activity = { 1: '比赛', 2: "讲座", 3: '企业开放日', 4: '宣讲会', 5: '志愿者', 6: '展览展会', 7: '项目实践', 8: '公益活动', 9: '其他' }
export const property = { 1: '国有企业', 2: '民营企业', 3: '股份制企业', 4: "外资企业", 5: '合伙企业', 6: '事业单位', 7: '行政机关', 8: '社会团体', 9: '中外合资' }
export const financing = { 1: "未融资", 2: '天使轮', 3: 'A轮', 4: 'B轮', 5: 'C轮', 6: 'D轮及以上', 7: '已上市', 8: '不需要融资' } // 企业融资阶段
export const scale = { 1: "1-15人", 2: "15-50人", 3: "50-150人", 4: "150-500人", 5: "500-2000人", 6: "2000人以上" } // 企业规模
export const process = { '-1': "审核未通过", 1: '审核通过', 2: "待结算", 3: '已支付' } // 职位流程
export const interviewState = { sign_up: "待审核", forward: '企业待审核', invite: '待面试邀请', sending: "待接受面试", accept: '待面试', completed: '面试完成', refuse: '拒绝面试', invitation: '入职邀请', un_hired: '审核拒绝', refuse_work: '拒绝入职' }
export const industry = ["互联网/游戏/软件", "电子/通信/硬件", "汽车/机械/制造", "金融/经济/投资/财会", "教育/培训", "房产/家居/物业/建筑", "企业服务/咨询", "快消/百货/批发/零售", "广告/传媒/公关/展览", "医疗/健康/制药/生物", "交通/贸易/物流", "化工/能源/环保", "餐饮/酒店/旅游/娱乐", "公共事业/NGO/政府", "农林牧渔/其他"]
export const handBookType = { 1: '职才部落经历', 2: '工作实习经历', 3: '社团和组织经历', 4: '项目经历', 5: '竞赛/获奖经历', 6: '作品集（含技能强项）', 7: '研究经历', 8: '其他' }
export const politics = { 1: '群众', 2: '共青团员', 3: '党员', 4: '预备党员' }
export const education = ['中专', '大专', '本科', '研究生及以上']
export const adType = {
    1: '预备委员',
    2: '职荐书',
    3: '储备营',
    4: '滚动条'
}
export const configuration = [
    {
        type: "stuIntention",
        title: "求职期望",
        li: 0,
        witness: 0
    },
    {
        type: "stuEducate",
        title: "教育经历",
        li: 0,
        witness: 0
    },
    {
        type: "stuTribes",
        title: "职才部落经历",
        li: 0,
        witness: 1
    },
    {
        type: "stuPractices",
        title: "工作实习经历",
        li: 1,
        witness: 1
    },
    {
        type: "stuProjects",
        title: "项目经历",
        li: 1,
        witness: 1
    },
    {
        type: "stuResearch",
        title: "研究经历",
        li: 1,
        witness: 1
    },
    {
        type: "stuSocieties",
        title: "社团/组织经历",
        li: 1,
        witness: 1
    },
    {
        type: "stuAwards",
        title: "竞赛/获奖经历",
        li: 0,
        witness: 1
    },
    {
        type: "stuWorks",
        title: "作品集",
        li: 0,
        witness: 1
    },
    {
        type: "stuAwardCertificates",
        title: "奖项证书",
        li: 0,
        witness: 0
    },
    {
        type: "stuHobbies",
        title: "兴趣爱好",
        li: 0,
        witness: 0
    },
    {
        type: "stuOthers",
        title: "其他",
        li: 1,
        witness: 1
    }
]
export const roadbed = { 2: '职才提升', 3: '公司详情', 4: '简历详情', 5: '职才部落文章', 6: '职位详情', 7: '手账本详情',8:"职场前沿" }
// export const interviewState = {
// 	sign_up: "待审核",
// 	forward: '企业待审核',
// 	invite: '待面试邀请',
// 	sending: "待接受面试",
// 	accept: '待面试',
// 	completed: '面试完成',
// 	refuse: '拒绝面试',
// 	invitation: '入职邀请'
// }
export const objTurnArr = (obj) => {
    let arr = []
    for (let index in obj) {
        arr[index] = obj[index]
    }
    return arr
}
export const filterJoin = (data, join = ' ') => {
    return data.filter(key => {
        if (key) return key
    }).join(join)
}