import request from '../utils/request'
// 获取就业导向列表
export function getGuideList(params) {
	return request({
		url: 'sys/recObtainGuide/list',
		method: 'get',
		params
	})
}

// 获取就业向导详情
export function getGuideDetails(params) {
	return request({
		url: 'sys/recObtainGuide/list/details',
		method: 'get',
		params
	})
}

// 添加就业向导二级数据
export function addGuideDetails(data) {
	return request({
		url: 'sys/recObtainGuide/add',
		method: 'post',
		data
	})
}

// 编辑就业向导二级数据
export function editGuideDetails(data) {
	return request({
		url: 'sys/recObtainGuide/edit',
		method: 'post',
		data
	})
}

// 删除就业向导二级数据
export function deleteGuide(params) {
	return request({
		url: 'sys/recObtainGuide/delete',
		method: 'delete',
		params
	})
}


// 职才部落列表
export function getCampList(params) {
	return request({
		url: 'sys/RecReserveCamp/list',
		method: 'get',
		params
	})
}

// 添加职才部落
export function addCampList(data) {
	return request({
		url: 'sys/RecReserveCamp/add',
		method: 'post',
		data
	})
}

// 编辑职才部落
export function editCampList(data) {
	return request({
		url: 'sys/RecReserveCamp/edit',
		method: 'post',
		data
	})
}

// 置顶/取消置顶职才部落
export function isTopCamp(id) {
	return request({
		url: `sys/RecReserveCamp/top/${id}`,
		method: 'put'
	})
}
// 部落在线
export function online(id) {
	return request({
		url: `sys/RecReserveCamp/online/${id}`,
		method: "put"
	})
}
// 部落离线
export function offline(id) {
	return request({
		url: `sys/RecReserveCamp/offline/${id}`,
		method: "put"
	})
}

// 获取职才部落人员列表
export function getCampPersonnelList(params) {
	return request({
		url: 'sys/RecReserveCampDetails/student/list',
		method: 'get',
		params
	})
}

// 给予/取消部落职才标签
export function editCamplabel(data) {
	return request({
		url: 'sys/RecReserveCampDetails/edit',
		method: 'post',
		data
	})
}

// 获取职才部落动态列表
export function getCampArticlelList(params) {
	return request({
		url: 'sys/recReserveArticle/list',
		method: 'get',
		params
	})
}

// 获取职才部落视频列表
export function getCampVideoList(params) {
	return request({
		url: 'sys/recReserveCampVideo/list',
		method: 'get',
		params
	})
}

// 职才部落企业列表
export function getCampEnterpriseList(params) {
	return request({
		url: '/sys/RecReserveCampDetails/company/list',
		method: 'get',
		params
	})
}

// 移除职才部落学生成员及企业
export function deleteMember(params) {
	return request({
		url: 'sys/RecReserveCampDetails/delete',
		method: 'delete',
		params
	})
}


//  职才部落添加动态
export function campDynamicAdd(data) {
	return request({
		url: 'sys/recReserveArticle/add',
		method: 'POST',
		data
	})
}

// 职才部落编辑动态
export function campDynamicEdit(data) {
	return request({
		url: 'sys/recReserveArticle/edit',
		method: 'post',
		data
	})
}

// 职才部添加视频
export function campVideoAdd(data) {
	return request({
		url: 'sys/recReserveCampVideo/add',
		method: 'post',
		data
	})
}

// 职才部落编辑视频
export function campVideoEdit(data) {
	return request({
		url: 'sys/recReserveCampVideo/edit',
		method: 'post',
		data
	})
}

// 职才部落删除动态
export function deleteDynamic(params) {
	return request({
		url: 'sys/recReserveArticle/delete',
		method: 'delete',
		params
	})
}

// 职才部落删除视频
export function deleteVideo(params) {
	return request({
		url: 'sys/recReserveCampVideo/delete',
		method: 'delete',
		params
	})
}

// 获取职才部落动态详情
export function getDynamicDetails(params) {
	return request({
		url: 'sys/recReserveArticle/queryById',
		method: 'get',
		params
	})
}

// 获取职才部落视频详情
export function getVideoDetails(params) {
	return request({
		url: 'sys/recReserveCampVideo/queryById',
		method: 'get',
		params
	})
}

// 获取职场前沿列表
export function getPrefaceList(params) {
	return request({
		url: 'sys/recCareerPreface/list',
		method: 'get',
		params
	})
}

// 删除职场前沿列表
export function deletePreface(params) {
	return request({
		url: 'sys/recCareerPreface/delete',
		method: 'DELETE',
		params
	})
}

// 添加职场前沿数据
export function addPreface(data) {
	return request({
		url: 'sys/recCareerPreface/add',
		method: 'post',
		data
	})
}

// 编辑职场前沿数据
export function editPreface(data) {
	return request({
		url: 'sys/recCareerPreface/edit',
		method: 'post',
		data
	})
}


// 获取职场前沿详情数据
export function getPrefaceDetails(params) {
	return request({
		url: 'sys/recCareerPreface/queryById',
		method: 'get',
		params
	})
}

// 删除职场前沿评论
export function delComment(params) {
	return request({
		url: 'sys/recCareerPrefaceDetails/delete/comments',
		method: 'DELETE',
		params
	})
}

// 获取职才提升列表
export function getPromotionList(params) {
	return request({
		url: 'wechat/recCareerPromotion/list',
		method: 'get',
		params
	})
}

// 职才提升刪除
export function deletePromotion(params) {
	return request({
		url: 'wechat/recCareerPromotion/delete',
		method: 'delete',
		params
	})
}

// 职才提升添加
export function addPromotion(data) {
	return request({
		url: 'wechat/recCareerPromotion/add',
		method: 'post',
		data
	})
}

// 职才提升编辑
export function editPromotion(data) {
	return request({
		url: "wechat/recCareerPromotion/edit",
		method: 'post',
		data
	})
}

// 获取职才提升详情
export function getPromotionDetails(params) {
	return request({
		url: "wechat/recCareerPromotion/queryById",
		method: 'get',
		params
	})
}

// 获取职才提升视频
export function getPromotionVideo(params) {
	return request({
		url: 'wechat/recCareerPromotionDetails/list',
		method: 'get',
		params
	})
}

// 添加职才提升课程
export function addPromotionVideo(data) {
	return request({
		url: 'wechat/recCareerPromotionDetails/add',
		method: 'post',
		data
	})
}

// 编辑职才提升课程
export function editPromotionVideo(data) {
	return request({
		url: 'wechat/recCareerPromotionDetails/edit',
		method: 'post',
		data
	})
}


// 获取文章评论
export function getArticleComment(params) {
	return request({
		url: "sys/recReserveArticleComment/list",
		method: 'get',
		params
	})
}

// 删除文章评论
export function delArticleComment(params) {
	return request({
		url: 'sys/recReserveArticleComment/delete',
		method: 'delete',
		params
	})
}







// 职才部落 列表
export function getRecStuTribeList(params) {
	return request({
		url: '/wechat/recStuTribe/list',
		method: 'get',
		params
	})
}


// 职才部落 动态置顶
export function dynamicTop(params) {
	return request({
		url: 'sys/recReserveArticle/top',
		method: 'post',
		params
	})
}

// 职场前沿 置顶
export function prefaceTop(params) {
	return request({
		url: 'sys/recCareerPreface/top',
		method: 'post',
		params
	})
}


// 移除部落绑定企业
export function deleteSpecify(campId) {
	return request({
		url: `sys/RecReserveCampDetails/camp/${campId}/specify`,
		method: "DELETE"
	})
}
