import React, { useEffect, useState } from 'react'
import { Table, Image, Modal } from "antd"
import { detailsTable } from "./table"
import { useLocation } from "react-router-dom"
import { getPrefaceDetails, getArticleComment, delArticleComment } from "../../../api/recObtainGuide"
import "./index.scss"
import { getUserInfo } from '../../../utils/auth'
export default function BooksDetails() {
    const [state, setState] = useState()
    const userInfo = JSON.parse(getUserInfo()) || {}
    const [params, setParams] = useState({ pageSize: 10, pageNo: 1, type: 1 })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const { state: id } = useLocation()
    useEffect(() => {
        getPrefaceDetails({ id }).then(({ result }) => {
            setState(result)
        })
    }, [])
    const getArticleCommentApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        data.articleId = id
        getArticleComment(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    const delCommentApi = (data) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除当前项',
            okText: '删除',
            cancelButtonProps: true,
            onOk: () => {
                delArticleComment(data).then(res => {
                    if (res) {
                        getArticleCommentApi()
                    }
                })
            }
        })
    }
    useEffect(() => {
        getArticleCommentApi()
    }, [params])
    return (
        <div className='BooksDetails FrontierDetails'>
            <div style={{
                flex: 1
            }}>
                <div className='title'>{state?.title}</div>
                <div className='album'>
                    {
                        state?.type === 2 ?
                            <Image.PreviewGroup>
                                {
                                    state.image.split(',').map((key, index) => {
                                        return <Image key={index} src={key} height="auto" />
                                    })
                                }
                            </Image.PreviewGroup> :
                            state?.type === 1 ? <video src={state.video} controls /> : null

                    }
                </div>
                <div className='content' dangerouslySetInnerHTML={{ __html: state?.content.replace(/[\n\r]/g, '<br/>') }} />
            </div>
            <div className='nav'>评论列表（{list.length}条）<span>点赞（{state?.likes ?? 0}）</span></div>
            <Table
                columns={userInfo?.nature === 'sys' ? [...detailsTable, ...[{
                    width: 88,
                    dataIndex: 'id',
                    align: 'center',
                    render: (id) => <div className='delItem' onClick={() => { delCommentApi({ id }) }}>删除</div>
                }]] : detailsTable}
                rowKey="id"
                dataSource={list}
                showHeader={false}
                pagination={{
                    total: total,
                    pageSize: params.pageSize,
                    current: params.pageNo,
                    onChange: (pageNo, pageSize) => { setParams(pageNo, pageSize) }
                }}
                scroll={{ y: 'calc(100vh - 100px)' }} />
        </div>
    )
}
