import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Table, Modal } from "antd"
import { getPromotionList, deletePromotion } from "../../../api/recObtainGuide"
import { PromoteTable } from "../constans"
import Editor from './Editor'
import "../guide.scss"
import { getUserInfo } from '../../../utils/auth'

export default function Promote() {
	const navigate = useNavigate()
	const userInfo = JSON.parse(getUserInfo()) || {}
	const [list, setList] = useState([])
	const [paramsItem, setParamsItem] = useState({ visible: false })
	const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
	const [total, setTotal] = useState(0)

	// 获取职才提升列表
	const getCampListApi = () => {
		getPromotionList(params).then(({ result }) => {
			console.log('getCampList', result)
			let { records, total } = result
			setList(records)
			setTotal(total)
		})
	}
	let timeout;
	// input 输入选择
	const inputChange = (e) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		const fake = () => {
			setParams({ ...params, ...{ title: `,${e.target.value},` } })
		};
		timeout = setTimeout(fake, 1000);
	}
	const deleteItem = (id) => {
		Modal.confirm({
			title: '提示',
			content: '是否删除该项',
			okText: '删除',
			onOk: () => {
				deletePromotion({ id }).then(res => {
					if (res) getCampListApi()
				})
			}
		})
	}
	useEffect(() => {
		getCampListApi()
	}, [params])

	return (
		<div className='financialScreen'>
			<div className='screening'>
				<div className='ScreenContent'>
					<Input placeholder='搜索名称' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
					<Button onClick={getCampListApi} type='primary'>搜索</Button>
				</div>
				{
					userInfo?.nature === 'sys' ? <Button type="primary" onClick={() => {
						setParamsItem({ visible: true })
					}}><span className='iconfont'>&#xe684;</span> 新建
					</Button> : null
				}
			</div>
			<Table
				columns={
					userInfo?.nature === 'sys' ? [...PromoteTable, ...[{
						title: '操作',
						align: 'center',
						width: 150,
						render: (key) => <div className='operation' onClick={(e) => { e.stopPropagation() }}>
							<div className="edit" onClick={(e) => {
								e.stopPropagation()
								setParamsItem({ visible: true, params: { ...key, ...{ label: key.label.split(',') } } })
							}}>编辑</div>
							<div className='vertical'></div>
							<div onClick={(e) => {
								e.stopPropagation()
								deleteItem(key.id)
							}} className="reject">删除</div>
						</div>
					}]] : PromoteTable
				}
				dataSource={list}
				onRow={r => { return { onClick: e => { navigate('details', { state: r.id }) } } }}
				rowKey="id"
				scroll={{ y: 'calc(100vh - 100px)' }}
				pagination={{
					total: total,
					current: params.pageNo,
					pageSize: params.pageSize,
					onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
				}}
			/>
			<Editor visible={paramsItem.visible} params={paramsItem.params} cancel={() => { setParamsItem({ visible: false }) }} update={getCampListApi} />
		</div>
	)
}
