import request from '../utils/request'
// 获取职位信息
export function getPositionsInfo(params) {
    return request({
        url: 'sys/admin/list',
        method: 'get',
        params
    })
}
// 设置审核状态
export function setPositionsStatus(data) {
    return request({
        url: 'sys/admin/refuse/position',
        method: 'PUT',
        data: data
    })
}
// 获取职位详情
export function getPositionsDetails(data) {
    return request({
        url: 'web/recPosition/queryById?id=' + data,
        method: 'GET',
    })
}
// 查看职位成员信息
export function getPositionsMembers(params) {
    return request({
        url: 'sys/position/working',
        method: 'get',
        params
    })
}
// 查看该职位报名学员
export function getPositionsApply(params) {
    return request({
        url: 'sys/position/sign',
        method: 'get',
        params
    })
}

// 推送闪光优职
export function setPush(id) {
    return request({
        url: `web/recPosition/superior/${id}`,
        method: 'put'
    })
}

// 删除闪光优职
export function deleteSuperior(id) {
    return request({
        url: `web/recPosition/delete/superior/${id}`,
        method: 'DELETE'
    })
}

// 新建人才库
export function setPushLibrary(data) {
    return request({
        url: "sys/recPushLibrary/add",
        method: 'post',
        data
    })
}

// 获取职位推送列表
export function getPushList(params) {
    return request({
        url: "sys/recPushLibrary/list",
        method: 'GET',
        params
    })
}

// 删除职位推送库
export function delPush(params) {
    return request({
        url: 'sys/recPushLibrary/delete',
        method: 'delete',
        params
    })
}

// 获取职位推送库详情
export function getStudentsList(params) {
    return request({
        url: 'sys/recPushLibrayDetails/list',
        method: 'get',
        params
    })
}

// 删除职位推荐库学生
export function delPushStudent(params) {
    return request({
        url: 'sys/recPushLibrayDetails/delete',
        method: 'delete',
        params
    })
}

// 添加职位推荐学生
export function studentsAddPush(data) {
    return request({
        url: 'sys/recPushLibrayDetails/add',
        method: 'post',
        data
    })
}

// 设置职位上下架
export function setPositionsShelf(id) {
    return request({
        url: `web/recPosition/shelf?id=${id}`,
        method: 'post'
    })
}

// 设置职位置顶/取消置顶
export function setPositionsTop(id) {
    return request({
        url: `sys/position/top/${id}`,
        method: 'put'
    })
}

// 获取企业详情职位列表
export function getEnterprisePositionList(params) {
    return request({
        url: 'sys/position/list',
        method: 'get',
        params
    })
}

// 资金发放
export function recStuPenalty(params) {
    return request({
        url: 'wechat/recStuPenalty/deduct',
        method: 'put',
        params
    })
}

// 资金一键发放
export function oneKeyPenalty(data) {
    return request({
        url: 'wechat/recStuPenalty/commission/batch/pay',
        method: 'put',
        data
    })
}