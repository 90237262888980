import React, { useState, useEffect } from 'react'
import { useLocation, Outlet } from 'react-router-dom';
import Screening from "./components/Screening"
import PositionIItem from './components/PositionIItem'
import Audit from "./components/Audit";
import Rejected from "./components/Rejected";
import { Table } from "antd"
import { getPositionsInfo, setPositionsStatus } from "../../api/position"
import "./index.scss"
export default function List() {
    const { pathname } = useLocation()
    const [state, setState] = useState({ name: '', positionType: 0, process: -1, paymentType: 0, pageNo: 1, pageSize: 10, isShelf: '', type: 0, recEducation: '全部' })
    const [total, setTotal] = useState(1)
    const [list, setList] = useState([])
    const [modalBtn, setModalBtn] = useState({ id: '', auditShow: false, rejectedShow: false })
    const getPositionsInfoApi = () => {
        let obj = JSON.parse(JSON.stringify(state))
        for (let key in obj) {
            if (!obj[key]) {
                delete obj[key]
            }
        }
        if (state.process === -1) {
            delete obj.process
        }
        if (state.process === 0) {
            obj.process = 0
        }
        if (state.isShelf === 0) {
            obj.isShelf = 0
        }
        if (state.recEducation === '全部') {
            delete obj.recEducation
        }
        getPositionsInfo(obj).then(({ result }) => {
            setTotal(parseInt(result.total))
            result.records.forEach(key => {
                if (key.beginTime) {
                    key.beginTime = key.beginTime.split(' ')[0]
                    key.endTime = key.endTime.split(' ')[0]
                }
            });
            setList(result.records)
        })
    }
    const init = (e) => {
        setState(e)
    }
    // 显示弹框
    const selectButton = (e) => {
        let modalShow = modalBtn
        modalShow.id = e.id
        if (e.key) {
            modalShow.auditShow = true
            modalShow.rejectedShow = false
        } else {
            modalShow.auditShow = false
            modalShow.rejectedShow = true
        }
        setModalBtn({
            ...modalShow
        })
    }
    // 取消弹框显示
    const UndateVisible = () => {
        let modalShow = modalBtn
        for (let key in modalShow) {
            modalShow[key] = false
        }
        setModalBtn({
            ...modalShow
        })
    }
    // 更新职位状态
    const UndateTwoBrokerage = (e) => {
        e.id = modalBtn.id
        setPositionsStatus(e).then(res => {
            console.log(res, '设置状态回调')
            if (res) {
                UndateVisible()
                getPositionsInfoApi()
            }
        })
    }
    useEffect(() => {
        if (pathname === '/position/list') {
            getPositionsInfoApi()
        }
    }, [state,pathname])
    return (
        <>
            {
                pathname === '/position/list' ? <div className='position'>
                    <Screening state={state} setState={(e) => { init(e) }}></Screening>
                    <Table
                        columns={[
                            {
                                render: (key) => <PositionIItem state={key} selectButton={selectButton} update={getPositionsInfoApi} />
                            }
                        ]}
                        dataSource={list}
                        showHeader={false}
                        rowKey="id"
                        scroll={{ y: 'calc(100vh - 100px)' }}
                        pagination={{
                            total: total,
                            current: state.pageNo,
                            pageSize: state.pageSize,
                            onChange: (pageNo, pageSize) => { setState({ ...state, ...{ pageNo, pageSize } }) }
                        }} />
                    <Audit visible={modalBtn.auditShow} UndateVisible={UndateVisible} UndateTwoBrokerage={UndateTwoBrokerage} />
                    <Rejected visible={modalBtn.rejectedShow} UndateVisible={UndateVisible} UndateTwoBrokerage={UndateTwoBrokerage} />
                </div> : <Outlet />
            }
        </>

    )
}
