import request from '../utils/request'
// 获取简历列表
export function getResumeList(params) {
    return request({
        url: 'sys/admin/resume/list',
        method: 'get',
        params
    })
}

// 批量审核初审通过
export function auditResume(params) {
    return request({
        url: 'sys/admin/forward',
        method: 'PUT',
        params
    })
}

// 初审拒绝
export function refuseResume(params) {
    return request({
        url: 'sys/admin/refuse',
        method: 'put',
        params
    })
}

// 发送面试邀请
export function inviteResume(data) {
    return request({
        url: 'web/recPosition/invite',
        method: 'post',
        data
    })
}

// 通过面试
export function agreeInterview(id) {
    return request({
        url: `web/recPosition/agree/${id}`,
        method: 'put'
    })
}

// 拒绝面试
export function rejectInterview(params) {
    return request({
        url: 'web/recPosition/refuse',
        method: 'put',
        params
    })
}