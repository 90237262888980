import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import Existing from './components/Existing';
import Apply from './components/Apply';
import { getPositionsDetails } from "../../api/position";
import { brokerageUnit, unit, positionType, partType, paymentType, type, scale, financing } from "../../utils/specification"
import "./details.scss"
export default function Details() {
    let { state } = useLocation()
    console.log(state, 'state')
    const [details, setDetails] = useState({})
    // 计算html
    const calculate = (str) => {
        let reg = new RegExp("\n", "g");
        return str.replace(reg, '<br/>');
    }
    const getPositionsDetailsApi = () => {
        getPositionsDetails(state.id).then(({ result }) => {
            if (result?.beginTime) {
                result.beginTime = result.beginTime.split(' ')[0]
                result.endTime = result.endTime.split(' ')[0]
            }
            if (result?.point) {
                result.point = result.point.split(',')
            }
            result.description = calculate(result.description)
            let lebel = [details.recEducation, details.recPositionDetailId, details.interview ? '面试' : '', partType[details.partType], paymentType[details.paymentType], type[details.type]].filter(key => {
                return key
            })
            result.lebel = lebel
            setDetails(result)
        })
    }
    useEffect(() => {
        getPositionsDetailsApi()
    }, [])
    return (
        <div className='positionDetails'>
            <div className="header">
                <div>
                    <div className='top'>
                        <div className='name'>{details.name}</div>
                        <div className='price'>{details.salary}/{unit[details.unit]}</div>
                        <div className='species'>{positionType[details.positionType]}</div>
                    </div>
                    <div className='label'>{details.lebel ? details.lebel.join(' · ') : ''}</div>
                    <div className='labelBox' >
                        {
                            details.point ? details.point.map((key, index) => {
                                return (<div className='labelItem' key={index}>{key}</div>)
                            }) : ''
                        }
                    </div>
                    <div className='tiem'>{details.beginTime}  至  {details.endTime}</div>
                    <div className='site'>{details.address}</div>
                </div>
                <div>
                    <div className='status'></div>
                    <div className='dataBox'>
                        <div className='dataItem'>
                            <div>{details.brokerage}/{brokerageUnit[details.brokerageUnit]}</div>
                            <div>服务佣金</div>
                        </div>
                        <div className='vertical' style={{ background: '#FF0000' }}></div>
                        <div className='dataItem'>
                            <div>{details.twoBrokerage || 0}</div>
                            <div>猎才金</div>
                        </div>
                        <div className='vertical' style={{ height: '85px', background: '#FF7F00' }}></div>
                        <div className='dataItem'>
                            <div>{details.threeBrokerage || 0}</div>
                            <div>上级猎才金</div>
                        </div>
                        <div className='vertical' style={{ height: '70px', background: '#FFFF00' }}></div>
                        <div className='dataItem'>
                            <div>{details.period || 0}天</div>
                            <div>保用期</div>
                        </div>
                        <div className='vertical' style={{ height: '55px', background: '#00FF00' }}></div>
                        <div className='dataItem'>
                            <div>{details.credit || 0}元</div>
                            <div>信用金</div>
                        </div>
                        <div className='vertical' style={{ height: '40px', background: '#00FFFF ' }}></div>
                        <div className='dataItem'>
                            <div>{details.periodMin || '1天'}</div>
                            <div>信用时长</div>
                        </div>
                        <div className='vertical' style={{ height: '25px', background: '#0000FF ' }}></div>
                        <div className='dataItem'>
                            <div>{details.number || 0}人</div>
                            <div>招聘人数</div>
                        </div>
                        <div className='vertical' style={{ height: '10px', background: '#0000FF' }} ></div>
                        <div className='dataItem'>
                            <div>{details.pinged || 0}人</div>
                            <div>已聘人数</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="info">
                    <div className='top'>
                        <img src={details.logo} alt="" />
                        <div>
                            <div className='name'>{details.companyName}</div>
                            <div className='label'>{details.recIndustry} · {financing[details.financing]} · {scale[details.scale]}</div>
                        </div>
                    </div>
                    <div className='details'>
                        <div>职位描述</div>
                        <p dangerouslySetInnerHTML={{ __html: details.description }}></p>
                    </div>
                </div>
                <div style={{ width: "58%" }}>
                    <Existing id={state.id}/>
                    <Apply id={state.id} />
                </div>
            </div>
        </div>
    )
}
