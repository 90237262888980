import { filterJoin } from "../../utils/specification"
const type = ['余额', '信用金', '红包', '猎才金']
const status = {
    withdrawing: '提现中',
    withdraw_fail: '提现失败',
    withdraw_success: '提现成功',
    UNPAID: '待支付',
    PAID: '支付成功',
    FAIL_PAID: '支付失败'
}
export const columns = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '联系方式',
        dataIndex: 'phone',
        align: 'center'
    },
    {
        title: '入驻时间',
        dataIndex: 'createTime',
        align: 'center',
        render: (t) => <>{t.split(' ')[0]}</>
    }
]
export const details = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '姓名',
        dataIndex: "name",
        align: 'center'
    },
    {
        title: '金额',
        dataIndex: 'money',
        align: 'center'
    },
    {
        title: '时间',
        dataIndex: 'createTime',
        align: 'center'
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        width: 200,
        render: (t) => <>{status[t] || '成功'}</>
    }
]
export const audit = [
    {
        title: '序号',
        width: 80,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '姓名',
        dataIndex: "name",
        align: 'center'
    },
    {
        title: '金额',
        dataIndex: 'money',
        align: 'center'
    },
    {
        title: '时间',
        dataIndex: 'createTime',
        align: 'center'
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        render: (t) => <>{status[t] || '成功'}</>
    }
]