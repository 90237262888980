import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import { Table, Modal } from "antd"
import { hrDetailsColumns } from "./table"
import { getHrDetails, getPosition, authorize, cancelAuthorize } from "../../api/students"
import { getResumeDetails } from "../../api/commendBook"
import "./index.scss"
import "./hr.scss"
export default function HrDetauls() {
    const [info, setInfo] = useState({})
    const [list, setList] = useState([])
    const [position, setPosition] = useState([])
    const { state } = useLocation()
    useEffect(() => {
        getHrDetailsApi()
        getPosition().then(({ result }) => {
            setPosition(result.records)
        })
    }, [state])
    // 获取HR详情
    function getHrDetailsApi() {
        getHrDetails(state).then(async ({ result }) => {
            let collectedResumes = result.collectedResumes
            delete result.collectedResumes
            for (var i = 0; i < collectedResumes.length; i++) {
                let item = collectedResumes[i]
                let info = await getResumeDetails({ id: item.id }).then(res => res.result)
                item.jobObjective = info.jobObjective
                collectedResumes[i] = item
            }
            setInfo(result)
            setList(collectedResumes)
        })
    }
    // 组件模版
    function DetailsItem({ textClassName = "", text = "" }) {
        return (
            <div className="detailsItem">
                <div className="ol"></div>
                <div className={textClassName}>{text}</div>
            </div>
        )
    }
    // 授权信息
    function authorizeApi(resumeIds) {
        Modal.confirm({
            title: '提示',
            content: '确认授权当前简历信息展示',
            onOk: () => {
                authorize({ hrId: state, resumeIds }).then(res => getHrDetailsApi())
            }
        });
    }
    // 取消授权
    function cancelAuthorizeApi(authorizeId) {
        Modal.confirm({
            title: '提示',
            content: '确认取消授权当前简历信息展示',
            onOk: () => {
                cancelAuthorize(state, authorizeId).then(res => getHrDetailsApi())
            }
        });
    }
    return (
        <div className="HrDetails">
            <div className="userInfo">
                <div className="userAvatar">
                    <img src={`${info?.avatar ?? "--"}`} />
                </div>
                <div className="userName">
                    {info?.name ?? "--"}
                    <img className='gender' src={`/assets/${info.sex === 1 ? 'male' : info.sex === 2 ? 'female' : ''}.png`} alt="" />
                </div>
                <div className="detailsCard">
                    <DetailsItem text={`${info?.age ?? "--"}岁`} />
                    <DetailsItem text={`${info?.employerName ?? "--"}`} />
                </div>
                <div className="detailsCard">
                    <DetailsItem text={`${info?.workingYears ?? "--"}年`} />
                    <DetailsItem text={`${position.filter(key => key.id == info.industryId)[0]?.name ?? '--'}`} />
                </div>
                <div className="achievement">
                    <div className="myAchievement">联系方式</div>
                    <div className="detailsCard">
                        <div className="call">
                            <span className='phone'>&#xe969;</span>	 {info?.phone ?? "--"}
                            {/* <PhoneOutlined style={{ color: "#1764FE", marginRight: "20px" }} /> */}
                        </div>
                        <div className="call">
                            于<span>{info?.superiorName}</span>{info?.createTime?.split(" ")[0]}加入
                        </div>
                    </div>
                </div>
            </div>
            <div className="hrlist">
                <Table
                    rowKey="id"
                    // showHeader={false}
                    columns={[...hrDetailsColumns, ...[{
                        title: '操作',
                        dataIndex: 'authorized',
                        width: 128,
                        render: (t, key) => <> {t ? <div className="agree shopping" onClick={() => cancelAuthorizeApi(key.authorizeId)}>取消授权</div> : <div className="agree" onClick={() => authorizeApi(key.id)}>授权</div>}</>,
                        align: "center"
                    }]]}
                    dataSource={list}
                    pagination={false}
                />
            </div>
        </div>
    )
}