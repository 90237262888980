import request from '../utils/request'
// 获取简历列表
export function getResumeList(params) {
    return request({
        url: 'sys/admin/resume/all/list',
        method: 'get',
        params
    })
}

// 获取学生简历列表
export function getStudentResumeList(studentId) {
    return request({
        url: `sys/admin/resume/query/${studentId}`,
        method: 'get'
    })
}

// 通过简历ID查询简历详情
export function getResumeDetails(params) {
    return request({
        url: 'wechat/recStuResume/queryById',
        method: 'get',
        params
    })
}

// 获取学生信息
export function getStudentInfo(studentId) {
    return request({
        url: `sys/admin/queryById/${studentId}`,
        method: 'get'
    })
}

// 获取简历详情
export function getResumeInfo(params) {
    return request({
        url: 'wechat/recStuResume/queryById',
        method: 'get',
        params
    })
}

// 获取文件详情
export function getPDFDetailis(params){
    return request({
        url: 'sys/oss/file/queryById',
        method: 'get',
        params
    })
}

// 删除简历
export function delectResume(params){
    return request({
        url:'wechat/recStuResume/delete',
        method:'delete',
        params
    })
}

// 推送/取消明日精英
export function setRecommendInfo(data) {
    return request({
        url: 'sys/admin/resume/push',
        method: 'PUT',
        data
    })
}

// 获取见证列表
export function getWitness(params) {
    return request({
        url: 'wechat/recStuResumeBook/list',
        method: 'get',
        params
    })
}

// 获取手帐本列表（手帐本后台查看-分页列表查询）
export function getHandBookList(params) {
    return request({
        url: 'wechat/recBook/sys/list',
        method: 'get',
        params
    })
}

// 手帐本审核通过
export function setPass(params) {
    return request({
        url: 'wechat/recBook/success',
        method: 'PUT',
        params
    })
}

// 手帐本审核拒绝
export function setReject(params) {
    return request({
        url: 'wechat/recBook/refuse',
        method: 'PUT',
        params
    })
}

// 手帐本推荐/移除
export function setRecommend(data) {
    return request({
        url: 'wechat/recBook/edit',
        method: 'post',
        data
    })
}

// 手账本详情
export function getBookDetails(params) {
    return request({
        url: 'wechat/recBook/sys/queryById',
        method: 'get',
        params
    })
}

// 手帐本评论数据
export function getHandBookComment(params) {
    return request({
        url: 'sys/recReserveArticleComment/list',
        method: "get",
        params
    })
}

// 获取快照简历
export function getSnapshootResume(params) {
    return request({
        url: 'wechat/recStuApply/queryById',
        method: 'get',
        params
    })
}


// 获取行业列表
export function getPosition(params) {
    return request({
        url: 'com/recPositionDetail/auth/list',
        method: 'get',
        params
    })
}


// 获取HR推送库列表
export function getHrPushList(params) {
    return request({
        url: 'sys/resumePushHr/warehouse/page',
        method: 'get',
        params
    })
}

// 创建推送库
export function addResumePushHr(data) {
    return request({
        url: 'sys/resumePushHr/warehouse/add',
        method: 'post',
        data
    })
}

// 向推送库添加简历
export function pushResumePushHr(data) {
    return request({
        url: 'sys/resumePushHr/warehouse/resume/add',
        method: 'post',
        data
    })
}

// 推送职位中的已报名学生到人才推送库中
export function positionPushLibrary(data) {
    return request({
        url: 'sys/recPushLibrary/position/push',
        method: 'post',
        data
    })
}

// 从推送库移除简历
export function removeResumePushHr(data) {
    return request({
        url: 'sys/resumePushHr/warehouse/resume/remove',
        method: 'post',
        data
    })
}

// 获取推送库详情
export function getResumePushHrDetails(warehouseId) {
    return request({
        url: `sys/resumePushHr/warehouse/${warehouseId}/detail`,
        method: 'get'
    })
}

// 向HR推送简历
export function pushWarehouseHr(data) {
    return request({
        url: 'sys/resumePushHr/warehouse/hr/push',
        method: 'post',
        data
    })
}

// 根据推送库ID获取推送记录
export function getWarehouseRecord(warehouseId) {
    return request({
        url: `sys/resumePushHr/warehouse/${warehouseId}/record/batch/list`,
        method: 'get'
    })
}