import "./Audit.scss";
import { Form, Input, Modal, Button } from "antd"
export default function InputView(props) {
    const handleCancel = () => {
        props.UndateVisible()
    };
    const onFinish = (values) => {
        console.log('Success:', values);
        values.status = '1'
        props.UndateTwoBrokerage(values)
    };
    return (
        <Modal
            visible={props.visible}
            title="审核"
            footer={null}
            onCancel={handleCancel}
            destroyOnClose
        >
            <Form name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    name="credit"
                    label="信用金"
                    rules={[
                        {
                            required: true,
                            message: '请输入信用金',
                        },
                    ]}
                >
                    <Input placeholder='请输入信用金' type={"number"} />
                </Form.Item>
                <Form.Item
                    name="periodMin"
                    label="信用时长"
                    rules={[
                        {
                            required: true,
                            message: '请输入最少工作时长',
                        },
                    ]}
                >
                    <Input placeholder='请输入最少工作时长' type={"number"} suffix={<div>小时</div>} />
                </Form.Item>
                <Form.Item
                    name="period"
                    label="保用期"
                    rules={[
                        {
                            required: true,
                            message: '请输入保用期',
                        },
                    ]}
                >
                    <Input placeholder='请输入保用期' type={"number"} suffix={<div>天</div>} />
                </Form.Item>

                <Form.Item
                    name="twoBrokerage"
                    label="一级猎才金"
                    rules={[
                        {
                            required: true,
                            message: '请输入一级猎才金',
                        },
                    ]}
                >
                    <Input placeholder='请输入一级猎才金' type={"number"} maxLength={2} />
                </Form.Item>
                <Form.Item
                    name="threeBrokerage"
                    label="二级猎才金"
                    rules={[
                        {
                            required: true,
                            message: '请输入二级猎才金',
                        },
                    ]}
                >
                    <Input placeholder='请输入二级猎才金' type={"number"} maxLength={2}/>
                </Form.Item>
                <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}