import React, { useState } from 'react'
import { Modal, Form, Input, Button, Upload, message } from "antd"
import { addPromotion, editPromotion } from "../../../api/recObtainGuide"
import "./index.scss"
import { useEffect } from 'react'
export default function Editor(props) {
    const [fileList, setFileList] = useState([])
    const fileChange = ({ fileList: newFileList }) => {
        if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
            setFileList([...[newFileList[newFileList.length - 1].response.result.url]])
        }
    };
    const onFinish = (e) => {
        if (!fileList.length) {
            message.error('请上传封面图')
            return false
        }
        for (let index in e) {
            if (Array.isArray(e[index])) {
                e[index] = e[index].join(',')
            }
        }
        e.url = fileList.join(',')
        let arr = ''
        if (props?.params?.id) {
            arr = editPromotion({ ...props.params, ...e })
        } else {
            arr = addPromotion(e)
        }
        arr.then(res => {
            if (res) {
                props.update()
                props.cancel()
                setFileList([])
            }
        })
    }
    useEffect(() => {
        if (props?.params?.id) {
            setFileList(props?.params?.url?.split(',') ?? [])
        }
    }, [props])
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            width={598}
            destroyOnClose
            maskClosable
            wrapClassName="promoteModal"
            className="promoteModal"
            onCancel={() => { props.cancel(); setFileList([]) }}
        >
            <Form
                initialValues={{
                    remember: true,
                    ...props.params
                }}
                onFinish={onFinish}
                autoComplete="off"
                className='editor'
            >
                <Form.Item name="title" label="标题" rules={[{ required: true, message: '请输入你的标题' }]}>
                    <Input placeholder="请输入你的标题" />
                </Form.Item>
                {/* 原系列改为特点 */}
                <Form.Item name="category" label="特点" rules={[{ required: true, message: '请输入您的特点' }]}>
                    <Input placeholder="请输入您的特点" maxLength={11} />
                </Form.Item>
                <Form.Item name="content" label="介绍" rules={[{ required: true, message: '请介绍你的内容' }]}>
                    <Input.TextArea placeholder="请介绍你的内容" />
                </Form.Item>
                <Form.Item name="label" label="标签" rules={[{ required: true, message: '请选择标签' }]}>
                    <Input placeholder="请输入自定义标签" />
                </Form.Item>
                <Form.Item label="封面图" className='requireds'>
                    <Upload
                        action={process.env.REACT_APP_BASE_UPLOAD_API}
                        onChange={(e) => { fileChange(e) }}
                        accept=".png,.jpg"
                    >
                        {
                            fileList.length ? fileList.map((key, index) => {
                                return (
                                    <div key={index} className="uploadButton uploadButtonImg">
                                        <img src={key} />
                                    </div>
                                )
                            }) : <Button className='uploadButton'>
                                <span className='iconfont'>&#xe60f;</span>
                            </Button>
                        }
                    </Upload>
                </Form.Item>
                <div className='flexSbCenter' >
                    <Button type="default" onClick={() => { props.cancel(); setFileList([]) }} loading={false} className='submit'>取消</Button>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>确定</Button>
                </div>
            </Form>
        </Modal>
    )
}
