import React, { useState } from 'react'
import { Modal, Form, Input, Button, Upload, message, InputNumber } from "antd"
import { addPromotionVideo, editPromotionVideo } from "../../../api/recObtainGuide"
import "./index.scss"
import { useEffect } from 'react'
export default function AddVideo(props) {
    const onFinish = (e) => {
        if (!fileList) {
            message.error('请上传视频文件')
            return false
        }
        e.video = fileList
        let arr = ' '
        if (props?.params?.id) {
            arr = editPromotionVideo({ ...props.params, ...e })
        } else {
            e.promotionId = props.id
            arr = addPromotionVideo(e)
        }
        arr.then(res => {
            if (res) {
                props.update()
                props.cancel()
                setFileList('')
            }
        })
    }
    const [fileList, setFileList] = useState('')
    const fileChange = ({ fileList: newFileList }) => {
        if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
            setFileList(newFileList[newFileList.length - 1].response.result.url)
        }
    };
    useEffect(() => {
        if (props?.params?.id) {
            setFileList(props.params.video)
        }
    })
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            width={598}
            destroyOnClose
            maskClosable
            wrapClassName="promoteModal"
            className="promoteModal reserveModal"
            onCancel={() => { props.cancel() }}
        >
            <Form
                initialValues={{
                    remember: true,
                    ...props.params
                }}
                onFinish={onFinish}
                autoComplete="off"
                className='editor'
            >
                <Form.Item label="标题" name="title" rules={[{ required: true, message: '请输入你的标题' }]}>
                    <Input placeholder="请输入你的标题" />
                </Form.Item>
                <Form.Item label="课节" name="course" rules={[{ required: true, message: '请输入你的课节' }]}>
                    <InputNumber min={1} placeholder="请输入你的课节" />
                </Form.Item>
                <Form.Item label="视频" className='requireds'>
                    <Upload
                        action={process.env.REACT_APP_BASE_UPLOAD_API}
                        onChange={(e) => { fileChange(e) }}
                        accept=".mp4"
                    >
                        {
                            fileList ?
                                <div className="uploadButton uploadButtonImg">
                                    <video src={fileList} />
                                </div> :
                                <Button className='uploadButton'>
                                    <div className='iconfont'>&#xe60f;</div>
                                    <p>支持MP4格式</p>
                                </Button>
                        }
                    </Upload>
                </Form.Item>
                <div className='flexSbCenter' >
                    <Button type="default" onClick={() => { props.cancel() }} loading={false} className='submit'>取消</Button>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>确定</Button>
                </div>
            </Form>
        </Modal>
    )
}
