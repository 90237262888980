import React, { useState, useEffect } from 'react'
import { Select, Input } from "antd"
import { getSchoolList } from "../../../api/school"
export default function Screening(props) {
    const [selectBtn, setSelectBtn] = useState({ query: '', title: '全部学校' });
    const [input, setInput] = useState();
    const [school, setSchool] = useState([])
    const { Option } = Select
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            props.state({ name: e.target.value, pageNo: 1 })
        };
        timeout = setTimeout(fake, 1000);
    }
    const handleChange = (e) => {
        props.state({ schoolId: e })
    }
    useEffect(() => {
        getSchoolList({ pageSize: 10000 }).then(({ result }) => {
            setSchool(result.records)
        })
    }, [])

    return (
        <div className='screening'>
            <div className='flexAlignCenter'>
                <Input placeholder='输入预备委员姓名' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
                <Select defaultValue="" showSearch style={{ width: 240 }} onChange={handleChange}>
                    {
                        school.map(key => {
                            return <Option value={key.id} key={key.id}>{key.name}</Option>
                        })
                    }
                    <Option value=''>全部学校</Option>
                </Select>
            </div>
        </div >
    )
}
