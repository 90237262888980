import React from "react";
// import RenderRoutes from "../components/RenderRoutes";
import { useRoutes } from "react-router-dom";
// import { Routes, Route } from "react-router-dom";

// 引入默认路由页面
import Layout from "../views/Layout";
import Login from "../views/Login";
import NotFound from "../views/NotFound";

// 引入权限路由页面
import Home from "../views/Home";

// 猎才管理 
import List from "../views/Hunting/List";
import Audit from "../views/Hunting/Audit";
import HuntingDetails from "../views/Hunting/Details";

// 职位管理
import PositionList from "../views/Position/List"
import PositionDetails from "../views/Position/Details";
import PositionPush from "../views/Position/Push"
import PositionPushDetails from "../views/Position/PushDetails"
import PositionResumeAudit from "../views/Position/ResumeAudit"

// 学校管理
import School from "../views/School"
import SchoolDetails from "../views/School/Details";


// 企业列表和审核列表
import EnterpriseList from "../views/Enterprise/List"
import EnterpriseAudit from "../views/Enterprise/Audit"
import EnterpriseEditor from "../views/Enterprise/Editor"
import EnterpriseDetails from "../views/Enterprise/Details"

// 学生管理列表
import StudentsList from "../views/User/StudentsList"
import StudentsAudit from "../views/User/StudentsAudit"
import StudentsDetails from "../views/User/StudentsDetails";
import HrList from "../views/User/HrList";
import HrDetauls from "../views/User/HrDetails";


// 储备营管理
import ReserveCampList from "../views/ReserveCamp/List" // 部落职才列表
import ReserveCampDetails from "../views/ReserveCamp/List/Details" // 部落职才详情
import ReserveDynamicDetails from "../views/ReserveCamp/List/DynamicDetails" // 职才部落动态详情
import ReserveCampFrontier from "../views/ReserveCamp/Frontier" // 职场前沿列表
import ReserveCampFrontierDetails from "../views/ReserveCamp/Frontier/Details" // 职场前沿详情
import ReserveCampPromote from "../views/ReserveCamp/Promote" // 职才提升列表
import ReserveCampPromoteDetails from "../views/ReserveCamp/Promote/Details" // 职才提升详情
import ReserveCampGuide from "../views/ReserveCamp/Guide" // 专业就业导向
import ReserveCampGuideDetails from "../views/ReserveCamp/Guide/Details" // 专业就业导向详情

// 职荐书管理
import CommendBookResume from "../views/CommendBook/Resume"
import CommendBookResumeDetails from "../views/CommendBook/ResumeDetails"
import CommendBookResumePreview from "../views/CommendBook/ResumePreview"
import CommendBooks from "../views/CommendBook/Books"
import CommendBooksDetails from "../views/CommendBook/BooksDetails"
import HrPush from "../views/CommendBook/HrPush";
import HrPushDetails from "../views/CommendBook/HrPushDetails"

// 广告管理
import Advertising from "../views/Advertising"

// 财务管理
import FinancialStatistical from "../views/Financial/Statistical"
import FinancialDetails from "../views/Financial/Details"
import FinancialAudit from "../views/Financial/Audit"
const base = [
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: 'position',
				children: [
					{
						path: 'list',
						element: <PositionList />,
						children: [
							{
								path: "details",
								element: <PositionDetails />
							},
						]
					},
					{
						path: 'push',
						element: <PositionPush />,
						children: [
							{
								path: 'details',
								element: <PositionPushDetails />
							}
						]
					},
					{
						path: 'resumeAudit',
						element: <PositionResumeAudit />
					}
				]
			},
			{
				path: 'school',
				children: [
					{
						path: '',
						element: <School />
					},
					{
						path: 'details',
						element: <SchoolDetails />
					}
				]
			},
			{
				path: "hunting",
				children: [
					{
						path: "list",
						element: <List />,
						children: [
							{
								path: 'details',
								element: <HuntingDetails />
							}
						]
					},
					{
						path: "audit",
						element: <Audit />
					}
				]
			},
			{
				path: 'enterprise',
				children: [
					{
						path: 'list',
						element: <EnterpriseList />,
						children: [
							{
								path: 'editor',
								element: <EnterpriseEditor />
							},
							{
								path: 'details',
								element: <EnterpriseDetails />
							}
						]
					},
					{
						path: 'audit',
						element: <EnterpriseAudit />
					}
				]
			},
			{
				path: 'students',
				children: [
					{
						path: 'list',
						element: <StudentsList />,
						children: [
							{
								path: 'details',
								element: <StudentsDetails />
							}
						]
					},
					{
						path: 'audit',
						element: <StudentsAudit />
					},
					{
						path: 'hr',
						element: <HrList />
					},
					{
						path: 'hr/details',
						element: <HrDetauls />
					}
				]
			},
			{
				path: 'reserveCamp',
				children: [
					{
						path: 'list',
						children: [
							{
								path: "",
								element: <ReserveCampList />,
							},
							{
								path: "details",
								children: [
									{
										path: "",
										element: <ReserveCampDetails />
									},
									{
										path: 'dynamicDetails',
										element: <ReserveDynamicDetails />
									}
								]
							}
						]
					},
					{
						path: 'frontier',
						children: [
							{
								path: "",
								element: <ReserveCampFrontier />,
							},
							{
								path: "details",
								element: <ReserveCampFrontierDetails />,
							}
						]
					},
					{
						path: 'promote',
						children: [
							{
								path: "",
								element: <ReserveCampPromote />,
							},
							{
								path: "details",
								element: <ReserveCampPromoteDetails />,
							}
						]
					},
					{
						path: 'guide',
						children: [
							{
								path: "",
								element: <ReserveCampGuide />,
							},
							{
								path: "details",
								element: <ReserveCampGuideDetails />,
							}
						]
					}
				]
			},
			{
				path: 'commendBook',
				children: [
					{
						path: 'resume',
						element: <CommendBookResume />,
						children: [
							{
								path: 'details',
								element: <CommendBookResumeDetails />
							},
							{
								path: 'preview',
								element: <CommendBookResumePreview />
							}
						]
					},
					{
						path: 'books',
						element: <CommendBooks />,
						children: [
							{
								path: 'details',
								element: <CommendBooksDetails />
							}
						]
					},
					{
						path: 'hrPush',
						element: <HrPush />
					},
					{
						path: 'hrPush/details',
						element: <HrPushDetails />
					}
				]
			},
			{
				path: 'financial',
				children: [
					{
						path: 'statistical',
						element: <FinancialStatistical />
					},
					{
						path: 'details',
						element: <FinancialDetails />
					},
					{
						path: 'audit',
						element: <FinancialAudit />
					}
				]
			},
			{
				path: 'advertising',
				children: [
					{
						path: '',
						element: <Advertising />,
					}
				]
			},

		]
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '*',
		element: <NotFound />,
	},
];
export const RoutesList = () => {
	const element = useRoutes(base)
	return (
		<div>{element}</div>
	);
};

