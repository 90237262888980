import React, { useState } from 'react'
import { Modal, Form, Input, Button, Select, Divider, Space, Typography, DatePicker } from "antd"
import { education } from "../../../utils/specification"
import { addGuideDetails, editGuideDetails } from "../../../api/recObtainGuide"
import "./index.scss"
export default function Editor(props) {
    const { Option } = Select
    const [name, setName] = useState('');
    const [items, setItems] = useState([]);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = () => {
        setItems([...items, name]);
        setName('');
    };
    const onFinish = (e) => {
        for (let index in e) {
            if (Array.isArray(e[index])) {
                e[index] = e[index].join(',')
            }
        }
        let arr = ''
        if (props?.params?.id) {
            arr = editGuideDetails({ ...props.params, ...e })
        } else {
            e.specialty = props.specialty
            arr = addGuideDetails(e)
        }
        arr.then(res => {
            if (res) {
                props.cancel()
                props.update()
                setItems([])
            }
        })
    }
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            destroyOnClose
            maskClosable
            width={658}
            // setFileList([])
            wrapClassName="GuideDetailsModal"
            className="GuideDetailsModal"
            onCancel={props.cancel}
        >
            <Form
                name="add"
                initialValues={{
                    remember: true,
                    ...props.params
                }}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className='editor'
            >
                <Form.Item name="label" label="岗位名称" rules={[{ required: true, message: '请输入岗位名称' }]} >
                    <Input placeholder="请输入岗位名称" />
                </Form.Item>
                <Form.Item name="content" label="岗位介绍" rules={[{ required: true, message: '请输入岗位介绍' }]} >
                    <Input placeholder="请输入岗位介绍" />
                </Form.Item>
                <Form.Item name="introduce" label="工作介绍" rules={[{ required: true, message: '请输入工作介绍' }]} >
                    <Input.TextArea placeholder="请输入工作介绍" />
                </Form.Item>
                <Form.Item label="从业条件" className="flexSpaceBetween" width="auto" >
                    <Form.Item name="conditions" style={{ width: "calc(100% / 2)" }} rules={[{ required: true, message: '请选择学历要求' }]} >
                        <Select placeholder="请选择学历要求">
                            {
                                education.map((key, index) => {
                                    return <Option key={index} value={key} label={key} />
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="background" style={{ width: "calc(100% / 2)" }} rules={[{ required: true, message: '请输入专业背景' }]} >
                        <Input placeholder='请输入专业背景' />
                    </Form.Item>
                </Form.Item>
                <Form.Item name="generalize" label="薪资概况" rules={[{ required: true, message: '请填写薪资概况' }]}>
                    <Input placeholder="请填写薪资概况" />
                </Form.Item>
                <Form.Item name="skill" label="需要掌握的技能" rules={[{ required: true, message: '请选择技能' }]} >
                    <Select mode="multiple" placeholder="请选择技能" dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space align="center" style={{ padding: '0 8px 4px', }} >
                                <Input placeholder="请输入技能标签" value={name} onChange={onNameChange} />
                                <Typography.Link onClick={(e) => { e.preventDefault(); addItem() }} style={{ whiteSpace: 'nowrap' }}>
                                    添加
                                </Typography.Link>
                            </Space>
                        </>
                    )}>
                        {
                            items.map((key, index) => {
                                return <Option value={key} key={index} label={key} />
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="other" label="其他">
                    <Input.TextArea placeholder="其他" />
                </Form.Item>
                <div className='flexSbCenter' >
                    <Button type="default" onClick={() => { props.cancel(); setItems([]) }} loading={false} className='submit'>取消</Button>
                    <Button type="primary" htmlType='submit' onClick={onFinish} loading={false} className='submit'>确定</Button>
                </div>
            </Form>
        </Modal >
    )
}
