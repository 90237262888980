import React, { useEffect, useState, useRef } from 'react'
import { Table, Input, Modal, message } from "antd"
import { useNavigate } from "react-router-dom"
import { resumeAudit } from "./table"
import { getResumeList, auditResume, refuseResume, inviteResume, agreeInterview, rejectInterview } from "../../api/resume"
import { objTurnArr } from "../../utils/specification"
import "./resumeAudit.scss"
export default function Resume() {
    const navigate = useNavigate()
    const rejectInput = useRef()
    const [params, setParams] = useState({ name: '', type: 0, status: 0, pageNo: 1, pageSize: 10 })
    const processType = [
        { title: "全部", key: 0 },
        { title: "待审核", key: "sign_up" },
        { title: "待面试", key: "accept" },
        { title: "保用中", key: "period" },
        { title: "待结算", key: "un_settle" },
        { title: "已完成", key: "completed" },
        { title: "已拒接", key: "refuse" },
    ]
    // 职位类型
    const postType = objTurnArr({ 0: '全部', 1: '兼职', 2: '实习', 3: '活动', 4: '全职' });
    console.log(processType, 'postTypepostTypepostType')
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const getResumeApi = () => {
        let obj = JSON.parse(JSON.stringify(params))
        for (let index in obj) {
            if (!obj[index]) {
                delete obj[index]
            }
        }
        getResumeList(obj).then(({ result }) => {
            let { total, records } = result
            setList(records)
            setTotal(total)
        })
    }
    // 定义定时器执行
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setParams({ ...params, ['name']: e.target.value,['pageNo']:1 })
        };
        timeout = setTimeout(fake, 1000);
    }
    const refuseResumeApi = (id) => {
        return (
            Modal.confirm({
                title: '提示',
                content: (<Input className='rejectInput' ref={rejectInput} placeholder='请输入拒绝备注' />),
                okText: '拒绝',
                onOk: () => {
                    let remark = rejectInput.current.input.value
                    if (!remark) {
                        message.error('请输入拒绝备注')
                    } else {
                        refuseResume({ id, remark }).then(res => {
                            if (res) {
                                getResumeApi()
                            }
                        })
                    }
                }
            })
        )
    }
    // 定义批量审核api
    const auditResumeApi = (data) => {
        auditResume(data).then(res => {
            if (res) {
                getResumeApi()
            }
        })
    }
    // 定义发送发送 
    useEffect(() => {
        getResumeApi()
    }, [params])
    return (
        <div className='resume'>
            <div className='screening'>
                <div className='flexAlignCenter'>
                    <div className='typeWrap'>
                        {
                            processType.map((item, index) => {
                                return <div className={`typeItem ${params.status === item.key ? 'typeItemAct' : ''}`} key={index} onClick={() => { setParams({ ...params, ...{ status: item.key } }) }}>{item.title}</div>
                            })
                        }
                    </div>
                    <div className='typeWrap'>
                        {
                            postType.map((item, index) => {
                                return <div className={`typeItem ${params.type === index ? 'typeItemAct' : ''}`} key={index} onClick={() => { setParams({ ...params, ...{ type: index } }) }}>{item}</div>
                            })
                        }
                    </div>
                </div>
                <div className='flexAlignCenter' style={{ marginTop: '20px' }}>
                    <Input placeholder='搜索岗位信息' onInput={(e) => { inputChange(e) }} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
                </div>
            </div >
            <Table
                rowKey="resumeId"

                columns={[...resumeAudit, ...[{
                    title: '操作',
                    align: 'center',
                    render: (key) =>
                        <div className='operation'>
                            {
                                key.status === 'sign_up' ?
                                    <>
                                        <div className='operationItem' onClick={() => { auditResumeApi({ ids: key.resumeId }) }}>通过初审</div>
                                        <div className='operationItem' onClick={() => { refuseResumeApi(key.resumeId) }}>拒绝初审</div>
                                    </> :
                                    key.status === 'period' ? <>2022年05月20日保用结束</> : null

                            }
                            {/* key.status === 'accept' ?
                                        <div className='operationItem' onClick={() => { inviteResume({ id: key.resumeId }).then(res => { if (res) getResumeApi() }) }}>面试</div> :
                                        key.status === 'accept' ?
                                            <>
                                                <div className='operationItem' onClick={() => { agreeInterview(key.resumeId).then(res => { if (res) getResumeApi() }) }}>通过面试</div>
                                                <div className='operationItem' onClick={() => { rejectInterview({ id: key.resumeId }).then(res => { if (res) getResumeApi() }) }}>拒绝面试</div>
                                            </>
                                            :  */}
                            <div className='flexs' onClick={() => { navigate('/commendBook/resume/preview', { state: { ...key, ...{ applyId: key.resumeId, name: key.student } } }) }}>查看</div>
                        </div>
                }]]}
                dataSource={list}
                pagination={{
                    total: total,
                    current: params.pageNo,
                    pageSize: params.pageSize,
                    onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
                }}
                scroll={{ y: 'calc(100vh - 354px)' }}
            />
        </div>
    )
}
