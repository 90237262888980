import React, { useState, useEffect } from 'react'
import { Select, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { objTurnArr, positionType as positionList, paymentType as paymentList, type as typeList } from "../../../utils/specification"
const { Option } = Select;
export default function Screening(props) {
    const education = ['全部', '中专', '大专', '本科', '研究生及以上']
    positionList[0] = '全部'
    const positionType = objTurnArr(positionList)
    const process = [
        { key: -1, title: '全部' },
        { key: 0, title: '待审核' },
        { key: 1, title: '进行中' },
        { key: 3, title: '已完成' }
    ]
    paymentList[0] = '结算方式'
    const paymentType = objTurnArr(paymentList)
    typeList[0] = '职位类型'
    const type = objTurnArr(typeList)
    const [state, setState] = useState(props.state)
    const [input, setInput] = useState(props.state)
    // 类型和状态的选择
    const selectClick = (type, e) => {
        if (state[type] === e) {
            return false
        }
        setState({ ...state, [type]: e })
    }
    // 定义定时器执行
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setState({ ...state, ['name']: e.target.value,['pageNo']:1 })

        };
        timeout = setTimeout(fake, 1000);
    }
    useEffect(() => {
        props.setState(state)
    }, [state])
    return (
        <div className='screening'>
            <div className='flexAlignCenter'>
                <div className='typeWrap'>
                    {
                        positionType.map((key, index) => {
                            return <div key={index} className={`typeItem ${state.positionType === index ? 'typeItemAct' : ''}`} onClick={() => { selectClick('positionType', index) }}>{key}</div>
                        })
                    }
                </div>
                <div className='typeWrap'>
                    {
                        process.map(key => {
                            return <div key={key.key} className={`typeItem ${state.process === key.key ? 'typeItemAct' : ''}`} onClick={() => { selectClick('process', key.key) }}>{key.title}</div>
                        })
                    }
                </div>
            </div>
            <div className='flexAlignCenter' style={{ marginTop: '20px' }}>
                <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                    <Input placeholder='输入职位名称' onChange={(e) => { inputChange(e) }} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "258px", marginRight: '10px' }} />
                    <Select value={state.recEducation} style={{ minWidth: '88px' }} className='select' onChange={(e) => { selectClick('recEducation', e) }}>
                        {
                            education.map((key, index) => {
                                return (<Option value={key} key={index}>{key}</Option>)
                            })
                        }
                    </Select>
                    <Select value={state.paymentType} className='select' onChange={(e) => { selectClick('paymentType', e) }}>
                        {
                            paymentType.map((key, index) => {
                                return (<Option value={index} key={index}>{key}</Option>)
                            })
                        }
                    </Select>
                    <Select value={state.type} className='select' onChange={(e) => { selectClick('type', e) }}>
                        {
                            type.map((key, index) => {
                                return (<Option value={index} key={index}>{key}</Option>)
                            })
                        }
                    </Select>
                    <Select value={state.isShelf} className='select' onChange={(e) => { selectClick('isShelf', e) }}>
                        <Option value=''>是否上架</Option>
                        <Option value={1}>上架</Option>
                        <Option value={0}>下架</Option>
                    </Select>
                    {/* <Select value={state.type} className='select' onChange={(e) => { selectClick('type', e) }}>
                        <Option value="1">已开启</Option>
                        <Option value="0">已关闭</Option>
                    </Select> */}
                </div>
            </div>
        </div >
    )
}
