import React, { useEffect, useState } from 'react'
import { Table, Modal } from "antd"
import { useNavigate, useLocation } from 'react-router-dom'
import { getStudentsList, delPushStudent } from "../../api/position"
import { details } from "./table"
import "./index.scss"
export default function PushDetails() {
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10 })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const navigate = useNavigate()
    const { state } = useLocation()
    console.log(state,'statestatestatestate')
    // 定义删除学生推送
    const delPushStudentApi = (id) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除当前项',
            okText: '删除',
            onOk: () => {
                delPushStudent({ id }).then(res => {
                    if (res) {
                        getStudentsApi()
                    }
                })
            }
        })
    }
    const getStudentsApi = () => {
        getStudentsList({ ...params, ...{ pushId: state.id } }).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    useEffect(() => {
        getStudentsApi()
    }, [params])
    return (
        <Table
            className='pushList pushDetails'
            columns={[...details, ...[{
                title: '操作',
                width: 100,
                align: 'center',
                dataIndex: 'id',
                render: (t) => <div className='flexAlignCenter'>
                    <div className='del' onClick={(e) => { e.stopPropagation(); delPushStudentApi(t) }}>&#xe8b6;</div>
                </div>
            }]]}
            dataSource={list}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 100px)' }}
            pagination={{
                total: total,
                current: params.pageNo,
                pageSize: params.pageSize,
                onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
            }}
        ></Table>
    )
}
