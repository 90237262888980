import { financing, property, positionType } from "../../utils/specification"
export const columnsList = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center',
        width: 98
    },
    {
        title: '企业名称',
        align: 'center',
        render: (key) => <>
            <div>{key.name}</div>
            {
                key?.hrName ? <div>上级公司：{key?.hrName}</div> : null
            }
        </>
    },
    {
        title: '行业',
        dataIndex: "recIndustry",
        align: 'center',
        render: (t) => <>
            {
                t ? <div>{
                    t.split(',').map((key, index) => {
                        return <div key={index}>{key}</div>
                    })}</div> : null
            }
        </>
    },
    {
        title: '融资',
        dataIndex: "financing",
        render: (t) => <>{financing[t]}</>,
        align: 'center'
    },
    {
        title: '类型',
        dataIndex: "property",
        render: (t) => <>{property[t]}</>,
        align: 'center'
    },
    {
        title: '创建人/联系方式',
        render: (t, r) => <>{r?.createBy} {r?.mobile}</>,
        align: 'center'
    },
    {
        title: '创建时间',
        dataIndex: "createTime",
        align: 'center'
    }
]
export const columnsAudit = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        width: 98,
        align: 'center'
    },
    {
        title: '公司名称',
        align: 'center',
        render: (key) => <>
            <div>{key.name}</div>
            {
                key?.hrName ? <div>上级公司：{key?.hrName}</div> : null
            }
        </>
    },
    {
        title: '负责人/联系方式',
        render: (t, r) => <>{r?.username} {r?.mobile}</>,
        align: 'center'
    },
    {
        title: '认证时间',
        dataIndex: "updateTime",
        align: 'center'
    }
]
export const positionColumns = [
    {
        title: '序号',
        width: 78,
        align: 'center',
        render: (t, r, i) => <>{i + 1}</>
    },
    {
        title: '职位信息',
        render: (t, r) =>
            <div className="info">
                <div className="name">{r.name}</div>
                <div className="details">{[r?.address?.split(',')[1], r?.recEducation, r?.salary].filter(key => { if (key) return key }).join(' | ')}</div>
            </div>
    },
    {
        title: '类型',
        dataIndex: 'positionType',
        render: (text) => <div className='positionType'>{positionType[text]}</div>,
        align: 'center',
    },
    {
        title: '招聘人数',
        dataIndex: 'number',
        render: (text) => <>招聘{text || 0}人</>,
        align: 'center',
    },
    {
        title: '已聘人数',
        dataIndex: 'pinged',
        align: 'center',
        render: (text) => <>已聘{text || 0}人</>,
    }
]