export const GuideTable = [
	{
		title: '序号',
		render: (t, r, i) => <>{i + 1}</>,
		align: 'center',
		width: 100
	},
	{
		title: '专业名称',
		dataIndex: 'name',
	},
	{
		title: '状态',
		dataIndex: 'isEdit',
		align: 'center',
		width: 200,
		render: (t, r) => <>{t ? <div className="edit">已编辑</div> : "未编辑"}</>,
	}
]

export const FrontierTable = [
	{
		title: '序号',
		render: (t, r, i) => <>{i + 1}</>,
		align: 'center',
		width: 100
	},
	{
		title: '标题',
		dataIndex: 'title',
		align: 'center'
	},
	{
		title: '视频/图片',
		align: 'center',
		render: (key) =>
			<div className="uploadButton uploadButtonImg">
				{/* <img src={key.images} /> */}
				{
					key.type === 1 ? <video src={key.video} controls /> : key.type === 2 ? <img src={key.image.split(',')[0]} /> : null
				}
			</div>
	},
	{
		title: '类型',
		dataIndex: 'category',
		align: 'center'
	},
	{
		title: '点赞',
		dataIndex: 'likes',
		align: 'center'
	},
	{
		title: '评论',
		dataIndex: 'comment',
		align: 'center'
	},
	{
		title: '发布时间',
		dataIndex: 'createTime',
		align: 'center'
	}
]

export const PromoteTable = [
	{
		title: '序号',
		render: (t, r, i) => <>{i + 1}</>,
		align: 'center',
		width: 88
	},
	{
		title: '标题',
		dataIndex: 'title',
		align: 'center',
		width: 300
	},
	{
		title: '介绍',
		dataIndex: 'content',
		render: (t) => <div className="threeRows">{t}</div>,
		onCell: () => {
			return {
				style: {
					width: '100%'
				}
			}
		}

	},
	{
		title: '内容',
		align: 'center',
		dataIndex: 'number',
		width: 158,
		render: (t) => <>共{t ?? 0}节</>
	},
	{
		title: '评论',
		dataIndex: 'count',
		align: 'center'
	},
	{
		title: '发布时间',
		dataIndex: 'createTime',
		align: 'center'
	}
]
export const tribe = [
	{
		title: '序号',
		render: (t, r, i) => <>{i + 1}</>,
		align: 'center',
		width: 88
	},
	{
		title: '工作坊名称',
		align: 'center',
		dataIndex: 'name',
		width: 168
	},
	{
		title: '标签',
		dataIndex: 'label',
		align: 'center',
		render: (t) => <div className="labelFlex">
			{
				t.split(',').map((key, index) => {
					return <div className="labelItem" key={index}>{key}</div>
				})
			}
		</div>,
	},
	{
		title: '标题图片',
		dataIndex: 'logo',
		align: 'center',
		render: (t) =>
			<div className="uploadButton uploadButtonImg" style={{ width: '100%' }}>
				<img src={t} />
			</div>
	},
	{
		title: '简介',
		dataIndex: 'description',
		align: 'center'
	},
	{
		title: '加入人数',
		dataIndex: 'count',
		align: 'center',
		width: 100
	},
	{
		title: '创建时间',
		dataIndex: 'createTime',
		align: 'center',
		width: 128
	}
]
export const ListsTable = [
	[
		{
			title: '序号',
			render: (t, r, i) => <>{i + 1}</>,
			align: 'center',
			width: 100
		},
		{
			title: '部落信息',
			dataIndex: 'info',
			align: 'center'
		},
		{
			title: '加入时间',
			dataIndex: 'time',
			align: 'center'
		},
	],
	[
		{
			title: '序号',
			render: (t, r, i) => <>{i + 1}</>,
			align: 'center',
			width: 100
		},
		{
			title: '视频/图片',
			dataIndex: 'media',
			align: 'center'
		},
		{
			title: '点赞',
			dataIndex: 'give',
			align: 'center'
		},
		{
			title: '评论',
			dataIndex: 'comment',
			align: 'center'
		},
		{
			title: '发布时间',
			dataIndex: 'time',
			align: 'center'
		},
	],
	[],
	[
		{
			title: '序号',
			render: (t, r, i) => <>{i + 1}</>,
			align: 'center',
			width: 100
		},
		{
			title: '企业名称',
			dataIndex: 'name',
			align: 'center'
		},
		{
			title: '加入时间',
			dataIndex: 'time',
			align: 'center'
		},
	]
]