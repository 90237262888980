import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Input, Table, Modal } from "antd"
import { getGuideDetails, deleteGuide } from "../../../api/recObtainGuide"
import { columns } from "./table"
import Editor from './Editor'
import "../guide.scss"
export default function Details() {
    const { state: specialty } = useLocation()
    const [paramsItem, setParamsItem] = useState({ visible: false })
    const [list, setList] = useState([])
    const [params, setParams] = useState({})
    // // 获取专业导向详情列表
    const getGuideDetailsApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        for (let index in data) {
            if (!data[index]) {
                delete data[index]
            }
        }
        data.specialty = specialty
        getGuideDetails(data).then(({ result }) => {
            setList(result)
        })
    }
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setParams({ ...params, ...{ label: e.target.value } })
        };
        timeout = setTimeout(fake, 1000);
    }
    const deleteItem = (id) => {
        Modal.confirm({
            title: '提示',
            content: '是否删除该岗位',
            okText: '删除',
            onOk: () => {
                deleteGuide({ id }).then(res => {
                    if (res) getGuideDetailsApi()
                })
            }
        })
    }
    useEffect(() => {
        getGuideDetailsApi()
    }, [params])

    return (
        <div className='financialScreen guideDetails'>
            <div className='ScreenContent'>
                <div className='flexAlignCenter flex1'>
                    <Input placeholder='请输入岗位名称' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
                    <Button onClick={getGuideDetailsApi} type='primary'>搜索</Button>
                </div>
                <Button onClick={() => { setParamsItem({ visible: true }) }} type='primary'>新建</Button>
            </div>
            <Table
                style={{ marginTop: '20px' }}
                columns={[...columns, ...[{
                    title: '操作',
                    align: 'center',
                    width: 150,
                    render: (key) => <div className='operation'>
                        <div className="edit" onClick={() => {
                            setParamsItem({ visible: true, params: { ...key, ...{ skill: key?.skill?.split(',') ?? [] } } })
                        }}>编辑</div>
                        <div className='vertical'></div>
                        <div onClick={() => {
                            deleteItem(key.id)
                        }} className="reject">删除</div>
                    </div>
                }]]}
                dataSource={list}
                rowKey="id"
                scroll={{ y: 'calc(100vh - 100px)' }}
                pagination={{
                    total: list.length
                }}
            />
            <Editor
                params={paramsItem.params}
                visible={paramsItem.visible}
                specialty={specialty}
                update={() => { getGuideDetailsApi() }}
                cancel={() => { setParamsItem({ visible: false }) }}
            />
        </div>
    )
}
