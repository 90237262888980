import "./Rejected.scss";
import { Form, Input, Modal, Button } from "antd"
const { TextArea } = Input;
export default function InputView(props) {
    const handleCancel = () => {
        props.UndateVisible()
    };
    const onFinish = (values) => {
        values.status = '-1'
        props.UndateTwoBrokerage(values)
    };
    return (
        <Modal
            visible={props.visible}
            title="驳回"
            footer={null}
            onCancel={handleCancel}
            destroyOnClose
        >
            <Form name="basic"
                initialValues={{
                    remember: true
                }}
                onFinish={(e) => { onFinish(e) }}
                autoComplete="off">
                <Form.Item
                    name="remark"
                    label="驳回理由"
                    rules={[
                        {
                            required: true,
                            message: '请输入驳回理由'
                        }
                    ]}
                >
                    <TextArea  placeholder='请输入驳回理由' maxLength={300} autoSize={true}/>
                </Form.Item>
                <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>
                        确认
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}