import React, { useEffect, useState } from 'react'
import { Pagination, Button, Dropdown, Menu, } from "antd"
import { NavLink } from "react-router-dom"
import { getPositionsApply } from "../../../api/position"
import { interviewState } from "../../../utils/specification"
import { getHrPushList, positionPushLibrary } from '../../../api/commendBook'
export default function Apply(props) {
    const [members, setMembers] = useState({ pageNo: 1, pageSize: 5 })
    const [membersList, setMembersList] = useState([])
    const [total, setTotal] = useState(0)
    const [HrList, setHrList] = useState([])
    const getPositionsApplyApi = () => {
        let data = JSON.parse(JSON.stringify(members))
        data.positionId = props.id
        getPositionsApply(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setMembersList(records)
        })
        getHrPushList({ pageNo: 1, pageSize: 1000 }).then(({ result }) => {
            setHrList(result.records)
        })
    }
    const updatePage = (e) => {
        setMembers({ ...members, ...e })
    }
    function positionPush(name) {
        positionPushLibrary({
            positionId: props.id,
            warehouseId: name
        }).then(res => {
            console.log(res)
        })
    }
    useEffect(() => {
        getPositionsApplyApi()
    }, [members])
    return (
        <>
            {
                membersList.length ? <div className="position" style={{ marginTop: '20px' }} >
                    <div className='provide'>
                        {/* onClick={() => setParams({ visible: true })} */}
                        <Dropdown overlay={<Menu items={
                            HrList.map((item, index) => {
                                return {
                                    key: index + 1,
                                    label: (<div className='operationBox' onClick={() => { positionPush(item.id) }}>{item.name}</div>)
                                }
                            })
                        } />} placement="bottom" arrow>
                            <Button type='primary' style={{ width: '100px' }}>推送人才库</Button>
                        </Dropdown>
                    </div>
                    <div className='recordingHead'>
                        <div className='number'>编号</div>
                        <div className='basisInfo'>基本信息</div>
                        <div className='officer flex1'>预备委员</div>
                        <div className='state flex1'>状态</div>
                        <div className='state flex1'>简历</div>
                        <div className='induction flex1'>报名时间</div>
                        {/* <div className='departure flex1'>操作</div> */}
                    </div>
                    {
                        membersList.map((key, index) => {
                            return (
                                <div className='detailsItem' key={index}>
                                    <div className='number'>{index + 1}</div>
                                    <div className='basisInfo'>
                                        <img className='avatar' src={key.avatar} alt="" />
                                        <div>
                                            <div className='flexAlignBaseline'>
                                                <div className='nickname'>{key.name}</div>
                                                <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                                                <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                                            </div>
                                            <div className='schoolInfo'>
                                                {key.school}{key.specialty ? ' · ' + key.specialty : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='officer flex1'>{key.superiorName}</div>
                                    <div className='results flex1'>{interviewState[key.state]}</div>
                                    <NavLink to="/commendBook/resume/preview" state={{ ...key, ...{ positionId: props.id } }} className='results flex1 agree'>查看</NavLink>
                                    <div className='induction flex1'>{key.signTime}</div>
                                    {/* <div className='departure options flex1'>
                                <div className='agree'>同意</div>
                                <div className='vertical'></div>
                                <div className='refused'>驳回</div>
                            </div> */}
                                </div>
                            )
                        })
                    }
                    <Pagination className='page' showSizeChanger={false} defaultCurrent={members.pageNo} total={total} pageSize={members.pageSize} onChange={(e) => { updatePage({ pageNo: e }) }} onShowSizeChange={(current, size) => { updatePage({ pageNo: current, pageSize: size }) }} />
                </div > : null
            }
        </>
    )
}
