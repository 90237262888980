import React, { useState, useEffect } from 'react'
import { Input, Form, Modal, Button, Upload, message } from "antd"
import { setRecommendInfo } from "../../api/commendBook"
import "./index.scss"
export default function Resume(props) {
  const [fileList, setFileList] = useState(props.params.url || [])
  const onFinish = (e) => {
    if (!fileList.length) {
      message.error('请上传封面图')
      return false
    }
    let data = JSON.parse(JSON.stringify(props.params))
    e.url = fileList[0]
    setRecommendInfo({ ...data, ...e }).then(res => {
      if (res) {
        props.update()
        props.cancel()
      }
    })
  }
  const fileChange = ({ fileList: newFileList }) => {
    if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
      setFileList([...[newFileList[newFileList.length - 1].response.result.url]])
    }
  };
  useEffect(() => {
    console.log(props)
    if (props.visible && props.params?.url) setFileList([props.params.url])
  }, [props])
  return (
    <Modal
      visible={props.visible}
      centered
      footer={null}
      destroyOnClose
      maskClosable
      onCancel={() => { props.cancel(); setFileList([]) }}
      className="resumeModal"
    >
      <Form
        initialValues={{
          remember: true,
          ...props.params
        }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={(e) => { onFinish(e) }}
        autoComplete="off"
        style={{ marginTop: '38px' }}
      >
        <Form.Item label="标题" name="title" rules={[
          { required: true, message: '请填写标题' }
        ]}>
          <Input placeholder='请填写标题' />
        </Form.Item>
        <Form.Item label="评论" name="evaluate" rules={[
          { required: true, message: '请填写评论' }
        ]}>
          <Input placeholder='请填写评论' />
        </Form.Item>
        <Form.Item label="个人感言" name="meEvaluate" rules={[
          { required: true, message: '请填写个人感言' }
        ]}>
          <Input placeholder='请填写个人感言' />
        </Form.Item>
        <Form.Item
          label="封面图"
          className='requireds'
        >
          <Upload
            action={process.env.REACT_APP_BASE_UPLOAD_API}
            onChange={(e) => { fileChange(e, 'img') }}
            accept=".png,.jpg"
          >
            {
              fileList.length ?
                <div className="uploadButton uploadButtonImg">
                  <img src={fileList} />
                </div>
                :
                <Button className='uploadButton'>
                  <div className='iconfont'>&#xe60f;</div>
                  <p>不超过10M</p>
                  <p>支持PNG/JPG</p>
                </Button>
            }
          </Upload>
        </Form.Item>
        {
          [0, 2].includes(props?.type) ? <div className='flexAlignCenter' style={{ justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={false} className='submit'>提交</Button>
          </div> : null
        }
      </Form>
    </Modal >
  )
}
