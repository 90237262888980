import React from 'react'
import { Modal } from 'antd'
import { property, financing, scale, filterJoin } from "../../utils/specification"
export default function Info(props) {
    console.log(props, 'props')
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            destroyOnClose
            maskClosable
            onCancel={props.cancel}
            closable={false}
            width={'auto'}
            className="customModal enterpriseModal"
        >
            <div className='name'>{props.nameFull}</div>
            <div className='cardDetails'>
                <div className='top'>
                    <div className='companyInfo'>
                        <div className='companyName'>{props.name}</div>
                        <div className='label'>
                            <div className='labelItem'>{props.recIndustry}</div>
                        </div>
                        <div className='label'>
                            <div className='labelItem'>{property[props.property]}</div>
                            <div className='labelItem'>{financing[props.financing]}</div>
                            <div className='labelItem'>{scale[props.scale]}</div>
                        </div>
                        <div className='label'>{props.address}</div>
                    </div>
                    <img className='companyImg' src={props.license} alt="公司图片" />
                </div>
                <div className='linkman'>
                    <div className='name'>{props.username}</div>
                    <div className='info'>
                        <div className='post'>{props.job} <span>{props.mobile}</span></div>
                        <div className='coding'>{props.idCard}</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
