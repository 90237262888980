export const detailsTable = [
    {
        render: (t, r) =>
            <div className="rows">
                <img src={r.avatar} />
                <div style={{ marginLeft: '10px' }}>
                    <div className="name">{r.name}</div>
                    <div className="time">{r.createTime}</div>
                    <div className="comment">{r.content}</div>
                </div>
            </div>
    }
]