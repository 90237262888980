import React, { useEffect, useState } from 'react'
import { Table, Input } from "antd";
import { getStudentsRemark, setStudentsRemarkSuccess, setStudentsRemarkReject } from "../../api/students"
import { columnsAudit } from "./table"
import "./index.scss"
export default function Home(props) {
	const [list, setList] = useState([])
	const [total, setTotal] = useState(0)
	const [params, setParams] = useState({ pageNo: 1, pageSize: 10, type: 1 })
	const process = { 1: '审核通过', 2: "审核已被拒绝" }
	const getStudentsRemarkApi = () => {
		getStudentsRemark(params).then(({ result }) => {
			setList(result.records)
			setTotal(result.total)
		})
	}
	// 定义定时器
	let timeout;
	// 输入框搜索
	const inputChange = (e) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		const fake = () => {
			setParams({ ...params, ...{ name: e.target.value } })
		};
		timeout = setTimeout(fake, 1000);
	}
	useEffect(() => {
		getStudentsRemarkApi()
	}, [params])
	return (
		<div className='huntingPage'>
			<div className='screening'>
				<Input placeholder='请输入学生名字' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
			</div >
			<Table
				columns={[...columnsAudit, ...[{
					title: '操作',
					align: 'center',
					width: 148,
					render: (key) => <>
						{
							key.isFlash === 0 ?
								<div className='flexAlignCenter'>
									<div className="edit" onClick={() => { setStudentsRemarkSuccess(key.id).then(res => { if (res) getStudentsRemarkApi() }) }}>
										同意
									</div>
									<div className='vertical'></div>
									<div className="reject" onClick={() => { setStudentsRemarkReject(key.id).then(res => { if (res) getStudentsRemarkApi() }) }}>
										驳回
									</div>
								</div>
								: process[key.isFlash]
						}
					</>
				}]]}
				rowKey="id"
				dataSource={list}
				scroll={{ y: 'calc(100vh - 100px)' }}
				pagination={{
					total: total,
					current: params.pageNo,
					pageSize: params.pageSize,
					onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
				}}
			></Table>
		</div>
	)
}
