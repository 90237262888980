import React from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { login } from "../../api/user"
import { setToken, setUserInfo, setPermission } from "../../utils/auth"
import "./LoginView.scss";
import { useNavigate } from 'react-router-dom';
export default function LoginView() {
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	const onFinish = (values) => {
		console.log('Success:', values);
		if (!values.remember) {
			message.error('请阅读并同意《用户服务协议》和《隐私协议》');
			return false
		}
		login(values).then(({ result }) => {
			console.log(result, 'result')
			if(!result) return
			setToken(result.token);
			setUserInfo(JSON.stringify(result.userInfo));
			setPermission(JSON.stringify(result.permission))
			message.success('登录成功');
			window.location.reload()
			if(result?.userInfo?.nature==='finance'){
				window.location.pathname = '/position/list'
			}else{
				window.location.pathname = '/'
			}
		})
	};
	return (
		<div className="LoginView">
			<div className="hello">HELLO,欢迎登录后台</div>
			<div className="welcome">WELCOME TO EHE SYSTEM</div>
			<Form
				name="basic"
				initialValues={{
					remember: true,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					name="username"
					className='phoen'
					rules={[
						{
							required: true,
							message: '请输入账号',
						},
					]}
				>
					<Input placeholder='请输入账号' />
				</Form.Item>

				<Form.Item
					name="password"
					className='password'
					rules={[
						{
							required: true,
							message: '请输入密码',
						},
					]}
				>
					<Input.Password placeholder='请输入密码' />
				</Form.Item>

				<Form.Item
					name="remember"
					valuePropName="checked"
					className='checkBox'
				>
					<Checkbox>已阅读并同意《用户服务协议》和《隐私协议》</Checkbox>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" loading={false} className='submit'>
						登录
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}