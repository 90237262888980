import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import { getSchoolInfo, getMonthlyStatistics, getAgencyList } from '../../api/school'
import "./details.scss"
import * as echarts from "echarts"

export default function Details() {
    const { state } = useLocation()
    console.log(state,'statestatestatestatestatestatestatestatestate')
    const [member, setMember] = useState([])
    const [statistical, setStatistical] = useState([])
    const [monthly, setMonthly] = useState([])
    const [agency, setAgency] = useState([])
    const dataType = { count: '学校总人数', hunterCount: '预备委员人数', monthCount: '本月人数', todayCount: '今日新增', yesterdayCount: '昨日人数' }
    const type = { 1: '兼职', 2: '实习', 3: '活动', 4: '全职', 5: '储备营', 6: '储备营', 7: '预备委员', 8: '预备委员' }
    const imgType = { 1: 'partTime', 2: 'internship', 3: 'activity', 4: 'fullTime' }
    function dataFormatting(data) {
        let arr = []
        let obj1 = { title: '输送人员统计', color: '#4370FF', children: [] }
        let obj2 = { title: '职才统计', color: '#29C19B', children: [] }
        let obj3 = { title: '预备委员统计', color: '#E9513E', children: [] }
        for (let index in data) {
            arr.push(data[index])
        }
        arr.forEach(key => {
            if (key.type <= 4) {
                key.prompt = '上月'
                key.promptText = '本月'
                obj1.children.push(key)
            } else if (key.type <= 6) {
                key.promptText = '今日'
                key.prompt = key.type == 5 ? '昨日' : '上月'
                obj2.children.push(key)
            } else {
                key.promptText = '今日'
                key.prompt = key.type == 7 ? '昨日' : '上月'
                obj3.children.push(key)
            }
        })
        return [obj1, obj2, obj3]
    }
    useEffect(() => {
        getSchoolInfo(state).then(({ result }) => {
            setStatistical(dataFormatting(result.details))
            delete result.details
            let arr = []
            for (let index in result) {
                arr.push({
                    type: index,
                    value: result[index]
                })
            }
            setMember(arr)
        })
        getMonthlyStatistics(state).then(({ result }) => {
            console.log(result, 'result')
            let numberArr = []
            let incomeArr = []
            result.forEach(key => {
                numberArr.push(key.number)
                incomeArr.push(key.income)
            })
            let myChart = echarts.init(document.getElementById('monthly'));
            myChart.setOption({
                tooltip: {},
                xAxis: {
                    data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
                },
                yAxis: {},
                series: [
                    {
                        type: 'bar',
                        barWidth: '20%',
                        data: numberArr,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#FFCF7D' }, { offset: 1, color: '#EF9700' }])
                        }
                    },
                    {
                        type: 'bar',
                        data: incomeArr,
                        barWidth: '20%',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#78A5FF' }, { offset: 1, color: '#0155FE' }])
                        }
                    }
                ],

            });
        })
        getAgencyList(state).then(({ result }) => {
            setAgency(result)
            console.log(agency, 'agency')
        })
    }, [])
    return (
        <div className='detailsPage'>
            <div className='memberBox flexAlignCenter'>
                {member.map((key, index) => {
                    return (<div key={index} className='memberItem flexAlignCenter' style={{ backgroundImage: `url(/assets/${key.type}Bg.png)` }}>
                        <img src={`/assets/${key.type}Img.png`} alt="" />
                        <div>
                            <div className='memberNumber'>{key.value}人</div>
                            <div className='memberTitle'>{dataType[key.type]}</div>
                        </div>
                    </div>)
                })}
            </div>
            <div className='centent'>
                <div className='leftBox'>
                    <div className='monthlyBox'>
                        <div className='monthlyHeader flexAlignCenter'>
                            <div className='monthlyTitle'>月度统计</div>
                            <div className='monthlyType flexAlignCenter'>
                                <div className='monthlyItem'>注册人数</div>
                                <div className='monthlyItem'>收益金额（￥）</div>
                            </div>
                        </div>
                        <div id='monthly'></div>
                    </div>
                    <div className='todo'>
                        <div className='todoHeader flexAlignCenter'>
                            <div className='todoTitle'>待办事项</div>
                        </div>
                        <div className='todoContent'>
                            {
                                agency.map((key, index) => {
                                    return (
                                        <div className='todoItem flexAlignCenter' key={index}>
                                            <div className='todoNumber'>{index + 1}</div>
                                            <div className='todoType'>{key.title}</div>
                                            <div className='todoText flex1'>{key.content}</div>
                                            <div className='todoUsername'>{key.username}</div>
                                            <div className='todoTime'>{key.createTime}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='rightBox'>
                    {
                        statistical.map((key, index) => {
                            return (
                                <div key={index} style={{ marginTop: index ? '-12px' : '' }}>
                                    <div className='rightTitle flexAlignCenter'>
                                        <div className='vertical' style={{ background: key.color }}></div>
                                        <span style={{ color: key.color }}>{key.title}</span>
                                    </div>
                                    <div className='rightWrap'>
                                        {
                                            key.children.map((item, itemIndex) => {
                                                return (
                                                    <div className='rightItem' key={itemIndex} style={{ backgroundImage: item.type < 5 ? `url(/assets/${imgType[item.type]}.png)` : '' }}>
                                                        <div>
                                                            <div className='todayNum'>{item.todayNum}人</div>
                                                            <div className='todayType'>{item.promptText}加入{type[item.type]}人数</div>
                                                            <div className='ratio'>同比{item.prompt}{item.todayNum >= item.yesterdayNum ? '上升' : '下降'}<span style={{ color: item.todayNum >= item.yesterdayNum ? '#E9513E' : '#F7B749' }}>{item.same}%</span></div>
                                                        </div>
                                                        <div className='flexAlignCenter'>
                                                            <div>{item.yesterdayNum || 0}人</div>
                                                            <div className='prompt'>{item.prompt}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}
