import React, { useState, useEffect } from 'react'
import { Image, Modal, Button, Upload, message, Form, Select, Input, Switch } from "antd"
import { roadbed as roadbedList, objTurnArr } from "../../utils/specification"
import { getPositionsInfo } from "../../api/position"
import { getEnterpriseList } from "../../api/enterprise"
import { getHandBookList, getResumeList } from "../../api/commendBook"
import { getCampArticlelList, getPromotionList, getPrefaceList } from "../../api/recObtainGuide"
import { addAdvertising, editAdvertising } from "../../api/advertising"
import "./index.scss"
export default function EditAd(props) {
    const [type, setType] = useState()
    const [paramsInt, setParamsInt] = useState()
    const [list, setList] = useState([])
    const { Option } = Select
    const roadbed = objTurnArr(roadbedList)
    const [fileList, setFileList] = useState([])
    const onFinish = (e) => {
        console.log(props)
        let obj = {
            url: '',
            category: props.type,
            isOpen: Number(e?.isOpen ?? true),
        }
        if (props.type === 4) {
            obj.url = e.url
        } else {
            if (!fileList.length) {
                message.error('请上传封面图')
                return false
            }
            obj.url = fileList.join(',')
            obj.routing = type
            obj.sourceId = list[paramsInt - 1]?.id ?? props?.params?.sourceId
        }
        if (type === 6) {
            let item = list[paramsInt - 1]
            if (item.positionType === 3) {
                obj.routing = 63
            }
            obj.sourceId = item.id
        } else if (type === 7) {
            let item = list[paramsInt - 1]
            if (item.video) {
                obj.routing = 73
            }
            obj.sourceId = item.id
        }
        let arr = ''
        if (props?.params?.id) {
            arr = editAdvertising({ ...props.params, ...obj })
        } else {
            arr = addAdvertising(obj)
        }
        arr.then(res => {
            if (res) {
                props.cancel()
                props.update()
                setFileList([])
                setType()
            }
        })
    }
    const fileChange = ({ fileList: newFileList }) => {
        if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
            setFileList([...fileList, ...[newFileList[newFileList.length - 1].response.result.url]])
        }
    };
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            switch (type) {
                case 2:
                    getPromotionList({ title: e ? `,${e},` : e }).then(({ result }) => {
                        result.records.forEach(key => {
                            key.name = key.title
                        })
                        setList(result.records)
                    })
                    break;
                case 3:
                    getEnterpriseList({ nameFull: e }).then(({ result }) => {
                        setList(result.records)
                    })
                    break;
                case 4:
                    getResumeList({ name: e }).then(({ result }) => {
                        setList(result.records)
                    })
                    break;
                case 5:
                    getCampArticlelList({ title: e ? `,${e},` : e }).then(({ result }) => {
                        result.records.forEach(key => {
                            key.name = key.title
                        })
                        setList(result.records)
                    })
                    break;
                case 6:
                    getPositionsInfo({ name: e }).then(({ result }) => {
                        setList(result.records)
                    })
                    break;
                case 7:
                    getHandBookList({ title: e }).then(({ result }) => {
                        result.records.forEach(key => {
                            key.name = key.title
                        })
                        setList(result.records)
                    })
                    break;
                case 8:
                    getPrefaceList({ title: e ? `,${e},` : e }).then(({ result }) => {
                        result.records.forEach(key => {
                            key.name = key.title
                        })
                        setList(result.records)
                    })
                    break;
            }
        };
        timeout = setTimeout(fake, 1000);
    }
    useEffect(() => {
        setList([])
        setParamsInt()
    }, [type])
    useEffect(() => {
        console.log(props)
        if (props?.params?.id) {
            console.log(props)
            setFileList([props?.params?.url])
        }
        if (props?.params?.routing) {   
            setType(props.params.routing)
        }
    }, [props?.params])
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            destroyOnClose
            maskClosable
            width={548}
            onCancel={() => { props.cancel(); setFileList([]) }}
            wrapClassName="advertisingModal"
        >
            <Form
                onFinish={onFinish}
                initialValues={{
                    remember: true,
                    ...props.params
                }}
                style={{ paddingTop: '30px' }}
            >
                {
                    props.type === 4 ?
                        <Form.Item name="url" label="文字" rules={[{ required: true, message: '请输入文字' }]}>
                            <Input placeholder="请输入文字" />
                        </Form.Item>
                        :
                        <>
                            <Form.Item label="图片">

                                {
                                    fileList.length ? fileList.map((key, index) => {
                                        return (
                                            <div key={index} className="uploadButton uploadButtonImg">
                                                <Image src={key} />
                                                <div className='close' onClick={() => { setFileList([]) }}>
                                                    <svg t="1685608587171" class="icon close" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2409" width="30" height="30"><path d="M512 883.2A371.2 371.2 0 1 0 140.8 512 371.2 371.2 0 0 0 512 883.2z m0 64a435.2 435.2 0 1 1 435.2-435.2 435.2 435.2 0 0 1-435.2 435.2z" fill="#5A5A68" p-id="2410"></path><path d="M557.056 512l122.368 122.368a31.744 31.744 0 1 1-45.056 45.056L512 557.056l-122.368 122.368a31.744 31.744 0 1 1-45.056-45.056L466.944 512 344.576 389.632a31.744 31.744 0 1 1 45.056-45.056L512 466.944l122.368-122.368a31.744 31.744 0 1 1 45.056 45.056z" fill="#5A5A68" p-id="2411"></path></svg>
                                                </div>
                                            </div>
                                        )
                                    }) : <Upload
                                        action={process.env.REACT_APP_BASE_UPLOAD_API}
                                        onChange={(e) => { fileChange(e) }}
                                        accept=".png,.jpg"
                                    >
                                        <Button className='uploadButton'>
                                            <span className='iconfont'>&#xe60f;</span>
                                        </Button>
                                    </Upload>
                                }
                            </Form.Item>
                            <Form.Item label="跳转路径" name="routing">
                                <Select style={{ width: 375 }} onChange={(e) => { setType(e) }} allowClear placeholder="请选择跳转路径">
                                    {
                                        roadbed.map((key, index) => {
                                            return <Option value={index} key={index}>{key}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="选择参数" name="sourceId">
                                <Select showSearch allowClear onChange={(e) => { setParamsInt(e + 1) }} onSearch={inputChange} filterOption={false} notFoundContent={null} placeholder="请选择文章/职位/公司">
                                    {
                                        list.map((key, index) => {
                                            return <Option value={index} key={index}>{key.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </>
                }
                <Form.Item label="是否开启" name="isOpen">
                    <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked />
                </Form.Item>
                <div className='flexAlignCenter' style={{ justifyContent: "center" }}>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>提交</Button>
                </div>
            </Form>
        </Modal >
    )
}
