export const detailsTable = [
    {
        render: (key) =>
            <div className="rows">
                <img src={key.avatar} />
                <div style={{ marginLeft: '10px' }}>
                    <div className="name">{key.name}</div>
                    <div className="time">{key.createTime}</div>
                    <div className="comment">{key.content}</div>
                </div>
            </div>
    }
]