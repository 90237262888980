import { filterJoin } from "../../utils/specification"
export const resumeTable = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center',
        width: 100
    },
    {
        title: '基本信息',
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key?.stuName ?? JSON.parse(key?.content)?.name??""}</div>
                        <div className='gender'>
                            <img src={`/assets/${key?.sex === 1 ? 'male' : key?.sex === 2 ? 'female' : JSON.parse(key?.content)?.sex === 1 ? 'male' : JSON.parse(key?.content)?.sex === 2 ? 'female' : ''}.png`} alt="" />
                        </div>
                        <div className='the'>{key?.graduate ? key?.graduate + '届' : JSON.parse(key?.content)?.graduate ? JSON.parse(key?.content).graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key?.school??JSON.parse(key?.content)?.school, key?.specialty??JSON.parse(key?.content)?.specialty], '·')}
                    </div>
                </div>
            </div>
    },
    {
        title: '简历名称',
        dataIndex: 'resume',
        align: 'center'
    },
    {
        title: '求职意向',
        dataIndex: 'jobObjective',
        align: 'center',
        width: 128
    },
    {
        title: '状态',
        dataIndex: 'isFlash',
        align: 'center',
        width: 128,
        render: (t) => <>{t ? '已推' : ''}</>
    }
]
export const type = { 1: '职才部落经历', 2: '工作实习经历', 3: '社团和组织经历', 4: '项目经历', 5: '竞赛/获奖经历', 6: '作品集（含技能强项）', 7: '研究经历', 8: '其他' }
export const booksTable = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center',
        width: 100
    },
    {
        title: '姓名',
        dataIndex: 'name',
        align: 'center'
    },
    {
        title: '标题',
        dataIndex: 'title',
        align: 'center'
    },
    {
        title: '类型',
        dataIndex: 'type',
        render: (t) => <>{type[t]}</>,
        align: 'center'
    },
    {
        title: '上传时间 ',
        dataIndex: 'createTime',
        align: 'center'
    }
]
export const booksList = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center',
        width: 78
    },
    {
        title: '标题',
        dataIndex: 'title',
        align: 'center'
    },
    {
        title: '类型',
        dataIndex: 'type',
        render: (t) => <>{type[t]}</>,
        align: 'center'
    }
]
export const detailsTable = [
    {
        render: (t, r) =>
            <div className="rows">
                <img src={r.avatar} />
                <div style={{ marginLeft: '10px' }}>
                    <div className="name">{r.name}</div>
                    <div className="time">{r.createTime}</div>
                    <div className="content">{r.content}</div>
                </div>
            </div>
    }
]

export const HrPushColumns = [
    {
        title: '序号',
        width: 98,
        align: 'center',
        render: (t, r, i) => <>{i + 1}</>
    },
    {
        title: '名称',
        dataIndex: 'name'
    }
]


export const HrPushDetailsColumns = [
    {
        title: '序号',
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center',
        width: 100
    },
    {
        title: '基本信息',
        dataIndex: 'info',
        render: (key) =>
            <div>
                <div className='flexAlignBaseline'>
                    <div className='nickname' style={{ fontSize: 18, color: 'black', fontWeight: 'bolder' }}>{key.name}</div>
                    <div className='the' style={{ marginLeft: 20 }}>{key.graduate ? key.graduate + '届' : ''}</div>
                </div>
                <div className='schoolInfo'>
                    {filterJoin([key.school, key.specialty], '·')}
                </div>
            </div>
    },
    {
        title: '简历名称',
        dataIndex: 'info',
        align: 'center',
        render: (t) => <>{t?.resume}</>
    }
]

export const hrColumns = [
    {
        title: '基本信息',
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {key.employerName}
                    </div>
                </div>
            </div>
    },
    {
        title: '联系方式',
        dataIndex: 'phone',
        align: 'center'
    },
    {
        title: '工作年限',
        dataIndex: 'workingYears',
        align: 'center',
        render: (t) => <>{t || 0}年</>,
    },
]