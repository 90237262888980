import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Button, Select, Divider, Typography, Space, Upload } from "antd"
import { addCampList, editCampList } from "../../../api/recObtainGuide"
import { getEnterpriseList } from "../../../api/enterprise"
import "../guide.scss"
const { Option } = Select;
export default function Editor(props) {
    const fileChange = ({ fileList: newFileList }) => {
        if (newFileList[newFileList.length - 1].status === 'done' && newFileList[newFileList.length - 1].response.code === 200) {
            setFileList(newFileList[newFileList.length - 1].response.result.url)
        }
    };
    const [fileList, setFileList] = useState()
    const onFinish = (e) => {
        for (let index in e) {
            if (Array.isArray(e[index])) {
                e[index] = e[index].join(',')
            }
        }
        e.logo = fileList
        if (props?.params?.id) {
            props.params.status = 1
            editCampList({ ...props.params, ...e }).then(res => {
                if (res) {
                    props.update()
                    props.cancel()
                }
            })
        } else {
            addCampList(e).then(res => {
                if (res) {
                    props.update()
                    props.cancel()
                }
            })
        }
    }
    const [items, setItems] = useState([]);
    const [name, setName] = useState('');
    const [enterpriseList, setEnterpriseList] = useState([])
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = () => {
        setItems([...items, name]);
        setName('');
    };
    let timeout;
    // input 输入选择
    const inputChange = (nameFull) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            getEnterpriseList({ nameFull }).then(({ result }) => {
                let { records } = result
                setEnterpriseList(records)
            })
        };
        timeout = setTimeout(fake, 1000);
    }
    useEffect(() => {
        if (props?.params?.id) {
            setFileList(props.params.logo || '')
        }
    }, [props.params])
    return (
        <Modal
            visible={props.visible}
            centered
            footer={null}
            width={598}
            destroyOnClose
            maskClosable
            wrapClassName="reserveModal"
            className="reserveCampModal"
            onCancel={() => { props.cancel() }}
        >
            <Form
                initialValues={{
                    remember: true,
                    ...props.params
                }}
                onFinish={onFinish}
                autoComplete="off"
                className='editor'
            >
                <Form.Item name="name" label="名称" rules={[{ required: true, message: '请输入部落名称' }]}>
                    <Input placeholder="请输入部落名称" />
                </Form.Item>
                <Form.Item name="description" label="简介" rules={[{ required: true, message: '请简要描述' }]}>
                    <Input.TextArea placeholder="请简要描述" />
                </Form.Item>
                <Form.Item name="companyRule" label="导师" rules={[{ required: true, message: '请简要描述' }]}>
                    <Input.TextArea placeholder="请简要描述" />
                </Form.Item>
                <Form.Item name="studentRule" label="内容" rules={[{ required: true, message: '请简要描述' }]}>
                    <Input.TextArea placeholder="请简要描述" />
                </Form.Item>
                <Form.Item name="label" label="标签" rules={[{ required: true, message: '请选择标签' }]}>
                    <Select mode="multiple" placeholder="请选择标签" dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space align="center" style={{ padding: '0 8px 4px', }} >
                                <Input placeholder="请输入自定义标签" value={name} onChange={onNameChange} />
                                <Typography.Link onClick={(e) => { e.preventDefault(); addItem() }} style={{ whiteSpace: 'nowrap' }}>
                                    添加
                                </Typography.Link>
                            </Space>
                        </>
                    )}>
                        {
                            items.map((key, index) => {
                                return <Option value={key} key={index} label={key} />
                            })
                        }
                    </Select>
                </Form.Item>
                {
                    props?.params?.specify ? null : <Form.Item label="关联企业" name='specify'>
                        <Select showSearch allowClear onSearch={inputChange} filterOption={false} notFoundContent={null} placeholder="请选择关联企业">
                            {
                                enterpriseList.map(key => {
                                    return <Option value={key.id} key={key.id}>{key.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                }
                <Form.Item label="封面图片" className='requireds'>
                    <Upload
                        action={process.env.REACT_APP_BASE_UPLOAD_API}
                        onChange={(e) => { fileChange(e) }}
                        accept=".png,.jpg"
                    >
                        {
                            fileList ?
                                <div className="uploadButton uploadButtonImg">
                                    <img src={fileList} />
                                </div> :
                                <Button className='uploadButton'>
                                    <div className='iconfont'>&#xe60f;</div>
                                    <p>不超过10M</p>
                                    <p>支持PNG/JPG格式</p>
                                </Button>
                        }
                    </Upload>
                </Form.Item>
                <div className='flexSbCenter' >
                    <Button type="default" onClick={() => { props.cancel() }} loading={false} className='submit'>取消</Button>
                    <Button type="primary" htmlType="submit" loading={false} className='submit'>确定</Button>
                </div>
            </Form>
        </Modal>
    )
}
